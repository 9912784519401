import get from 'lodash/get';

export default {
  /**
   * Checks nested permissions (e.g. using dot notation).
   *
   * @param state
   * @param product
   */
  can({ permissions }) {
    return (permission) => get(permissions.data, permission) === true;
  },

  userId({ user }) {
    return user.data.id;
  },
};
