import isEmpty from 'lodash/isEmpty';
import keys from 'lodash/keys';
import mapValues from 'lodash/mapValues';
import reduce from 'lodash/reduce';
import pickBy from 'lodash/pickBy';
import mapAssoc from '@/utils/mapAssoc';

export default {
  /**
   * Returns a list of all the names of our loaded choices.
   *
   * @param state
   * @return {string[]}
   */
  loaded(state) {
    return keys(state);
  },

  /**
   * Checks if we have already loaded the choices for the given key.
   *
   * @param state
   * @param getters
   * @return {function(*=): boolean}
   */
  contains(state, getters) {
    return (key) => getters.loaded.indexOf(key) >= 0;
  },

  label(state, getters) {
    return (key, value) => {
      const entry = getters.byValue[key][value];
      return entry !== undefined ? entry.label : null;
    };
  },

  byValue(state) {
    return mapValues(state, ({ data }) => mapAssoc(data.values, 'value'));
  },

  meta(state) {
    return mapValues(
      pickBy(state, (choice) => !isEmpty(choice.data)),
      (obj) => obj.data.meta || {},
    );
  },

  simpleAreLoading(state) {
    return reduce(state, (acc, value) => {
      if (value.isLoading) {
        return true;
      }
      return acc;
    }, false);
  },
};
