import difference from 'lodash/difference';
import get from 'lodash/get';
import keys from 'lodash/keys';
import reduce from 'lodash/reduce';

import api from '@/store/api';

import waitForProduct from '@/store/effects/waitForProduct';
import { FORM_MAP_FULL, toForm, toState } from '@/store/modules/client/form';
import { NAMESPACE } from '@/store/modules/client';
import { transformToOldMapping } from '@/store/modules/client/MappingerMapper/tempMapper';

import {
  call,
  fork,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects';

import {
  ADD_CLIENT_TO_ROLE,
  GET_CLIENT_BY_ID,
  GET_CLIENT_BY_ID_ERROR,
  GET_CLIENT_BY_ID_SUCCESS,
  INIT_CLIENT_BY_ROLE,
  REMOVE_CLIENT_BY_ROLE,
  RESET_DIRTY_FLAG,
  SAVE_CLIENT_BY_ROLE,
  SAVE_CLIENT_BY_ROLE_ERROR,
  SAVE_CLIENT_BY_ROLE_SUCCESS,
  SET_DATA_BY_ROLE, SET_ID_BY_ROLE,
  SET_INPUT_FIELD,
} from './mutations';

import { NAMESPACE as QUOTE_NAMESPACE } from '@/store/modules/quote';
import { RESET_QUOTE } from '@/store/modules/quote/mutations';

import { ROLE_VERSICHERTE_PERSON, ROLE_VERSICHERUNGSNEHMER, ROLE_ZAHLPERSON } from './roles';
import fields from './fields';

/**
 * Loads the client data for the given client ID.
 * This will wait until we have a product set before doing the actual request.
 *
 * @param clientId
 * @return {IterableIterator<*>}
 */
export function* loadClientById(
  {
    payload: {
      id,
      roles,
      keepQuoteData,
      copyToInputStore,
      onSuccess,
    },
  },
) {
  const product = yield call(waitForProduct);

  try {
    const { data } = yield call(api, 'get', `${product}/kunde/${id}`);
    yield put({
      type: NAMESPACE + GET_CLIENT_BY_ID_SUCCESS,
      payload: {
        id,
        roles,
        data: toState(transformToOldMapping(data)),
        keepQuoteData,
        copyToInputStore,
      },
    });

    if (onSuccess !== undefined) {
      onSuccess();
    }
  } catch (error) {
    yield put({
      type: NAMESPACE + GET_CLIENT_BY_ID_ERROR,
      payload: {
        error,
        roles,
        id,
      },
    });
  }
}

export function* reinitializeClient({ payload: role }) {
  yield put({
    type: NAMESPACE + INIT_CLIENT_BY_ROLE,
    payload: {
      roles: [role],
    },
  });
}

export function* pruneCompanyClientsOnInput({ payload }) {
  const { field, value, role } = payload;

  if (field !== 'salutation') {
    return;
  }

  if (value !== get(yield select(), 'choices.simple.salutation_id.data.meta.isCompany')) {
    return;
  }

  // Prune all keys except the ones in this list
  const data = reduce(difference(keys(fields), [
    'address',
    'bank',
    'cellphone',
    'email',
    'id',
    'lastName',
    'phone',
    'salutation',
  ]), (acc, key) => {
    acc[key] = fields[key]; // reset back to the default value
    return acc;
  }, {});

  yield put({
    type: NAMESPACE + SET_DATA_BY_ROLE,
    payload: {
      roles: [role],
      data,
    },
  });
}

export function* pruneCompanyClientOnMassInput({ payload }) {
  const { data, roles } = payload;

  if (data.salutation === undefined) {
    return;
  }

  for (let i = 0; i < roles.length; i += 1) {
    yield fork(pruneCompanyClientsOnInput, {
      payload: {
        role: roles[i],
        field: 'salutation',
        value: data.salutation,
      },
    });
  }
}

export function* pruneCompanyClientOnLoad({ payload }) {
  const { client, roles } = payload;

  for (let i = 0; i < roles.length; i += 1) {
    yield fork(pruneCompanyClientsOnInput, {
      payload: {
        role: roles[i],
        field: 'salutation',
        value: client.data.salutation,
      },
    });
  }
}

function* resetLoadedQuote({ payload: role }) {
  if (role === ROLE_VERSICHERUNGSNEHMER) {
    const { product } = yield select();
    yield put({
      type: QUOTE_NAMESPACE + RESET_QUOTE,
      payload: {
        product,
      },
    });
  }
}

function* saveClient(role, { data: client }, forms) {
  const product = yield call(waitForProduct);

  // Allow saving of multiple form contents (e.g. VN + bank data)
  let content = {};

  for (let i = 0; i < forms.length; i += 1) {
    const { id: ignore, ...form } = toForm(client, forms[i]);
    content = { ...content, ...form };
  }

  if (client.id !== null) {
    // Just do an update call
    yield call(api, 'put', `${product}/kunde/${client.id}`, content);
    return;
  }

  // We have a fresh client, create one in the DB
  const { data } = yield call(api, 'post', `${product}/kunde`, content);

  yield put({
    type: NAMESPACE + SET_ID_BY_ROLE,
    payload: { role, id: data.id },
  });
}

function* saveClientIfDirty({ payload }) {
  const {
    role, onSuccess, onError, forms = [FORM_MAP_FULL],
  } = payload;

  const client = yield select((store) => store.client[role]);

  try {
    if (client.dirty) {
      yield call(saveClient, role, client, forms);
    }

    yield put({
      type: NAMESPACE + SAVE_CLIENT_BY_ROLE_SUCCESS,
      payload: { role, client },
    });

    // Allow custom callbacks since mutations do not have promise support by default
    if (onSuccess !== undefined) {
      onSuccess();
    }
  } catch (error) {
    yield put({
      type: NAMESPACE + SAVE_CLIENT_BY_ROLE_ERROR,
      payload: { role, client, error },
    });

    if (onError !== undefined) {
      onError(error);
    }
  }
}

function* markSavedClientsAsNotDirty({ payload }) {
  const { client } = payload;

  if (!client.dirty) {
    return;
  }

  yield put({
    type: NAMESPACE + RESET_DIRTY_FLAG,
    payload: client,
  });
}

export default function* root() {
  // Add initial client object
  yield put({
    type: NAMESPACE + ADD_CLIENT_TO_ROLE,
    payload: {
      client: yield select((state) => state.client[ROLE_VERSICHERTE_PERSON]),
      roles: [ROLE_VERSICHERUNGSNEHMER, ROLE_ZAHLPERSON],
    },
  });

  yield takeEvery(NAMESPACE + REMOVE_CLIENT_BY_ROLE, reinitializeClient);

  yield takeEvery(NAMESPACE + GET_CLIENT_BY_ID, loadClientById);

  yield takeEvery(NAMESPACE + ADD_CLIENT_TO_ROLE, pruneCompanyClientOnLoad);
  yield takeEvery(NAMESPACE + GET_CLIENT_BY_ID_SUCCESS, pruneCompanyClientOnMassInput);
  yield takeEvery(NAMESPACE + SET_DATA_BY_ROLE, pruneCompanyClientOnMassInput);
  yield takeEvery(NAMESPACE + SET_INPUT_FIELD, pruneCompanyClientsOnInput);

  yield takeEvery(NAMESPACE + REMOVE_CLIENT_BY_ROLE, resetLoadedQuote);

  yield takeEvery(NAMESPACE + SAVE_CLIENT_BY_ROLE, saveClientIfDirty);
  yield takeEvery(NAMESPACE + SAVE_CLIENT_BY_ROLE_SUCCESS, markSavedClientsAsNotDirty);
}
