import each from 'lodash/each';

import getters from './getters';

export const GET_PROFILES = 'getProfiles';
export const GET_PROFILES_SUCCESS = 'getProfilesSuccess';
export const GET_PROFILES_ERROR = 'getProfilesError';

export const CREATE_PROFILE = 'createProfile';
export const CREATE_PROFILE_SUCCESS = 'createProfileSuccess';
export const CREATE_PROFILE_ERROR = 'createProfileError';

export const SET_ACTIVE = 'setActive';
export const SET_BY_ID = 'setById';

export const UPDATE_PROFILE = 'updateProfile';
export const UPDATE_PROFILE_SUCCESS = 'updateProfileSuccess';
export const UPDATE_PROFILE_ERROR = 'updateProfileError';

export const DELETE_PROFILE = 'deleteProfile';
export const DELETE_PROFILE_SUCCESS = 'deleteProfileSuccess';
export const DELETE_PROFILE_ERROR = 'deleteProfileError';

export default {
  [GET_PROFILES](state, product) {
    state[product].list.isLoading = true;
  },

  [GET_PROFILES_SUCCESS](state, { product, data }) {
    state[product].list.isLoading = false;
    state[product].list.data = data;
  },

  [GET_PROFILES_ERROR](state, { product, error }) {
    state[product].list.isLoading = false;
    state[product].list.error = error;
  },

  [CREATE_PROFILE](state, { product, data: ctx }) {
    state[product].list.data.push(ctx);
    ctx.isLoading = true;
  },

  [CREATE_PROFILE_SUCCESS](state, { profile: ctx, data }) {
    ctx.isLoading = false;

    each(data, (val, key) => {
      ctx.data[key] = val;
    });
  },

  [CREATE_PROFILE_ERROR](state, { product, profile: ctx, error }) {
    const profiles = state[product].list.data;
    profiles.splice(profiles.indexOf(ctx), 1);

    ctx.isLoading = false;
    ctx.error = error;
  },

  [SET_ACTIVE](state, { product, data = null }) {
    state[product].active = data;
  },

  [SET_BY_ID]() {
    // just trigger saga
  },

  [UPDATE_PROFILE](state, { product, profile }) {
    const entry = getters.byId(state)[product][profile.data.id];

    if (entry === undefined) {
      return;
    }

    entry.isLoading = true;
  },

  [UPDATE_PROFILE_SUCCESS](state, { product, profile, data }) {
    const entry = getters.byId(state)[product][profile.data.id];

    if (entry === undefined) {
      return;
    }

    entry.isLoading = false;
    entry.error = null;

    each(data, (val, key) => {
      entry.data[key] = val;
    });
  },

  [UPDATE_PROFILE_ERROR](state, { product, profile, error }) {
    const entry = getters.byId(state)[product][profile.data.id];

    if (entry === undefined) {
      return;
    }

    entry.isLoading = false;
    entry.error = error;
  },

  [DELETE_PROFILE](state, { product, profile }) {
    const profiles = state[product];

    if (profiles.active !== null && profiles.active.data.id === profile.data.id) {
      profiles.active = null;
    }

    profiles.list.data.splice(profiles.list.data.indexOf(profile), 1);
  },

  [DELETE_PROFILE_ERROR](state, { product, profile: ctx, error }) {
    state[product].list.data.push(ctx);
    ctx.error = error;
  },
};
