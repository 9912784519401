import documents from './documents';
import files from './files';
import questions from './questions';

import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import sagas from './sagas';
import state from './state';

export const NAMESPACE = 'offers/';

export default {
  namespaced: true,

  actions,
  getters,
  mutations,
  sagas,
  state,

  modules: {
    documents,
    files,
    questions,
  },
};
