import { Offer } from '@/types';
import { Module } from 'vuex';

import mutations from './mutations';
import getters from './getters';
import sagas from './sagas';

export const NAMESPACE = 'webreports/';
export interface WebreportsState {
  data: {
    clientId: number;
    tarife: Offer[],
    allTimeTariffs: Offer[],
    merkmale: any[],
    recommendation: string;
    vorgaben: any,
    id: string,
    offerId: number;
  },
  isLoading: boolean,
  error: null | any,
}

const createState = () => ({
  data: {
    clientId: 0,
    tarife: [],
    allTimeTariffs: [],
    merkmale: [],
    recommendation: '',
    vorgaben: {},
    id: '',
    offerId: 0,
  },
  isLoading: false,
  error: null,
});

const webreportsModule: Module<WebreportsState, any> & {sagas: any} = {
  namespaced: true,
  state: createState(),
  mutations,
  getters,
  sagas,
};

export default webreportsModule;
