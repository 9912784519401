import reduce from 'lodash/reduce';
import products from '@/products';

export const resourceContainer = (data = {}) => ({
  isLoading: false,
  error: null,
  data,
});

/**
 * Creates a state where each supported module
 * has its own "resource container".
 *
 * @param callback
 * @return {*}
 */
export default function createPerProductState(callback = resourceContainer) {
  return reduce(products, (state, product) => {
    state[product.props.product] = callback();
    return state;
  }, {});
}
