import mapKeys from 'lodash/mapKeys';
import calculateHash from './calculateHash';

export default {
  byInsuranceId(state, _, { product }) {
    if (!product) {
      return {};
    }

    return mapKeys(state[product].data, ({ insuranceId }) => insuranceId);
  },

  selectionHash(state, _, { product }) {
    if (!product) {
      return 0;
    }

    return calculateHash(state[product].data);
  },

  blacklistedCompanies(companies) {
    return companies
      .filter((company) => company.isBlacklisted)
      .map((company) => company.insuranceId);
  },
};
