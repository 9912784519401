export default {
  salutation_id: 'salutation',
  firstname: 'firstName',
  lastname: 'lastName',
  birthday: 'birthDate',
  street: 'address.street',
  street_number: 'address.number',
  zip_code: 'address.zipCode',
  city: 'address.city',
  nationality: 'nationality',
};
