import filter from 'lodash/filter';
import get from 'lodash/get';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import merge from 'lodash/merge';
import cloneDeep from 'lodash/cloneDeep';

import { transformAll } from '@demvsystems/yup-ast';

import api from '@/store/api';
import { offersMatchInput } from '@/router/utils/stepValidators';

import {
  call, put, select, takeEvery, takeLatest,
} from 'redux-saga/effects';

import { toForm } from '@/store/modules/client/form';
import {
  ROLE_VERSICHERUNGSNEHMER,
  ROLE_MTVERSICHERTER_PARTNER,
  ROLE_VERSICHERTE_PERSON,
  ROLE_ZAHLPERSON,
} from '@/store/modules/client/roles';
import { getClientByRole, isSameByRole } from '@/store/modules/client/getters';

import { REPLACE_ROUTE, SET_PRODUCT } from '@/store/modules/global/mutations';
import { searchData } from '@/store/modules/global/getters';

import { NAMESPACE as CLIENT_NAMESPACE } from '@/store/modules/client';
import { RESET_DIRTY_FLAG, SET_ID_BY_ROLE } from '@/store/modules/client/mutations';

import { NAMESPACE as OFFER_NAMESPACE } from '@/store/modules/offers/index';
import getters from '@/store/modules/offers/getters';
import { GET_OFFERS } from '@/store/modules/offers/actions/offersByCompany';
import {
  ADD_SELECTION, REMOVE_SELECTION, RESET_SELECTION, SET_RECOMMENDED,
} from '@/store/modules/offers/mutations';

import { NAMESPACE as CONTRACT_NAMESPACE, NAMESPACE } from './index';
import { TYPE_INITIAL, TYPE_ONLINE, TYPE_PRINT } from './constants';
import {
  CLEAR_ERROR,
  GENERATE_CONTRACT,
  GENERATE_CONTRACT_ERROR,
  GENERATE_CONTRACT_SUCCESS,
  RESET_CONTRACT_STATE,
  SET_VALUE,
} from './mutations';

function buildCientDaten(clients, needsBankDetails) {
  const excludeIfEmpty = [
    ROLE_MTVERSICHERTER_PARTNER,
    ROLE_ZAHLPERSON,
  ];

  const mergeIfSame = {
    [ROLE_ZAHLPERSON]: ROLE_VERSICHERUNGSNEHMER,
    [ROLE_VERSICHERTE_PERSON]: ROLE_VERSICHERUNGSNEHMER,
  };

  return reduce(clients, (acc, client, role) => {
    if (role === ROLE_ZAHLPERSON && !needsBankDetails) {
      return acc;
    }

    if (excludeIfEmpty.includes(role) && client.data.id === null && client.dirty === false) {
      return acc;
    }

    const mergeRole = mergeIfSame[role];

    if (mergeRole !== undefined && isSameByRole(clients, role, mergeRole)) {
      acc[mergeRole] = toForm(client.data, role, acc[mergeRole]);
      return acc;
    }

    acc[role] = toForm(client.data, role);
    return acc;
  }, {});
}

function* buildRequest(callback = () => ({})) {
  const state = yield select();
  const { contract, offers, client: clients } = state;

  const { eingabedaten } = searchData(state);
  const mergedOffers = getters.offers(offers);

  const vorversicherungsTarif = offers.vorversicherung.data[0];

  if (vorversicherungsTarif && getters.isSelected(vorversicherungsTarif)) {
    mergedOffers.unshift(vorversicherungsTarif);
  }

  const offersByIdentifier = getters.offersByIdentifier(offers, { offers: mergedOffers });
  const selection = getters.selection(offers, { offersByIdentifier });
  const { identifier } = offersByIdentifier[offers.recommended];

  // Needed null coalescing operator because allTimeTariffs don't have a question object generated
  const questions = state.offers.questions[state.product][identifier]?.data;

  // We need to validate which questions are relevant.
  // So we need the Data to validate as in questions/index.vue
  const relevantState = merge(
    cloneDeep(contract.data),
    cloneDeep(eingabedaten),
    cloneDeep(state.offers.answers[state.product]),
    cloneDeep(selection[0]),
  );

  const mergeAnswers = (obj, questionsRoot) => reduce(questionsRoot, (acc, question) => {
    if (question.name !== undefined) {
      let addQuestion = true;
      if (question.requirement !== undefined) {
        try {
          const schema = transformAll(question.requirement);
          addQuestion = schema.isValidSync(relevantState);
        } catch (e) {
          addQuestion = false;
        }
      }

      if (addQuestion) {
        const key = question.name;
        acc[key] = state.offers.answers[state.product][key];
      }
    }

    if (question.items !== undefined) {
      return mergeAnswers(acc, question.items);
    }
    return acc;
  }, obj);

  const answers = mergeAnswers({}, questions);

  // this only works for property products, as we remove all other offers from the state when
  // entering the contract page.
  let needsBankDetails = false;
  const paymentData = selection[0]
    .gesellschaft
    .zahlart;
  if (!Array.isArray(paymentData) && typeof paymentData === 'object') {
    needsBankDetails = paymentData.meta.needsBankDetails.includes(contract.data.zahlart);
  }

  return {
    eingabedaten,
    berechnungsId: offers.remoteHash,
    empfohlenerTarifId: offers.recommended,
    // TODO clean this up after release
    clientDaten: ['bu', 'rlv', 'pkv'].includes(state.product)
      ? {
        versichertePerson: toForm(
          clients[ROLE_VERSICHERTE_PERSON].data,
          ROLE_VERSICHERTE_PERSON,
        ),
        versicherungsnehmer: toForm(
          clients[ROLE_VERSICHERUNGSNEHMER].data,
          ROLE_VERSICHERUNGSNEHMER,
        ),
      }
      : buildCientDaten(clients, needsBankDetails),
    antragsdaten: {
      ...contract.data,
      gesellschaftsspezifischeAntworten: answers,
    },
    // deprected, will be removed in future
    kundenId: clients[ROLE_VERSICHERUNGSNEHMER].data.id,
    tarife: map(selection.filter((offer) => !offer.disableContract), ({
      identifier: selectionId,
      gesellschaft,
      name,
    }) => ({
      id: selectionId,
      name,
      dokumente: map(filter(state.offers.documents[selectionId].dokumente, ['checked', true]), 'name'),
      distributionInsuranceId: state.companies.distribution[state.product][
        gesellschaft.id
      ].channel.selection || gesellschaft.id,
    })),
    filters: state.offers.filters,
    ...callback({ mergedOffers, selection, state }),
  };
}

const requestTypes = {
  [TYPE_PRINT]: ({ mergedOffers, selection, state }) => {
    const pool = state.companies.distribution[
      state.product
    ][
      state.companies.distribution[state.product][
        selection[0].gesellschaft.id
      ].channel.selection || selection[0].gesellschaft.id
    ].pooling.selection;

    let configuration;

    if (pool !== null) {
      configuration = {
        pool,
      };
    }

    return {
      vergleich: map(selection, (offer) => ({
        id: offer.identifier,
        isAllTimeTariff: offer.identifier === state.offers.vorversicherung.data[0]?.identifier,
      })),
      sortierung: map(getters.ordered(state.offers, { requested: mergedOffers }), 'identifier'),
      printDetails: state.contract.printDetails,
      bankverbindung: toForm(state.client[ROLE_ZAHLPERSON].data, ROLE_ZAHLPERSON),
      konfigurationsParameter: configuration,
    };
  },

  [TYPE_INITIAL]: ({ selection, state }) => ({
    konfigurationsParameter: {
      pool: state.companies.distribution[
        state.product
      ][
        state.companies.distribution[state.product][
          selection[0].gesellschaft.id
        ].channel.selection || selection[0].gesellschaft.id
      ].pooling.selection,
    },
  }),

  [TYPE_ONLINE]: ({ selection, state }) => ({
    konfigurationsParameter: {
      pool: state.companies.distribution[
        state.product
      ][
        state.companies.distribution[state.product][
          selection[0].gesellschaft.id
        ].channel.selection || selection[0].gesellschaft.id
      ].pooling.selection,
    },
  }),
};

export function* generateContract({ payload }) {
  const contractType = payload.type || TYPE_PRINT;
  const requestData = {
    ...yield call(buildRequest, requestTypes[contractType]),
    ...(payload.requestData || {}),
  };

  try {
    if (requestData.tarife.length === 0) {
      throw new Error('Sie haben keine Tarife ausgewählt für die Antragsdokumente gedruckt werden könnten!');
    }

    const product = yield select((store) => store.product);
    const { data } = yield call(api, 'post', `/${product}/antrag/${contractType}`, requestData);

    yield put({
      type: NAMESPACE + GENERATE_CONTRACT_SUCCESS,
      payload: { type: contractType, data, requestData },
    });

    if (payload.onSuccess !== undefined) {
      payload.onSuccess(data);
      return;
    }

    yield put({
      type: REPLACE_ROUTE,
      payload: 'completion',
    });
  } catch (error) {
    yield put({
      type: NAMESPACE + GENERATE_CONTRACT_ERROR,
      payload: error,
    });

    if (payload.onError !== undefined) {
      payload.onError(error);
    }
  }
}

export function* setClientFromRequest({ payload }) {
  const kundenId = get(payload.data, 'data.kundenId', 0);
  if (kundenId === 0) {
    return;
  }

  const client = getClientByRole(yield select((store) => store.client), ROLE_VERSICHERUNGSNEHMER);
  yield put({
    type: CLIENT_NAMESPACE + RESET_DIRTY_FLAG,
    payload: client,
  });

  yield put({
    type: CLIENT_NAMESPACE + SET_ID_BY_ROLE,
    payload: {
      id: kundenId,
      role: ROLE_VERSICHERUNGSNEHMER,
    },
  });
}

export function* clearErrorOnOfferUpdate() {
  yield put({
    type: NAMESPACE + CLEAR_ERROR,
  });
}

function* generateIfValid(mutation) {
  if (offersMatchInput()) {
    yield call(generateContract, mutation);
    return;
  }

  const error = new Error('Die Kundendaten stimmen nicht mehr mit den Vorgaben überein. Bitte führen Sie eine Neuberechnung durch.');

  yield put({
    type: NAMESPACE + GENERATE_CONTRACT_ERROR,
    payload: error,
  });

  if (mutation.payload.onError !== undefined) {
    mutation.payload.onError(error);
  }
}

function* resetContractOnProductChange({ payload: product }) {
  yield put({
    type: CONTRACT_NAMESPACE + RESET_CONTRACT_STATE,
    payload: { product },
  });
}

function* resetPaymentMethodOnRecommendationChange({ payload }) {
  // don't reset paymentMethod when recommendation is set from loading an quote
  if (payload.fromQuote) {
    return;
  }

  yield put({
    type: CONTRACT_NAMESPACE + SET_VALUE,
    payload: { key: 'zahlart', value: null },
  });
}

export default function* root() {
  yield takeEvery(SET_PRODUCT, resetContractOnProductChange);

  yield takeEvery(OFFER_NAMESPACE + SET_RECOMMENDED, resetPaymentMethodOnRecommendationChange);

  yield takeLatest(NAMESPACE + GENERATE_CONTRACT, generateIfValid);

  yield takeLatest([
    NAMESPACE + GENERATE_CONTRACT_SUCCESS,
    NAMESPACE + GENERATE_CONTRACT_ERROR,
  ], setClientFromRequest);

  yield takeLatest([
    OFFER_NAMESPACE + RESET_SELECTION,
    OFFER_NAMESPACE + GET_OFFERS,
    OFFER_NAMESPACE + ADD_SELECTION,
    OFFER_NAMESPACE + REMOVE_SELECTION,
  ], clearErrorOnOfferUpdate);
}
