import {
  put,
  takeEvery,
} from 'redux-saga/effects';

import { NAMESPACE } from '@/store/modules/offers';
import { GET_OFFERS, GET_OFFERS_BY_COMPANY_SUCCESS } from '@/store/modules/offers/actions/offersByCompany';
import { ADD_OFFER_DOCUMENTS_BULK, RESET_CONTRACT_FIELDS } from '@/store/modules/offers/documents/mutations';

function* resetDocuments() {
  yield put({ type: NAMESPACE + RESET_CONTRACT_FIELDS });
}

function* insertOffer({ payload }) {
  const { tarife = [] } = payload.data;
  yield put({ type: NAMESPACE + ADD_OFFER_DOCUMENTS_BULK, payload: tarife });
}

export default function* root() {
  yield takeEvery(NAMESPACE + GET_OFFERS, resetDocuments);
  yield takeEvery(NAMESPACE + GET_OFFERS_BY_COMPANY_SUCCESS, insertOffer);
}
