import cloneDeep from 'lodash/cloneDeep';
import { Merkmal } from '@/types/feature/types';
import filter from 'lodash/filter';

interface RelevantInputFields {
  diensthaftpflicht: boolean;
}

export function filterItemsByMetaData(features: Merkmal[], eingabedaten: any) {
  const relevantInputFields: RelevantInputFields = {
    diensthaftpflicht: eingabedaten.diensthaftpflicht,
  };

  const copyFeatures = cloneDeep(features);

  for (let i = 0; i < copyFeatures.length; i += 1) {
    const { items } = copyFeatures[i];

    copyFeatures[i].items = filter(items, (item) => {
      const visibileIn = item.meta?.visible;

      if (visibileIn) {
        let visible = false;
        for (let indexVisibleIn = 0; indexVisibleIn < visibileIn.length; indexVisibleIn += 1) {
          visible = relevantInputFields[visibileIn[indexVisibleIn] as keyof RelevantInputFields];
        }

        return visible;
      }

      return true;
    });
  }

  return copyFeatures;
}

export default filterItemsByMetaData;
