// @ts-ignore
import preventExit from '@/events/preventExit';

// @ts-ignore
import { baseUrl } from '@/store/api';

export default function refreshSession(product: string, suffix = '') {
  // Automatically redirect to a login page in case we don't have a session yet
  preventExit.disable();

  let redirect = window.location.origin + process.env.BASE_URL + product + window.location.search;

  if (suffix.length > 0) {
    redirect += `${redirect.indexOf('?') > 0 ? '&' : '?'}${suffix}`;
  }

  window.location.href = `${baseUrl}/${product}/login?redirect=${encodeURIComponent(redirect)}&refresh=1`;
}
