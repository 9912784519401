import map from 'lodash/map';
import filter from 'lodash/filter';

// @ts-ignore
import hashCode from '@/utils/hashCode';

import Company from './Company';

/**
 * Calculates a numeric hash for the current company selection
 * based on which companies are blacklisted.
 *
 * @param companies
 */
export default function calculateHash(companies: Company[]) {
  const selection = filter(companies, (company) => !company.isBlacklisted);

  return hashCode(map(selection, (company) => company.insuranceId).sort());
}
