import {
  addDays,
  subYears,
  differenceInDays,
  differenceInYears,
  startOfDay,
} from 'date-fns';

/**
 * Formats a date object
 * @param {Date} date
 * @param {String} format like dd.MM.yyyy
 * @return {String}
 */
export function formatDate(date: Date, format: string) {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return format
    .replace(/dd/, `0${day}`.slice(-2))
    .replace(/d/, `${day}`)
    .replace(/yyyy/, `${year}`)
    .replace(/yy/, `${year}`.slice(2))
    .replace(/MM/, `0${month}`.slice(-2))
    .replace(/M/, `${month}`);
}

/**
 * Get a date object dating the first day in the coming month
 * @param {String} format of result, defaults to null to provide a Date object
 * @param {Date} now reference date, optional
 * @return {String|Date} result according to the given format
 */
export function getFirstOfNextMonth(format: string|null = null, now: Date = new Date()) {
  // Create local copy, so we keep passed value original
  const month = now.getMonth();
  const resultDate = new Date(now.getTime());

  // Set day to first
  resultDate.setDate(1);

  // Is last month in year
  if (now.getMonth() === 11) {
    resultDate.setMonth(0);
    resultDate.setFullYear(resultDate.getFullYear() + 1);
  } else {
    resultDate.setMonth(month + 1);
  }

  return format !== null ? formatDate(resultDate, format) : startOfDay(resultDate);
}

/**
 * Get a date object dating the next day in the current month or first day in the next month
 * @param {String} format of result, defaults to null to provide a Date object
 * @param {Date} now reference date, optional
 * @return {String|Date} result according to the given format
 */
export function getNextDay(format: string|null = null, now: Date = new Date()) {
  const date = startOfDay(addDays(now, 1));

  return format !== null ? formatDate(date, format) : date;
}

/**
 * Get a date object dating the day in one year (365 days)
 * @param {String} format of result, defaults to nul to provide a Date object
 * @param {Date} now references a date, optional
 * @returns {String|Date} result according to the given format
 */
export function getDayInOneYear(format: string|null = null, now: Date = new Date()) {
  const date = startOfDay(addDays(now, 365));

  return format !== null ? formatDate(date, format) : date;
}

/**
 * Get the date 100 years before the given date
 * @param {String} format of result, defaults to nul to provide a Date object
 * @param {Date} now references a date, optional
 * @returns {String|Date} result according to the given format
 */
export function getDay100YearsBefore(format: string|null = null, now: Date = new Date()) {
  const date = startOfDay(subYears(now, 100));

  return format !== null ? formatDate(date, format) : date;
}

/**
 * Calculate the age based on given birthdate.
 * @param birthDate {String} representing the date of birth
 * @param date {String} represents a date on which the age should be calculated
 * @returns {number} age in years.
 */
export function calculateAge(birthDate: string, date: string|null = null) {
  return differenceInYears(
    date !== null ? new Date(date) : Date.now(),
    new Date(birthDate),
  );
}

/**
 * Get tooday's date and sets it to start of the day
 * @returns {Date}
 */
export function getToday(format: string | null = null): Date | string {
  const date = startOfDay(new Date(Date.now()));
  return format !== null ? formatDate(date, format) : date;
}

/**
 * Returns the difference between to dates in days.
 * Examples:
 * getDifference('2020-08-02', '2020-08-01') => 1
 * getDifference('2020-08-01', '2020-08-05') => -4
 * @param {Date | String} dateOne represents the first date on
 * which the difference will be calculated
 * @param {Date | String} dateTwo represents the date on whiche the difference to
 * dateOne is calculated
 * @returns {number} the difference in years.
 */
export function getDifference(dateOne: Date | string, dateTwo: Date | string) {
  const dateA = dateOne instanceof Date ? dateOne : new Date(dateOne);
  const dateB = dateTwo instanceof Date ? dateTwo : new Date(dateTwo);
  return differenceInDays(dateA, dateB);
}
