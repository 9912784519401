import Vue from 'vue';
import Vuex from 'vuex';
import saga from './saga';

import auth from './modules/auth';
import choices from './modules/choices';
import client from './modules/client';
import companies from './modules/companies';
import contract from './modules/contract';
import features from './modules/features';
import globals from './modules/global';
import input from './modules/input';
import offers from './modules/offers';
import profiles from './modules/profiles';
import quote from './modules/quote';
import webreports from './modules/webreports';
import userSettings from './modules/userSettings';

Vue.use(Vuex);

const store = new Vuex.Store({
  // Only enable strict mode during development
  // as it does a deep watch on every change
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== 'production',

  modules: {
    auth,
    choices,
    companies,
    client,
    contract,
    features,
    input,
    offers,
    profiles,
    quote,
    webreports,
    userSettings,
  },

  plugins: [
    saga,
  ],

  ...globals,
});

export function useStore() {
  return store;
}

export default store;
