/* eslint-disable no-template-curly-in-string */
import { DateLocale, NumberLocale, MixedLocale } from 'yup/lib/locale';

interface LocaleObject {
  date: DateLocale;
  number: NumberLocale;
  mixed: MixedLocale;
}

const locales: LocaleObject = {
  date: {
    max: (params) => `Das Datum darf nicht nach dem ${(params.max as Date).toLocaleDateString()} liegen.`,
    min: (params) => `Das Datum darf nicht vor dem ${(params.min as Date).toLocaleDateString()} liegen.`,
  },

  number: {
    min: 'Der Wert muss mindestens ${min} betragen.',
    max: 'Der Wert darf maximal ${max} sein.',
    integer: 'Bitte nur ganze Zahlen eingeben.',

  },

  mixed: {
    required: 'Bitte füllen Sie das Pflichtfeld aus.',
    oneOf: 'Muss einer der folgenden Werte sein: ${values}',
    notOneOf: 'Bitte füllen Sie das Pflichtfeld aus.',
    notType: 'Bitte füllen Sie das Pflichtfeld aus.',
  },
};

export default locales;
