export default {
  companies: {
    editor: () => import('./companies/Editor.vue'),
  },
  client: {
    editor: () => import('./client/Editor.vue'),
    search: () => import('./client/search/index.vue'),
    fillClient: () => import('./client/FillClientAddress.vue'),
  },
  complexSelect: () => import('./complex-select/index.vue'),
  print: {
    contract: {
      provident: () => import('./print/ContractProvident.vue'),
      property: () => import('./print/ContractProperty.vue'),
    },
    offer: () => import('./print/Offer.vue'),
  },
  profile: {
    create: () => import('./profile/Create.vue'),
    editor: () => import('./profile/Editor/index.vue'),
  },
  saveQuote: () => import('./SaveQuote.vue'),
  offer: {
    priceChange: () => import('./offer-price-change/index.vue'),
  },
  ConstructionType: () => import('./ConstructionType.vue'),
  PriceIn1914Calculator: () => import('./PriceIn1914Calculator.vue'),
  Session: {
    endedDialog: () => import('./SessionRefresh/SessionEndedDialog.vue'),
    loadOldDataDialog: () => import('./SessionRefresh/LoadPrevSessionDataDialog.vue'),
  },
  userSettings: {
    editor: () => import('./userSettings/Editor.vue'),
  },
  oldTariffSelect: () => import('./oldTariffSelect/index.vue'),
  gkvCostSavings: () => import('./gkvCostSavings/gkvCostSavings.vue'),
};
