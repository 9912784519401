import keys from 'lodash/keys';

export default {
  /**
   * Returns a list of all the names of our loaded choices.
   *
   * @param state
   * @return {string[]}
   */
  companyLoaded(state) {
    return keys(state.companies);
  },

  /**
   * Checks if we have already loaded the companies for the given key.
   *
   * @param state
   * @param getters
   * @return {function(*=): boolean}
   */
  containsCompany(state, getters) {
    return (key) => getters.companyLoaded.indexOf(key) >= 0;
  },
};
