import Vue from 'vue';

import find from 'lodash/find';
import each from 'lodash/each';
import map from 'lodash/map';

export const UPDATE_OFFER_DOCUMENT = 'updateOfferDocument';
export const UPDATE_OFFER_DOCUMENTS = 'updateOfferDocuments';
export const SET_DOCUMENTS_CHECKED_STATE = 'selectAllDocuments';

export const ADD_OFFER_DOCUMENTS = 'addOfferDocuments';
export const ADD_OFFER_DOCUMENTS_BULK = 'addOfferDocumentsBulk';
export const RESET_CONTRACT_FIELDS = 'resetContractFields';

const findOffer = (state, identifier) => (state[identifier] || null);

export default {
  [SET_DOCUMENTS_CHECKED_STATE](state, { identifier, checked = true } = {}) {
    if (identifier === null) {
      return;
    }

    const tarif = findOffer(state, identifier);
    if (tarif === null) {
      return;
    }

    each(tarif.dokumente, (ctx) => {
      ctx.checked = checked;
    });
  },

  [UPDATE_OFFER_DOCUMENT](state, { identifier, name, value }) {
    const { dokumente = [] } = findOffer(state, identifier) || {};
    const dokument = find(dokumente, { name });

    if (dokument) {
      dokument.checked = value;
    }
  },

  [UPDATE_OFFER_DOCUMENTS](state, { identifier, value }) {
    const { dokumente = [] } = findOffer(state, identifier) || {};

    each(dokumente, (ctx) => {
      ctx.checked = value;
    });
  },

  [RESET_CONTRACT_FIELDS](state) {
    each(state, (tarif) => {
      each(tarif.dokumente, (ctx) => {
        ctx.checked = false;
      });
    });
  },

  [ADD_OFFER_DOCUMENTS](state, { identifier, documents }) {
    Vue.set(state, identifier, {
      dokumente: map(documents, ((name) => ({ name, checked: false }))),
    });
  },

  [ADD_OFFER_DOCUMENTS_BULK](state, tarife) {
    for (let i = 0; i < tarife.length; i += 1) {
      const { identifier, dokumente: documents } = tarife[i];
      Vue.set(state, identifier, {
        dokumente: map(documents, ((name) => ({ name, checked: false }))),
      });
    }
  },
};
