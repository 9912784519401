import createPerProductState, { resourceContainer } from '@/utils/store/createPerProductState';

import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import sagas from './sagas';

export const NAMESPACE = 'profiles/';

export default {
  namespaced: true,

  actions,
  mutations,
  getters,
  sagas,

  state: createPerProductState(() => ({
    active: null,
    list: {
      ...resourceContainer(),
      data: [],
    },
  })),
};
