import { COLLAPSE_GROUP, EXPAND_GROUP, SET_FEATURE_VALUE } from './mutations';

export default {
  /**
   * Set's the feature value for the given key
   *
   * @param {Object} values
   * @param {String} values.identifier
   * @param {String} values.value
   */
  setFeatureValue({ commit, getters, rootState }, { identifier, value }) {
    const feature = getters.byKey[rootState.product][identifier];
    commit(SET_FEATURE_VALUE, { feature, value });
  },

  /**
   * Collapses or expands the given feature group.
   *
   * @param commit
   * @param getters
   * @param rootState
   * @param group
   */
  toggleGroup({ commit, getters, rootState }, group) {
    const mutation = getters.isHidden(group) ? EXPAND_GROUP : COLLAPSE_GROUP;

    commit(mutation, {
      product: rootState.product,
      group,
    });
  },
};
