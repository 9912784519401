import find from 'lodash/find';
import reduce from 'lodash/reduce';
import translateSymfonyErrors from '@/utils/api/translateSymfonyErrors';

/**
 * Find a single client by role in state
 * @param state
 * @param role
 * @returns {Object|undefined}
 */
export function getClientByRole(state, role) {
  return state[role];
}

/**
 * Find a single client by id in state
 * @param state
 * @param id
 * @returns {Object|undefined}
 */
export function getClientById(state, id) {
  return find(state, (obj) => obj.data.id === id);
}

/**
 * Checks if the client with given role A also has role B.
 *
 * @param state
 * @param a
 * @param b
 * @return Boolean
 */
export function isSameByRole(state, a, b) {
  const first = getClientByRole(state, a);
  const second = getClientByRole(state, b);

  // In case we have two "empty" clients, we need to check against the direct instance
  if (first === second) {
    return true;
  }

  if (first.data.id === null && second.data.id === null) {
    return false;
  }

  return first.data.id === second.data.id;
}

export function getRoleGroupedValidationErrors(state) {
  return reduce(state, (acc, field, key) => ({
    ...acc,
    [key]: field.error && Object.prototype.hasOwnProperty.call(field.error, 'data')
      ? translateSymfonyErrors(field.error.data.data)
      : field.error,
  }), {});
}

export default {
  /**
   * Gets the client with the given role
   */
  getClientByRole: (state) => (role) => getClientByRole(state, role),

  /**
   * Gets the client with the given id
   */
  getClientById: (state) => (id) => getClientById(state, id),

  isSameByRole: (state) => (a, b) => isSameByRole(state, a, b),

  getRoleGroupedValidationErrors,
};
