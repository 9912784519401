// @ts-ignore
import createPerProductState from '@/utils/store/createPerProductState';

import mutations from './mutations';
import getters from './getters';
// @ts-ignore
import sagas from './sagas';

export const NAMESPACE = 'companies/distribution/';

export default {
  namespaced: true,

  mutations,
  getters,
  sagas,

  state: createPerProductState(() => ({})),
};
