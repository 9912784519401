export default {
  salutation_id: 'salutation',
  firstname: 'firstName',
  lastname: 'lastName',
  title: 'title',
  birthday: 'birthDate',
  street: 'address.street',
  street_number: 'address.number',
  zip_code: 'address.zipCode',
  city: 'address.city',
  bank_name: 'bank.bankName',
  iban_code: 'bank.ibanCode',
  swift_code: 'bank.swiftCode',
};
