import { AxiosResponse } from 'axios';
import { BaseAction } from '@/store/common';

// @ts-ignore
import { NAMESPACE } from '..';

export const GET_OFFERS = 'getOffers';
export const GET_OFFERS_BY_COMPANY = 'getOffersByCompany';
export const GET_OFFERS_BY_COMPANY_SUCCESS = 'getOffersByCompanySuccess';
export const GET_OFFERS_BY_COMPANY_ERROR = 'getOffersByCompanyError';

export interface GetOffersByCompanyError {
  id?: number;
  error?: AxiosResponse;
}
export type GetOffersByCompanyErrorAction = BaseAction<GetOffersByCompanyError>

export const getOffersByCompanyError = (
  payload: GetOffersByCompanyError,
): GetOffersByCompanyErrorAction => ({
  type: NAMESPACE + GET_OFFERS_BY_COMPANY_ERROR,
  payload,
});
