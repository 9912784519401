import mapAssoc from '@/utils/mapAssoc';
import mapValues from 'lodash/mapValues';

export default {
  /**
   * Maps all profiles by their ID, grouped by product name.
   *
   * @param {Object} state
   * @return {Object}
   */
  byId(state) {
    return mapValues(state, ({ list }) => mapAssoc(list.data, ({ data }) => data.id));
  },
};
