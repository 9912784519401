import get from 'lodash/get';
import { Product } from '@/types';
// @ts-ignore
import store from '@/store';
// @ts-ignore
import products from '@/products';
// @ts-ignore
import { NAMESPACE } from '@/store/modules/offers';
// @ts-ignore
import { ADD_SELECTION, RESET_SELECTION, SET_RECOMMENDED } from '@/store/modules/offers/mutations';
import { isOfferSelected, offersMatchInput } from '@/router/utils/stepValidators';
import routeName from '@/router/utils/routeName';
import { StepConfig } from '../types';
import { ExtraStep } from '.';

function shouldChannel(insuranceId: string) {
  if (insuranceId === undefined) {
    return false;
  }

  const { companies, product } = store.state;

  if (products[product as Product].props.shouldChannel !== true) {
    return false;
  }

  const { selection } = companies.distribution[product][insuranceId].channel;

  return selection;
}

const stepConfig: StepConfig = {
  path: 'antrag/:identifier?',

  props: {
    icon: ['far', 'file'],
    label: 'Antrag',

    isValid() {
      return isOfferSelected() && offersMatchInput();
    },
  },

  beforeEnter: (to, from, next) => {
    // Set the currently selected offer based on the route parameter
    const { identifier } = to.params;

    // Step is needed so we don't have multiple tarifs on the contract page!
    if (identifier !== undefined) {
      store.commit(NAMESPACE + RESET_SELECTION);
      store.commit(NAMESPACE + ADD_SELECTION, { identifier });
    }

    // Mark the recommended offer based on the selection
    const { recommended } = store.state.offers;

    if (recommended === null || recommended === undefined) {
      store.commit(NAMESPACE + SET_RECOMMENDED, {
        identifier: store.state.offers.selected[0],
      });
    }

    const insuranceId = get(store.getters[`${NAMESPACE}recommendation`], ['gesellschaft', 'id']);

    // If we have to check for the distribution channel, redirect first
    const channelId = shouldChannel(insuranceId);

    if (channelId === false) {
      next();
      return;
    }

    if (channelId === undefined || !to.params.canSkip) {
      next({ name: routeName(ExtraStep.SelectChannel), params: { insuranceId } });
      return;
    }

    next();
  },
};

export default stepConfig;
