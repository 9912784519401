import { Role } from '@/store/modules/client/roles';
import { ProductConfig } from '@/types';
// @ts-ignore
import image from './assets/bg.jpg';
import filterCategories from '@/products/res/utils/featureFilter';
import reduce from 'lodash/reduce';

const config: ProductConfig = {
  path: '/res',
  component: () => import('@/views/layout/BaseLayout.vue'),
  // Config
  props: {
    name: 'Rechtsschutz',
    heading: 'Rechtsschutz',
    product: 'res',
    icon: 'home',
    image,

    // distribution settings
    shouldChannel: true,
    shouldPool: true,

    // how many request to send to backend for offers
    numChunks: 3,

    // which client data need to be send to the backend
    clientData: {
      [Role.VersichertePerson]: {
        'clientDaten.versichertePerson.geburtsdatum': 'birthDate',
        'clientDaten.versichertePerson.plz': 'address.zipCode',
      },
      [Role.Versicherungsnehmer]: {
        'clientDaten.versicherungsnehmer.geburtsdatum': 'birthDate',
        'clientDaten.versicherungsnehmer.plz': 'address.zipCode',
      },
    },

    // role settings
    productSyncRoleSettings: {
      [Role.Versicherungsnehmer]: [Role.VersichertePerson, Role.Zahlperson],
      [Role.VersichertePerson]: [Role.Versicherungsnehmer, Role.Zahlperson],
    },

    profileFields: [
      'eingabedaten.selbstbeteiligung',
      'eingabedaten.tarifgruppe',
      'eingabedaten.berufsstatus',
      'eingabedaten.leistungsumfang.privat',
      'eingabedaten.leistungsumfang.beruf',
      'eingabedaten.leistungsumfang.verkehr',
      'eingabedaten.leistungsumfang.hasMehrereFahrzeuge',
      'eingabedaten.leistungsumfang.anzahlFahrzeuge',
      'eingabedaten.leistungsumfang.fahrzeugtyp',
      'eingabedaten.leistungsumfang.mieten',
      'eingabedaten.leistungsumfang.vermietung',
      'eingabedaten.leistungsumfang.bruttojahresmieteBetrag1',
      'eingabedaten.leistungsumfang.bruttojahresmieteBetrag2',
      'eingabedaten.leistungsumfang.bruttojahresmieteBetrag3',
      'eingabedaten.leistungsumfang.vermietungObjekteAnzahl',
    ],

    keepInputFromQuotes: [
      'birthDate',
      'address.zipCode',
    ],

    defaults: {
      contract: () => ({
        antragAbgelehnt: false,
        zahlart: null,
        memofeld: '',
        vorversicherungsDaten: {
          gesellschaft: null,
          versicherungsnummer: '',
          kuendigender: null,
          kuendigen: false,
          beginn: '',
          ablauf: '',
        },
        vorschaden: {
          bestehenAnsprueche: false,
          memofeldAnsprueche: '',
          entschaedigungszahlung: null,
        },
        zusaetzlicheAngaben: {
          allgemein: {
            vorversicherungPartner: false,
            vorversicherungVersicherungsnehmer: false,
            vorversicherungVersicherungsnummerPartner: '',
            gesellschaft: null,
          },
          mieten: {
            gebaeudeart: 'wohnung',
            nutzungsart: 'eigentuemer',
          },
          vermietung: {
            ...reduce(Array(3), (acc, _, index) => ({ ...acc, [`vermietungObjektAnschrift${index}`]: '' }), {}),
          },
          verkehr: {
            ...reduce(Array(6), (acc, _, index) => ({ ...acc, [`verkehrFahrzeugKennzeichen${index}`]: '' }), {}),
          },
        },
      }),
    },

    offerDetail: () => import('./views/offerdetail.vue'),

    featureFilter: (store: any, features: any): any => {
      const { product } = store.state;
      const { eingabedaten } = store.state.input[product].data;
      const { leistungsumfang, tarifgruppe } = eingabedaten;
      const isTarifgruppeFamilie = store.getters['choices/simple/meta']?.resTarifgruppe?.isFamilie.includes(tarifgruppe);

      return filterCategories(
        features,
        leistungsumfang,
        isTarifgruppeFamilie,
        leistungsumfang.hasMehrereFahrzeuge,
      );
    },
  },

  // Wizard steps
  steps: {
    input: {
      component: () => import('./views/input.vue'),
    },
    offers: {
      component: () => import('./views/offers.vue'),
    },
    comparison: {
      component: () => import('./views/comparison.vue'),
      meta: {
        showContractButton: false,
      },
    },
    contract: {
      component: () => import('./views/contract.vue'),
    },
    completion: {
      component: () => import('@/views/pages/completion/type/Property.vue'),
    },
  },
};

export default config;
