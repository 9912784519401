import axios, {
  AxiosRequestConfig, AxiosError, AxiosInstance, Method,
} from 'axios';
import { CANCEL } from 'redux-saga';
import qs from 'qs';
import Vue from 'vue';

// @ts-ignore
import store from '@/store';
import modals from '@/views/modals/';

import sendToSentry from '@/utils/api/sendToSentry';
// @ts-ignore
import refreshSession from '@/utils/refreshSession';
// @ts-ignore
import JSendTransformResponse from '@/utils/api/JSendTransformResponse';

type httpMethodsWithData = 'post' | 'patch' | 'put';
type httpMethodsWithOutData = 'get' | 'delete';

const globalOptions: AxiosRequestConfig = {
  baseURL: '',
};

if (process.env.NODE_ENV === 'development') {
  globalOptions.baseURL = process.env.VUE_APP_DEV_BASE_URL;
  globalOptions.withCredentials = true;
}

// Send all errors to Sentry, except for 400 and 401 errors
const onApiError = (error: AxiosError) => {
  if (error.response && error.response.status === 401) {
    const user = store.state.auth.user.data;
    if (user.email || user.id) {
      // @ts-ignore
      Vue.modal(modals.Session.endedDialog);
    } else {
      refreshSession(store.state.product);
    }
  } else if (error.response && error.response.status !== 400 && error.response.status !== 404) {
    sendToSentry(error);
  }

  return Promise.reject(error.response || {
    status: 599,
  });
};

const instance = axios.create({
  ...globalOptions,

  // Use a root URL for all requests as we live under a different "namespace"
  baseURL: `${globalOptions.baseURL || ''}${process.env.VUE_APP_API_PATH}`,
});

instance.interceptors.response.use(JSendTransformResponse, onApiError);

const pwInstance = axios.create({
  ...globalOptions,

  baseURL: process.env.VUE_APP_PW_API_PATH || '',
  withCredentials: false,

  // Adds AJAX support for our Yii (Professional works) backend
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

pwInstance.interceptors.response.use(JSendTransformResponse, onApiError);

const bsInstance = axios.create({
  baseURL: process.env.VUE_APP_BS_API_PATH || '',
});

bsInstance.interceptors.response.use(JSendTransformResponse, onApiError);

const doRequest = (
  fetch: AxiosInstance,
  method: Method,
  url: string,
  data: any = {},
  options: AxiosRequestConfig = {},
  transformer: any = null,
) => {
  const httpMethod: keyof AxiosInstance = method.toLowerCase() as keyof AxiosInstance;

  const hasData = ['post', 'put', 'patch'].indexOf(httpMethod) >= 0;
  const settings = hasData ? options : data;

  const source = axios.CancelToken.source();
  settings.cancelToken = source.token;

  const transformedData = (hasData && transformer !== null) ? transformer(data) : data;
  const request = hasData
    ? fetch[httpMethod as httpMethodsWithData](url, transformedData, settings)
    : fetch[httpMethod as httpMethodsWithOutData](url, settings);

  // Not sure what the actual value is here?
  // @ts-ignore
  request[CANCEL] = () => source.cancel();

  return request;
};

export const baseUrl = instance.defaults.baseURL;

/**
 * Cancelable axios call using our "legacy" api (Professional works).
 *
 * This uses urlencoded formdata as its content-type as not all routes
 * support application/json yet.
 *
 * @param method
 * @param url
 * @param data
 * @param options
 * @returns {*}
 */
export function pwApi(
  method: Method,
  url: string,
  data: any = {},
  options: AxiosRequestConfig = {},
) {
  return doRequest(pwInstance, method, url, data, options, qs.stringify);
}

/**
 * Cancelable axios call using the bankdatenservice api.
 *
 * @param method
 * @param url
 * @param data
 * @param options
 * @returns {*}
 */

export function bsApi(
  method: Method,
  url:string,
  data: any = {},
  options: AxiosRequestConfig = {},
) {
  return doRequest(bsInstance, method, url, data, options);
}

/**
 * Cancelable axios call to be used by redux-saga.
 *
 * Based on
 * https://github.com/redux-saga/redux-saga/issues/651#issuecomment-262375964
 *
 * @param method The HTTP method
 * @param url The URL to request
 * @param data (only for POST, PUT, PATCH): the form data
 * @param options Additional options
 * @return {*}
 */
export default function api(
  method: Method,
  url: string,
  data: any = {},
  options: AxiosRequestConfig = {},
) {
  return doRequest(instance, method, url, data, options);
}
