import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import sagas from './sagas';

export const NAMESPACE = 'features/offers/';

export default {
  namespaced: true,

  actions,
  getters,
  mutations,
  sagas,

  // The following state describes a queue, with a fixed limit
  // to prevent RAM filling up on mobile/tablet devices
  state: {
    entries: [],
    limit: 16,
  },
};
