import forEach from 'lodash/forEach';
import map from 'lodash/map';
import merge from 'lodash/merge';
import set from 'lodash/set';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import products from '@/products';
import jQuerySerialize from '@/utils/store/jQuerySerialize';
import hashCode from '@/utils/hashCode';

import { byKey, evaluatedFeatures } from '@/store/modules/features/getters';
import companyGetters from '@/store/modules/companies/getters';

export function searchData({
  client, features, input, product, companies,
}, forQuote = false) {
  if (product === null) {
    return Object.freeze({});
  }

  const data = merge({ entities: {} }, input[product].data);

  forEach(products[product].props.clientData, (config, role) => {
    const clientFields = {};
    forEach(config, (name, key) => {
      // DEPRECATED clientData, will be removed with the RS
      set(data, key, get(client[role].data, name));
      clientFields[key.split('.').pop()] = get(client[role].data, name);
    });

    // eslint-disable-next-line no-underscore-dangle
    if (Object.prototype.hasOwnProperty.call(data.entities, client[role]._refId)) {
      // eslint-disable-next-line no-underscore-dangle
      data.entities[client[role]._refId].roles.push(role);
    } else {
      // eslint-disable-next-line no-underscore-dangle
      data.entities[client[role]._refId] = {
        // eslint-disable-next-line no-underscore-dangle
        refId: client[role]._refId,
        type: 'client',
        roles: [role],
        data: clientFields,
      };
    }
  });

  const byKeyCache = byKey(features);
  const values = evaluatedFeatures(
    features,
    { byKey: byKeyCache },
    { product },
    { searchData: data },
  );

  data.merkmale = map(
    byKeyCache[product],
    ({ identifier }) => ({ identifier, value: values[identifier] }),
  );

  if (data.vorversicherung?.aktiv) {
    if (forQuote) {
      data.allTimeTariff = input[product].data.vorversicherung;
    } else {
      // as those data might be very different per sparte I decided to make it a
      // per product config formatter function that generates this data
      const { oldTariffFormatter } = products[product].props;
      if (oldTariffFormatter && isFunction(oldTariffFormatter)) {
        data.allTimeTariff = oldTariffFormatter(input[product].data);
      }
    }
  }

  // remove vorversicherungen from search data
  delete data.vorversicherung;

  data.blacklistedCompaniesHash = hashCode(
    companyGetters.blacklistedCompanies(companies[product].data ?? []),
  ).toString();

  return Object.freeze(data);
}

export default {
  /**
   * Serializes the given object using either the legacy format
   * or just the object itself, depending on the current product
   * configuration.
   *
   * @param state
   * @return {function(*=): Array}
   */
  serialize(state) {
    const { legacy = {} } = products[state.product] || {};
    const { serialize = false } = legacy;

    return (obj) => (serialize ? jQuerySerialize(obj) : obj);
  },

  /**
   * Merges all the relevant data entered by the user
   * for all kinds of requests (e.g. offers).
   *
   * @returns {Object}
   */
  searchData(state) {
    return searchData(state);
  },

  requestHash(_, getters) {
    return hashCode(getters.searchData);
  },
};
