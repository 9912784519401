import each from 'lodash/each';

import { FEATURE_UNCHECKED } from './constants';

export const GET_FEATURES = 'getFeatures';
export const GET_FEATURES_SUCCESS = 'getFeaturesSuccess';
export const GET_FEATURES_ERROR = 'getFeaturesError';
export const SET_FEATURE_VALUE = 'setFeatureValue';
export const COLLAPSE_GROUP = 'collapseGroup';
export const EXPAND_GROUP = 'expandGroup';

export default {
  [GET_FEATURES](state, product) {
    state[product].isLoading = true;
  },

  [GET_FEATURES_SUCCESS](state, { product, data }) {
    const reset = (ctx) => {
      ctx.value = FEATURE_UNCHECKED;
      each(ctx.items, reset);

      // Add this so we can easily access the "feature group"
      ctx.product = product;

      if (ctx.requirements !== undefined) {
        ctx.requirements = Object.freeze(ctx.requirements);
      }
    };

    each(data, reset);

    state[product].isLoading = false;
    state[product].data = data;
  },

  [GET_FEATURES_ERROR](state, { product, error }) {
    state[product].isLoading = false;
    state[product].error = error;
  },

  [SET_FEATURE_VALUE](state, { feature: ctx, value }) {
    if (ctx !== undefined) {
      ctx.value = value;
    }
  },

  [COLLAPSE_GROUP](state, { product, group }) {
    state[product].collapsed.push(group.label);
  },

  [EXPAND_GROUP](state, { product, group }) {
    const { label } = group;
    const { collapsed } = state[product];
    const idx = collapsed.indexOf(label);

    if (idx >= 0) {
      collapsed.splice(idx, 1);
    }
  },
};
