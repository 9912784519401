import find from 'lodash/find';
import merge from 'lodash/merge';
import reduce from 'lodash/reduce';

import Vue from 'vue';
import api from '@/store/api';

import {
  all,
  call,
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import products from '@/products';
import { getFirstOfNextMonth, getNextDay } from '@/utils/date';
import waitForProduct from '@/store/effects/waitForProduct';

import { searchData } from '@/store/modules/global/getters';

import { NAMESPACE as CLIENT_NAMESPACE } from '@/store/modules/client';
import { NAMESPACE as COMPANY_NAMESPACE } from '@/store/modules/companies';
import { ADD_CLIENT_TO_ROLE, createClient } from '@/store/modules/client/mutations';
import { getClientById } from '@/store/modules/client/getters';
import { FORM_MAP_FULL, toForm, toState } from '@/store/modules/client/form';

import roles, { ROLE_VERSICHERUNGSNEHMER } from '@/store/modules/client/roles';

import { NAMESPACE as CONTRACT_NAMESPACE } from '@/store/modules/contract';
import { GENERATE_CONTRACT_SUCCESS, SET_DATA } from '@/store/modules/contract/mutations';

import { NAMESPACE as OFFER_NAMESPACE } from '@/store/modules/offers';
import { GET_OFFERS_BY_COMPANY_SUCCESS } from '@/store/modules/offers/actions/offersByCompany';
import offerGetters from '@/store/modules/offers/getters';
import companyGetters from '@/store/modules/companies/getters';
import {
  ADD_SELECTION,
  RESET_SELECTION,
  SET_RECOMMENDED,
  SET_REQUEST_FILTER,
} from '@/store/modules/offers/mutations';

import { NAMESPACE } from './index';
import { resetAfterLoad } from './constants';

import {
  UPSERT_QUOTE,
  UPSERT_QUOTE_ERROR,
  UPSERT_QUOTE_SUCCESS,
  GET_QUOTE_BY_ID,
  GET_QUOTE_BY_ID_ERROR,
  GET_QUOTE_BY_ID_SUCCESS,
  RESET_QUOTE,
  SAVE_BESTANDSVERTRAG,
  SAVE_BESTANDSVERTRAG_SUCCESS,
  SAVE_BESTANDSVERTRAG_ERROR,
} from './mutations';

import { NAMESPACE as INPUT_NAMESPACE } from '@/store/modules/input';
import { SET_INPUT } from '@/store/modules/input/mutations';

import { NAMESPACE as FEATURE_NAMESPACE } from '@/store/modules/features';
import { SET_FEATURE_VALUE, GET_FEATURES_SUCCESS } from '@/store/modules/features/mutations';
import { byKey } from '@/store/modules/features/getters';

import modals from '@/views/modals';
import { TYPE_PRINT } from '@/store/modules/contract/constants';
import { SET_BLACKLIST } from '@/store/modules/companies/mutations';

function* buildContent() {
  const state = yield select();
  const offers = {
    offers: offerGetters.offers(state.offers),
    isRecommended: offerGetters.isRecommended(state.offers),
  };

  const filters = {
    ...state.offers.filters,
    blacklistedCompanies: companyGetters.blacklistedCompanies(state.companies[state.product].data),
  };

  return {
    suche: searchData(state, true),
    vergleich: {
      empfehlung: offerGetters.recommendation(null, offers),
      vergleichGroup: offerGetters.selection(state.offers, {
        offersByIdentifier: offerGetters.offersByIdentifier(null, offers),
        vorversicherungsOffer: offerGetters.vorversicherungsOffer(state.offers),
      }),
    },

    filters,

    // Always add all clients so we can recreate the state later
    kunden: reduce(roles, (acc, role) => {
      acc[role] = toForm(state.client[role].data, FORM_MAP_FULL);
      return acc;
    }, {}),
  };
}

function* upsert(mutation) {
  const {
    product, title, overwrite, onSuccess,
  } = mutation.payload;

  const currentId = yield select((state) => state.quote[product].data.id);

  const payload = {
    titel: title,
    content: yield call(buildContent),
  };

  try {
    const { data } = currentId !== undefined && overwrite
      ? yield call(api, 'put', `${product}/angebot/${currentId}`, payload)
      : yield call(api, 'post', `${product}/angebot`, {
        clientId: (yield select((state) => state.client[ROLE_VERSICHERUNGSNEHMER].data.id)),
        ...payload,
      });

    yield put({
      type: NAMESPACE + UPSERT_QUOTE_SUCCESS,
      payload: { product, data },
    });

    if (onSuccess !== undefined) {
      onSuccess();
    }
  } catch (error) {
    yield put({
      type: NAMESPACE + UPSERT_QUOTE_ERROR,
      payload: { product, error },
    });
  }
}

/**
 * Loads a quote for the given ID in the given product category.
 *
 * @param payload
 * @return {IterableIterator<*>}
 */
export function* load({ payload }) {
  const {
    product, type, onSuccess, onError,
  } = payload;

  try {
    const { data } = yield call(api, 'get', `${product}/angebot/${payload.id}`);

    if (data.content.suche.eingabedaten.zugangsweg === null) {
      delete data.content.suche.eingabedaten.zugangsweg;
    }

    const dateFunction = products[product].props.useFirstOfNextMonth
      ? getFirstOfNextMonth
      : getNextDay;

    if (data.content.suche.eingabedaten.versicherungsbeginn) {
      const versicherungsBeginn = data.content.suche.eingabedaten.versicherungsbeginn;

      if (Date.parse(versicherungsBeginn) < dateFunction()) {
        data.content.suche.eingabedaten.versicherungsbeginn = dateFunction('yyyy-MM-dd');
      }
    } else {
      const versicherungsBeginn = data.content.suche.eingabedaten.allgemein.versicherungsbeginn;

      if (Date.parse(versicherungsBeginn) < dateFunction()) {
        data.content.suche.eingabedaten.allgemein.versicherungsbeginn = dateFunction('yyyy-MM-dd');
      }
    }

    yield put({
      type: NAMESPACE + GET_QUOTE_BY_ID_SUCCESS,
      payload: { product, data, type },
    });

    if (onSuccess !== undefined) {
      onSuccess(data);
    }
  } catch (error) {
    yield put({
      type: NAMESPACE + GET_QUOTE_BY_ID_ERROR,
      payload: { product, error, type },
    });

    if (onError !== undefined) {
      onError(error);
    }
  }
}

export function* loadInputFromQuote({ payload }) {
  const { suche } = payload.data.content;

  const data = {
    eingabedaten: { ...suche.eingabedaten },
    vorversicherung: { ...suche.allTimeTariff },
  };

  if (suche.gkvErsparnis) {
    if (suche.gkvErsparnis.tarif instanceof Array) {
      suche.gkvErsparnis.tarif = {};
    }

    data.gkvErsparnis = { ...suche.gkvErsparnis };
  }

  if (data.eingabedaten.photovoltaik) {
    if (data.eingabedaten.photovoltaik instanceof Array) {
      if (data.eingabedaten.photovoltaikVorhanden) {
        data.eingabedaten.photovoltaik = {
          feuerwehrschalter: false,
          mitversicherung: 'nein',
        };
      } else {
        delete data.eingabedaten.photovoltaik;
      }
    }
  }

  yield put({
    type: INPUT_NAMESPACE + SET_INPUT,
    payload: {
      product: payload.product,
      data,
    },
  });
}

/**
 * Set feature store according to quote features
 *
 * @param payload
 * @return {IterableIterator<*>}
 */
export function* loadFeaturesFromQuote({ payload }) {
  const { product } = payload;
  const featuresObj = yield select(({ features }) => (byKey(features)[product]));
  const { merkmale = [] } = payload.data.content.suche;

  for (let i = 0; i < merkmale.length; i += 1) {
    const { identifier, value } = merkmale[i];
    const feature = featuresObj[identifier];

    if (feature !== undefined) {
      yield put({
        type: FEATURE_NAMESPACE + SET_FEATURE_VALUE,
        payload: { feature, value },
      });
    }
  }
}

function* loadFiltersFromQuote({ payload }) {
  const { filters } = payload.data.content;
  const directAgreement = filters ? filters.directAgreement : false;

  yield put({
    type: OFFER_NAMESPACE + SET_REQUEST_FILTER,
    payload: {
      key: 'directAgreement',
      value: directAgreement || false,
      refresh: false,
    },
  });

  // Company blacklist
  const blacklistedCompanies = filters && filters.blacklistedCompanies !== undefined
    ? filters.blacklistedCompanies : [];
  const product = yield call(waitForProduct);

  yield put({
    type: COMPANY_NAMESPACE + SET_BLACKLIST,
    payload: { product, blacklistedCompanies },
  });
}

function* loadContractDataFromQuote({ payload }) {
  const { antrag } = payload.data.content;

  if (antrag === undefined) {
    return;
  }

  const product = yield call(waitForProduct);
  const defaults = products[product].props.defaults.contract();

  yield put({
    type: CONTRACT_NAMESPACE + SET_DATA,
    payload: merge(defaults, antrag),
  });
}

/**
 * Recreates the clients store from the data in the quote.
 *
 * @param payload
 * @return {IterableIterator<*>}
 */
export function* loadClientsFromQuote({ payload }) {
  const { kunden = {} } = payload.data.content;

  for (let i = 0; i < roles.length; i += 1) {
    const client = kunden[roles[i]];

    if (client !== undefined && client.id !== undefined) {
      let instance = getClientById(yield select((state) => state.client), client.id);

      if (instance === undefined) {
        instance = createClient(toState(client));
      }

      yield put({
        type: CLIENT_NAMESPACE + ADD_CLIENT_TO_ROLE,
        payload: { roles: [roles[i]], client: instance },
      });
    }
  }
}

/**
 * Restores the selection and recommendation
 * from a loaded quote.
 *
 * @param payload
 * @return {IterableIterator<*>}
 */
export function* loadOffersFromQuote({ payload }) {
  // Reset our current selection
  yield put({ type: OFFER_NAMESPACE + RESET_SELECTION });

  // Load our previous offer selection
  const { data } = payload;
  const { vergleichGroup: selection = [], empfehlung = {} } = data.content.vergleich || {};

  for (let i = 0; i < selection.length; i += 1) {
    yield put({
      type: OFFER_NAMESPACE + ADD_SELECTION,
      payload: selection[i],
    });
  }

  // Load the recommended offer, if any
  const { identifier = null } = empfehlung;

  if (identifier !== null) {
    yield put({
      type: OFFER_NAMESPACE + SET_RECOMMENDED,
      payload: { identifier, fromQuote: true },
    });
  }
}

function* resetQuoteAfterLoad({ payload }) {
  if (!resetAfterLoad.includes(payload.type)) {
    return;
  }

  yield put({
    type: NAMESPACE + RESET_QUOTE,
    payload: { product: payload.product },
  });
}

function* storeQuoteAfterContractCreation({ payload }) {
  // Since the user just gets a printable PDF, we don't know
  // if they actually sent the request to the company.
  if (payload.type === TYPE_PRINT) {
    return;
  }

  const { product, client } = yield select();

  try {
    yield put({ type: NAMESPACE + SAVE_BESTANDSVERTRAG });

    const { data } = yield call(api, 'post', `${product}/angebot/bestandsvertrag`, {
      clientId: client[ROLE_VERSICHERUNGSNEHMER].data.id,
      content: {
        ...yield call(buildContent),
        antrag: payload.requestData.antragsdaten,
      },
    });

    yield put({
      type: NAMESPACE + SAVE_BESTANDSVERTRAG_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    yield put({
      type: NAMESPACE + SAVE_BESTANDSVERTRAG_ERROR,
      payload: { error },
    });
  }
}

function* watchFeaturesFromQuote() {
  while (true) {
    const payload = find(
      yield all([
        take(NAMESPACE + GET_QUOTE_BY_ID_SUCCESS),
        take(FEATURE_NAMESPACE + GET_FEATURES_SUCCESS),
      ]),
      ['type', NAMESPACE + GET_QUOTE_BY_ID_SUCCESS],
    );

    yield call(loadFeaturesFromQuote, payload);
  }
}

function* showPriceChangeModal() {
  while (true) {
    const { payload } = yield take(OFFER_NAMESPACE + GET_OFFERS_BY_COMPANY_SUCCESS);

    const product = yield call(waitForProduct);
    const { data } = yield select((state) => state.quote[product]);

    // As long as the current quote has an offer for the loaded company
    const id = `${payload.id}`;
    const offerForCompany = find(data.offers, ({ gesellschaft }) => (gesellschaft.id === id));

    if (offerForCompany !== undefined) {
      // Show differences in a modal
      Vue.modal(modals.offer.priceChange);
    }
  }
}

export default function* root() {
  yield takeLatest(NAMESPACE + UPSERT_QUOTE, upsert);
  yield takeLatest(NAMESPACE + GET_QUOTE_BY_ID, load);
  yield takeEvery(NAMESPACE + GET_QUOTE_BY_ID_SUCCESS, loadContractDataFromQuote);
  yield takeEvery(NAMESPACE + GET_QUOTE_BY_ID_SUCCESS, loadClientsFromQuote);
  yield takeEvery(NAMESPACE + GET_QUOTE_BY_ID_SUCCESS, loadFiltersFromQuote);
  yield takeEvery(NAMESPACE + GET_QUOTE_BY_ID_SUCCESS, loadInputFromQuote);
  yield takeEvery(NAMESPACE + GET_QUOTE_BY_ID_SUCCESS, loadOffersFromQuote);
  yield takeEvery(NAMESPACE + GET_QUOTE_BY_ID_SUCCESS, resetQuoteAfterLoad);
  yield takeEvery(CONTRACT_NAMESPACE + GENERATE_CONTRACT_SUCCESS, storeQuoteAfterContractCreation);
  yield fork(watchFeaturesFromQuote);
  yield fork(showPriceChangeModal);
}
