export default {
  /**
   * Gets the quote state for the currently active product.
   *
   * @param state
   * @param getters
   * @param rootState
   * @return {*}
   */
  currentProductQuote(state, getters, rootState) {
    return state[rootState.product] || null;
  },
};
