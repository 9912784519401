import { MutationTree } from 'vuex';
import { WebreportsState } from '.';

export enum WebreportMutations {
  GET_VERGLEICHS_REPORT = 'getVergleichsReport',
  GET_VERGLEICHS_REPORT_SUCCESS = 'getVergleichsReportSuccess',
  GET_VERGLEICHS_REPORT_ERROR = 'getVergleichsReport_ERROR',
  LOADING_VERGLEICHS_DATA_DONE = 'loadingVergleichsDataDone',

  GET_UEBERSICHTS_REPORT = 'getUebersichtsReport',
  GET_UEBERSICHTS_REPORT_SUCCESS = 'getUebersichtsReportSuccess',
  GET_UEBERSICHTS_REPORT_ERROR = 'getUebersichtsReportError'
}

const mutations: MutationTree<WebreportsState> = {
  [WebreportMutations.GET_VERGLEICHS_REPORT]: (state) => {
    state.isLoading = true;
  },

  [WebreportMutations.GET_VERGLEICHS_REPORT_SUCCESS]: (state, { data }: any) => {
    state.data = data;
  },

  [WebreportMutations.GET_VERGLEICHS_REPORT_ERROR]: (state, error: any) => {
    state.isLoading = false;
    state.error = error;
  },

  [WebreportMutations.LOADING_VERGLEICHS_DATA_DONE]: (state) => {
    state.isLoading = false;
  },

  [WebreportMutations.GET_UEBERSICHTS_REPORT]: (state) => {
    state.isLoading = true;
  },

  [WebreportMutations.GET_UEBERSICHTS_REPORT_SUCCESS]: (state, { data }: any) => {
    state.data = data;
    state.isLoading = false;
  },

  [WebreportMutations.GET_UEBERSICHTS_REPORT_ERROR]: (state, error: any) => {
    state.isLoading = false;
    state.error = error;
  },
};

export default mutations;
