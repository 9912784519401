export default {
  id: 'id',
  salutation_id: 'salutation',
  firstname: 'firstName',
  lastname: 'lastName',
  title: 'title',
  birthday: 'birthDate',
  street: 'address.street',
  street_number: 'address.number',
  zip_code: 'address.zipCode',
  city: 'address.city',
  personal_status: 'maritalStatus',
  nationality: 'nationality',
  berufsgruppe: 'professionalStatus',
  jobname: 'profession',
  email: 'email',
  phone: 'phone',
  personalverantwortung: 'personnelResponsibilityCount',
  gender: 'gender',
};
