import { GetterTree } from 'vuex';
import orderBy from 'lodash/orderBy';
import filter from 'lodash/filter';
import map from 'lodash/map';
import { WebreportsState } from '.';
import { Offer } from '@/types';
import { Sortables } from '@/types/offers/sortables';
// @ts-ignore
import sortables from '@/store/modules/offers/sortables';

const getters: GetterTree<WebreportsState, {[k: string]: any}> = {
  offers(state, _, rootState) {
    if (state.data.tarife.length === 0) {
      return [];
    }

    const searchValues = rootState.offers.searchStrings;

    const ordered: Offer[] = orderBy(
      state.data.tarife,
      [
        'erfuellungsgrad.isLoading',
        'risikoDemver',
        ...map(rootState.offers.sorting, ({ key }) => {
          const sort = sortables[key as keyof Sortables];
          return sort.handler || sort.key;
        }),
      ],
      [
        'asc',
        'desc',
        ...map(rootState.offers.sorting, 'order'),
      ],
    );

    if (searchValues.length === 0) {
      return ordered;
    }

    return filter(ordered, (tarif) => {
      const searchableAttrs = [
        tarif.gesellschaft.name,
        tarif.name,
      ];

      if (tarif.beschreibung) {
        searchableAttrs.push(tarif.beschreibung);
      }

      return searchableAttrs.some(
        (attr) => searchValues.some(
          (searchValue: string) => attr.toLowerCase().indexOf(searchValue) > -1,
        ),
      );
    });
  },
};

export default getters;
