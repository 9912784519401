import analytics from '@/utils/analytics';

import events, { EVENT_ADD, EVENT_CLEAR } from './events';

import Modal from './Modal';
import Stack from './Stack.vue';

const trackEvent = (vm, type) => {
  // eslint-disable-next-line no-underscore-dangle
  analytics(['trackEvent', 'Modal', type, vm.$options.name || vm.$options.__file]);
};

export default function install(Vue) {
  Vue.component('Modal', Modal);
  Vue.component('ModalStack', Stack);

  Object.defineProperty(Vue.prototype, '$modal', {
    get: () => (component, props = {}, isAsync = false) => new Promise((resolve, reject) => {
      // If we pass no parameters, we just remove all modals
      if (!component) {
        events.$emit(EVENT_CLEAR);
        resolve();
        return;
      }

      let isAlive = false;

      events.$emit(EVENT_ADD, {
        component,
        props,

        // Resolve the promise when the modal has a value to update
        shown({ vueTarget }) {
          isAlive = true;

          trackEvent(vueTarget.$parent, 'Open');

          if (!isAsync) {
            return;
          }

          // The target is the actual modal, we need the component using it
          vueTarget.$parent.$once('input', (e) => {
            trackEvent(vueTarget.$parent, 'Save');

            resolve(e);
            isAlive = false;
          });
        },

        // Do not leave any unresolved promises behind
        // Only reject, if we haven't been closed before
        hidden({ vueTarget }) {
          if (!isAlive) {
            return;
          }

          trackEvent(vueTarget.$parent, 'Close');

          if (isAsync) {
            reject();
          }
        },
      });
    }),
  });

  // eslint-disable-next-line no-param-reassign
  Vue.modal = Vue.prototype.$modal;
}
