import createPerProductState from '@/utils/store/createPerProductState';

import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import sagas from './sagas';

import offers from './offers';

export const NAMESPACE = 'features/';

export default {
  namespaced: true,

  actions,
  mutations,
  getters,
  sagas,

  modules: {
    offers,
  },

  state: createPerProductState(() => ({
    isLoading: false,
    error: null,
    data: [],
    collapsed: [],
  })),
};
