import each from 'lodash/each';
import { ClientAddress, ClientApiResource, ClientBankInfo } from '@/types/store/modules/client';

// This needs to be removed in the future as it is just a mapper from the new
// client mapping to the old one, which the backend still expects from
// the frontend

export const MappingMap = {
  id: 'id',
  anredeId: 'salutation_id',
  vorname: 'firstname',
  nachname: 'lastname',
  titel: 'title',
  familienstand: 'personal_status',
  geschlecht: 'gender',
  geburtsdatum: 'birthday',
  nationalitaet: 'nationality',
  geburtsname: 'birthname',
  geburtsort: 'placeOfBirth',
  geburtsland: 'countryOfBirth',
  beruf: 'jobname',
  anteilTaetigkeitBuero: 'commercial_work_percentage',
  berufsgruppe: 'berufsgruppe',
  nichtraucher: 'nonSmoker',
  email: 'email',
  telefon: 'phone',
  mobil: 'cellphone',
  fax: 'fax',
  personalverantwortung: 'personalverantwortung',
  gewicht: 'weight',
  groesse: 'height',
  hoechsterBildungsabschluss: 'education',
  anzahlKinder: 'anzahlKinder',

  kundennummer: 'kundennummer', // new field
  // only direct mappings for address
  address: {
    strasse: 'street',
    hausnummer: 'street_number',
    plz: 'zip_code',
    ort: 'city',
  },
  // only direct mappings for bank info
  bank: {
    iban: 'iban_code',
    bic: 'swift_code',
    bankname: 'bank_name',
  },
};

type subMapKeys = 'bank' | 'address';
type clientApiResourceKeys = keyof ClientApiResource;

const mapResourceArray = (
  mappingKey: subMapKeys,
  resourceObj: ClientAddress | ClientBankInfo,
) => {
  const mappedClient: { [k: string]: unknown } = {};

  const subMapping = MappingMap[mappingKey];
  type subMappingKeys = keyof typeof subMapping

  Object.keys(subMapping).forEach((key) => {
    mappedClient[<clientApiResourceKeys>subMapping[
      <subMappingKeys>key
    ]] = resourceObj[<subMappingKeys>key];
  });
  return mappedClient;
};

export const transformToOldMapping = (client: ClientApiResource) => {
  let mappedClient: {[k: string]: unknown} = {};

  each(MappingMap, (value, key) => {
    if (['bank', 'address'].includes(key)) {
      const objectKey = key === 'bank' ? 'bankverbindung' : 'adresse';
      mappedClient = {
        ...mappedClient,
        ...mapResourceArray(<subMapKeys>key, client[objectKey]),
      };
    } else {
      mappedClient[<string>value] = client[<clientApiResourceKeys>key];
    }
  });

  return mappedClient;
};
