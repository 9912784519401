import { Step, ExtraStep } from '@/router/steps';

// @ts-ignore
import store from '@/store';

/**
 * Checks if the given named route is currently active
 * for the current product (e.g. "offers" maps to "phv.offers").
 *
 * @param name
 */
export const isCurrentRouteName = (name: Step): boolean => {
  const [, currentName] = store.state.route.name.split('.');
  return name === currentName;
};

/**
 * Prefixes the internal route name with the current product identifier.
 *
 * @param name
 */
export default (name: Step | ExtraStep): string => `${store.state.product}.${name}`;
