import Vue from 'vue';
import Router, { RouteConfig } from 'vue-router';
import { sync } from 'vuex-router-sync';
import map from 'lodash/map';
import reduce from 'lodash/reduce';

import modules from '@/products';

// @ts-ignore
import store from '@/store';
import Error404 from '@/views/errors/404.vue';

import guards from './guards';
import parseProduct from './products';
import webreports from './webreports';

Vue.use(Router);

const errorRoute = {
  path: '*',
  component: Error404,
};

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...map(modules, (module, name) => ({
      ...module,
      beforeEnter: guards.beforeModule(module),
      children: [
        {
          path: '/',
          redirect: module.redirect || `${module.path}/vorgaben`,
        },
        ...parseProduct(module, name),
        ...(module.children || []),
        errorRoute,
      ],
    })),

    {
      path: '/login',
      name: 'login',
    },

    ...reduce(modules,
      (acc: RouteConfig[], module, name: string) => ([
        ...acc,
        ...webreports(module, name.toLowerCase()),
      ]),
      []),

    errorRoute,
  ],
});

router.afterEach(guards.afterEach);

sync(store, router);

export default router;
