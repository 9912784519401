import reduce from 'lodash/reduce';
import api from '@/store/api';

import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';

import { NAMESPACE as OFFER_NAMESPACE } from '../index';
import { GET_OFFERS_BY_COMPANY_SUCCESS } from '../actions/offersByCompany';

import { NAMESPACE } from './index';
import {
  GET_QUESTIONS,
  GET_QUESTIONS_ERROR,
  GET_QUESTIONS_SUCCESS,
  FILL_QUESTIONS,
} from './mutations';

import { SET_ANSWER_KEYS } from '@/store/modules/offers/mutations';

/**
 * Loads questions for the given insurance ID in the given product category.
 *
 * @param payload
 * @return {IterableIterator<*>}
 */
function* load({ payload }) {
  const { product, identifier } = payload;
  try {
    const { data } = yield call(api, 'get', `${product}/tarife/${identifier}/fragen`);
    yield put({
      type: NAMESPACE + GET_QUESTIONS_SUCCESS,
      payload: { product, identifier, data },
    });
  } catch (error) {
    yield put({
      type: NAMESPACE + GET_QUESTIONS_ERROR,
      payload: { product, identifier, error },
    });
  }
}

function* resetQuestions({ payload }) {
  yield put({
    type: NAMESPACE + FILL_QUESTIONS,
    payload: {
      product: payload.product,
      offers: payload.data.tarife,
    },
  });
}

function* addMergedAnswers({ payload }) {
  const mergeAnswers = (base, obj) => reduce(obj, (acc, question) => {
    if (question.name !== undefined) {
      acc[question.name] = question.default !== undefined ? question.default : null;
    }

    if (question.items !== undefined) {
      return mergeAnswers(acc, question.items);
    }

    return acc;
  }, base);

  const answers = mergeAnswers({}, payload.data);
  yield put({
    type: OFFER_NAMESPACE + SET_ANSWER_KEYS,
    payload: {
      product: payload.product,
      answerKeys: answers,
    },
  });
}

export default function* root() {
  yield takeEvery(NAMESPACE + GET_QUESTIONS, load);
  yield takeEvery(OFFER_NAMESPACE + GET_OFFERS_BY_COMPANY_SUCCESS, resetQuestions);
  yield takeEvery(NAMESPACE + GET_QUESTIONS_SUCCESS, addMergedAnswers);
}
