import each from 'lodash/each';
import filter from 'lodash/filter';
import find from 'lodash/find';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import keys from 'lodash/keys';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import reduce from 'lodash/reduce';
import values from 'lodash/values';

import translateSymfonyErrors from '@/utils/api/translateSymfonyErrors';

import sortables from './sortables';

export default {
  /**
   * Indicates a percentage of our currently loading requests.
   *
   * @param data
   * @return {number}
   */
  loadingPercentage({ chunks }) {
    let total = 0;
    let loaded = 0.0;

    each(chunks, (chunk) => {
      total += 1;

      if (chunk.isLoading) {
        loaded += 0.031415; // always show a bit of progress
        return;
      }

      if (chunk.data === null || chunk.error !== null) {
        // In case we got an error, count them as loaded
        loaded += 1;
        return;
      }

      const { tarife } = chunk.data;

      if (tarife.length === 0) {
        // Chunk is fully loaded
        loaded += 1;
        return;
      }

      const remainder = filter(tarife, ({ erfuellungsgrad }) => !erfuellungsgrad.isLoading);
      loaded += remainder.length / tarife.length;
    });

    return Math.max(0, Math.min(1, loaded / total));
  },

  /**
   * Indicates if we've loaded all offers and their additional data.
   *
   * @param _
   * @param loadingPercentage
   * @return {boolean}
   */
  hasLoaded(_, { loadingPercentage }) {
    return loadingPercentage === 1;
  },

  isLoadingCompanyIds({ chunks }) {
    return filter(keys(chunks), (id) => chunks[id].isLoading);
  },

  activeCompanyIds({ filters }, _, { companies, product }) {
    if (!product) {
      return [];
    }

    return reduce(companies[product].data, (acc, company) => {
      if (filters.directAgreement && !company.isDirektvereinbarung) {
        return acc;
      }

      acc.push(`${company.insuranceId}`); // TODO let this be a number instead
      return acc;
    }, []);
  },

  offers({ chunks }) {
    const offers = {};

    each(chunks, (chunk) => {
      if (chunk.isLoading || chunk.data.tarife === undefined) {
        return;
      }

      each(chunk.data.tarife, (offer) => {
        // Exclude invalid offers
        if (offer.erfuellungsgrad.isLoading === false && offer.erfuellungsgrad.wert === null) {
          return;
        }

        offers[offer.identifier] = offer;
      });
    });

    return values(offers);
  },

  validationErrors({ chunks }) {
    return reduce(chunks, (acc, chunk) => {
      if (chunk.isLoading) {
        return acc;
      }

      const validation = get(chunk.error, 'data.data.validation', {});

      return {
        ...translateSymfonyErrors(validation),
        ...acc,
      };
    }, {});
  },

  tariffehler({ chunks }) {
    const offers = {};

    each(chunks, (chunk) => {
      if (chunk.isLoading || chunk.data.tariffehler === undefined) {
        return;
      }

      each(chunk.data.tariffehler, (offer) => {
        offers[offer.tarifId] = offer;
      });
    });

    return values(offers);
  },

  requested({ filters }, { offers, activeCompanyIds }) {
    if (filters.directAgreement !== true) {
      return offers;
    }

    return filter(offers, (offer) => activeCompanyIds.includes(offer.gesellschaft.id));
  },

  /**
   * Todo: rename in vgl-next
   *
   * Returns a list of offers ordered by their fulfillment degree.
   * This also puts the loaded entries before the not yet loaded ones.
   *
   * @param data
   * @return {Array}
   */
  ordered({ sorting }, { requested }) {
    return orderBy(
      requested,
      [
        'erfuellungsgrad.isLoading',
        'risikoDemver',
        ...map(sorting, ({ key }) => {
          const sort = sortables[key];
          return sort.handler || sort.key;
        }),
      ],
      [
        'asc',
        'desc',
        ...map(sorting, 'order'),
      ],
    );
  },

  searchStrings({ searchStrings }) {
    return filter(
      map(searchStrings, (filterItem) => filterItem.toLowerCase().trim()),
      (item) => item.length > 0,
    );
  },

  hasSearchStrings(state, getters) {
    return getters.searchStrings.length > 0;
  },

  filtered(state, getters) {
    const matches = [];
    const mismatches = [];

    if (!getters.hasSearchStrings) {
      return {
        matches: getters.ordered,
        mismatches,
      };
    }

    const filtered = filter(getters.ordered, ({ id }) => (
      getters.activeCompanyIds.indexOf(id)
    ));

    each(filtered, (tarif) => {
      const searchAttrs = [
        tarif.gesellschaft.name,
        tarif.name,
      ];

      if (tarif.beschreibung !== undefined) {
        searchAttrs.push(tarif.beschreibung);
      }

      const hasMatch = searchAttrs.some((attr) => {
        const lowerCase = attr.toLowerCase();
        return getters.searchStrings.some((filterItem) => lowerCase.indexOf(filterItem) > -1);
      });

      if (hasMatch) {
        matches.push(tarif);
      } else {
        mismatches.push(tarif);
      }
    });

    return {
      matches,
      mismatches,
    };
  },

  offersByIdentifier(_, { offers }) {
    return keyBy(offers, 'identifier');
  },

  /**
   * Returns an array of the offer instances that have been selected.
   *
   * This way we can retain the offers across searches to keep the
   * selection even if the result list changes.
   *
   * @param selected
   * @return {Array}
   */
  selection({ selected }, { offersByIdentifier, vorversicherungsOffer }) {
    const mergedOffers = { ...offersByIdentifier, ...vorversicherungsOffer };

    return filter(map(selected, (identifier) => mergedOffers[identifier]));
  },

  recommendation(_, { offers, isRecommended }) {
    return find(offers, isRecommended);
  },

  isRecommended({ recommended }) {
    return ({ identifier }) => recommended === identifier;
  },

  isSelected({ selected }) {
    return ({ identifier }) => selected.indexOf(identifier) >= 0;
  },

  hasSelection({ selected }) {
    return selected.length > 0;
  },

  offerByIdentifier: (_, { offersByIdentifier }) => (identifier) => offersByIdentifier[identifier],

  vorversicherungsOffer(state) {
    const vorversicherung = state.vorversicherung?.data;

    return vorversicherung.length > 0 ? keyBy(vorversicherung, 'identifier') : undefined;
  },

  selectionHasRisikoDEMVer({ selected }, { offersByIdentifier }) {
    let containsRisikoDEMVer = false;

    for (let i = 0; i < selected.length; i += 1) {
      const offer = offersByIdentifier[selected[i]];

      if (offer?.risikoDemver) {
        containsRisikoDEMVer = true;
        break;
      }
    }

    return containsRisikoDEMVer;
  },
};
