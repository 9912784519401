import { Role } from '@/store/modules/client/roles';
import { ProductConfig } from '@/types';
// @ts-ignore
import image from './assets/bg.jpg';

const config: ProductConfig = {
  path: '/hun',
  component: () => import('@/views/layout/BaseLayout.vue'),

  // Config
  props: {
    name: 'Hundehalterhaftpflicht',
    product: 'hun',
    icon: 'home',
    image,

    // distribution settings
    shouldChannel: true,
    shouldPool: true,

    // how many request to send to backend for offers
    numChunks: 3,

    // which client data need to be send to the backend
    clientData: {
      [Role.VersichertePerson]: {
        'clientDaten.versichertePerson.geburtsdatum': 'birthDate',
      },
      [Role.Versicherungsnehmer]: {
        'clientDaten.versicherungsnehmer.geburtsdatum': 'birthDate',
      },
    },

    // role settings
    productSyncRoleSettings: {
      [Role.Versicherungsnehmer]: [Role.VersichertePerson, Role.Zahlperson],
      [Role.VersichertePerson]: [Role.Versicherungsnehmer, Role.Zahlperson],
    },

    profileFields: [
      'eingabedaten.deckungssumme',
      'eingabedaten.selbstbeteiligung',
    ],

    keepInputFromQuotes: [
      'birthDate',
    ],

    defaults: {
      contract: () => ({
        antragAbgelehnt: false,
        zahlart: null,
        emailBestaetigungKunde: false,
        memofeld: '',
        vorversicherungsDaten: {
          gesellschaft: null,
          versicherungsnummer: '',
          kuendigender: null,
          kuendigen: false,
          gekuendigt: false,
          beginn: '',
          ablauf: '',
        },
        vorschaden: {
          bestehenAnsprueche: false,
          memofeldAnsprueche: '',
          entschaedigungszahlung: null,
        },
        hunde: Array.from(Array(5), () => ({
          name: '',
          alter: null,
          farbe: '',
          geschlecht: null,
        })),
      }),
    },

    offerDetail: () => import('./views/offerdetail.vue'),
  },

  // Wizard steps
  steps: {
    input: {
      component: () => import('./views/input.vue'),
    },
    offers: {
      component: () => import('./views/offers.vue'),
    },
    comparison: {
      component: () => import('./views/comparison.vue'),
      meta: {
        showContractButton: false,
      },
    },
    contract: {
      component: () => import('./views/contract.vue'),
    },
    completion: {
      component: () => import('@/views/pages/completion/type/Property.vue'),
    },
  },
};

export default config;
