import isEmpty from 'lodash/isEmpty';
// @ts-ignore
import store from '@/store';
// @ts-ignore
import { NAMESPACE as OFFER_NAMESPACE } from '@/store/modules/offers';
// @ts-ignore
import { NAMESPACE as COMPANY_NAMESPACE } from '@/store/modules/companies';

export const offersMatchInput = () => (
  store.state.offers.lastRequestHash === store.getters.requestHash
);

export const hasDisplayableOfferRequest = () => !isEmpty(store.state.offers.chunks);

export const isOfferSelected = () => (
  store.getters[`${OFFER_NAMESPACE}selection`].length > 0
);

export const companySelectionChanged = () => {
  const state = store.state.companies[store.state.product];
  return store.getters[`${COMPANY_NAMESPACE}selectionHash`] !== state.lastRequestHash;
};
