import { GET_FEATURES } from './mutations';

export default {
  /**
   * Fetches the features for the given offer only
   * if they not have been loaded already.
   *
   * @param {{ commit, getters }} module
   * @param {string} key
   */
  fetch({ commit, getters }, key) {
    if (getters.contains(key)) {
      return;
    }

    commit(GET_FEATURES, key);
  },
};
