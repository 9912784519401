import mutations from './mutations';
import getters from './getters';
import sagas from './sagas';

export const NAMESPACE = 'auth/';

export default {
  namespaced: true,

  mutations,
  getters,
  sagas,

  state: {
    permissions: {
      isLoading: false,
      error: null,
      data: {},
    },
    user: {
      isLoading: false,
      error: null,
      data: {},
    },
  },
};
