import {
  put,
  select,
  takeEvery,
} from 'redux-saga/effects';

import { Step } from '@/router/steps';
import routeName from '@/router/utils/routeName';

import { pushRoute } from '@/store/modules/global/actions/pushRoute';

// @ts-ignore
import { NAMESPACE } from '../index';

import {
  GET_OFFERS_BY_COMPANY_ERROR,
  GetOffersByCompanyErrorAction,
} from '../actions/offersByCompany';

function* backToInputOnClientErrors(
  error: GetOffersByCompanyErrorAction,
): IterableIterator<any> {
  if (error.payload.error === undefined || error.payload.error.status !== 400) {
    return;
  }

  const state = yield select();

  // Fixes duplicate route pushing
  if ((state as unknown as Record<string, any>).route.name === routeName(Step.Input)) {
    return;
  }

  yield put(pushRoute({
    step: Step.Input,
  }));
}

export default function* root() {
  yield takeEvery(NAMESPACE + GET_OFFERS_BY_COMPANY_ERROR, backToInputOnClientErrors);
}
