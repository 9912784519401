import api from '@/store/api';
import {
  call, put, select, takeLatest,
} from 'redux-saga/effects';
// @ts-ignore
import { GET_PRODUCT_STATUS_SUCCESS } from '@/store/modules/global/mutations';
// @ts-ignore
import { NAMESPACE as AUTH_NAMESPACE } from '@/store/modules/auth';
// @ts-ignore
import { SET_USER_SUCCESS } from '@/store/modules/auth/mutations';

/**
 * Load the product status for the given product.
 */
export function* load(): IterableIterator<any> {
  const product = yield select((state) => state.product);

  try {
    // @ts-ignore
    const { data } = yield call(api, 'get', `${product}/productstatus`);
    yield put({
      type: GET_PRODUCT_STATUS_SUCCESS,
      payload: { product, data },
    });
  } catch (error) {
    // Ignore any errors
  }
}

export default function* root() {
  yield takeLatest((AUTH_NAMESPACE + SET_USER_SUCCESS) as any, load);
}
