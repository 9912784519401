import endsWith from 'lodash/endsWith';

import {
  call,
  fork,
  put,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';

import api from '@/store/api';
import waitForProduct from '@/store/effects/waitForProduct';
import { NAMESPACE } from '.';
import { GET_FILES, GET_FILES_ERROR, GET_FILES_SUCCESS } from './mutations';

/**
 * Grabs the list of general files for the given product and collection.
 *
 * @param payload
 * @return {IterableIterator<*>}
 */
function* getFiles({ payload }) {
  const { product } = payload;
  const offers = yield select((state) => state.offers);

  try {
    const { data } = yield call(api, 'get', `${product}/files/${offers.remoteHash}/tariff/${offers.recommended}`);

    yield put({
      type: NAMESPACE + GET_FILES_SUCCESS,
      payload: { product, data },
    });
  } catch (error) {
    yield put({
      type: NAMESPACE + GET_FILES_ERROR,
      payload: { product, error },
    });
  }
}

function* loadFilesOnContractPage() {
  while (true) {
    const { payload } = yield take('route/ROUTE_CHANGED');

    if (endsWith(payload.to.name, 'contract')) {
      const product = yield call(waitForProduct);

      yield put({
        type: NAMESPACE + GET_FILES,
        payload: { product },
      });
    }
  }
}

export default function* root() {
  yield fork(loadFilesOnContractPage);
  yield takeLatest(NAMESPACE + GET_FILES, getFiles);
}
