export default {
  anzahlKinder: 'numberOfChildren',
  salutation_id: 'salutation',
  firstname: 'firstName',
  lastname: 'lastName',
  title: 'title',
  birthday: 'birthDate',
  street: 'address.street',
  street_number: 'address.number',
  zip_code: 'address.zipCode',
  city: 'address.city',
  country: 'address.country',
  personal_status: 'maritalStatus',
  nationality: 'nationality',
  email: 'email',
  phone: 'phone',
  cellphone: 'cellphone',
  placeOfBirth: 'placeOfBirth',
  countryOfBirth: 'countryOfBirth',
  birthname: 'birthName',
  bank_name: 'bank.bankName',
  iban_code: 'bank.ibanCode',
  swift_code: 'bank.swiftCode',
  jobname: 'profession',
  berufsgruppe: 'professionalStatus',
  commercial_work_percentage: 'commercialWorkPercentage',
  id: 'id',
  nonSmoker: 'nonSmoker',
  personalverantwortung: 'personnelResponsibilityCount',
  education: 'education',
  weight: 'weight',
  height: 'height',
  gender: 'gender',
};
