import { Role } from '@/store/modules/client/roles';
import { ProductConfig } from '@/types';
// @ts-ignore
import image from './assets/bg.jpg';

const config: ProductConfig = {
  path: '/bu',
  component: () => import('@/views/layout/BaseLayout.vue'),

  // Config
  props: {
    name: 'Berufsunfähigkeitsversicherung',
    product: 'bu',
    icon: 'home',
    image,

    // distribution settings
    shouldChannel: false,
    shouldPool: false,

    // how many request to send to backend for offers
    numChunks: 3,

    // which client data need to be send to the backend
    clientData: {
      [Role.VersichertePerson]: {
        'clientDaten.versichertePerson.geburtsdatum': 'birthDate',
        'eingabedaten.anteilTaetigkeitBuero': 'commercialWorkPercentage',
        'eingabedaten.anzahlKinder': 'numberOfChildren',
        'eingabedaten.gewicht': 'weight',
        'eingabedaten.groesse': 'height',
        'eingabedaten.berufsgruppe': 'professionalStatus',
        'eingabedaten.bildungsabschluss': 'education',
        'eingabedaten.personal_status': 'maritalStatus',
        'eingabedaten.personalverantwortungFuerAnzahlMitarbeiter': 'personnelResponsibilityCount',
        'eingabedaten.nichtraucher': 'nonSmoker',
      },
      [Role.Versicherungsnehmer]: {
        'clientDaten.versicherungsnehmer.geburtsdatum': 'birthDate',
      },
    },

    productSyncRoleSettings: {
      [Role.Versicherungsnehmer]: [Role.Zahlperson],
    },

    profileFields: [
      'eingabedaten.zahlweise',
      'eingabedaten.beitragsdynamik',
      'eingabedaten.beitragsendalter',
      'eingabedaten.niedrigerAnfangsbeitrag',
      'eingabedaten.rentensteigerung',
      'eingabedaten.ueberschusssystem',
      'eingabedaten.versicherungsendalter',
    ],

    keepInputFromQuotes: [
      'education',
      'commercialWorkPercentage',
      'birthDate',
      'professionalStatus',
      'nonSmoker',
      'numberOfChildren',
      'personnelResponsibilityCount',
      'maritalStatus',
      'profession',
    ],

    resetClientFieldsOnLoad: {
      'eingabedaten.berufComplex': [],
    },

    offerDetail: () => import('./views/offerdetail.vue'),

    useFirstOfNextMonth: true,
  },

  // Wizard steps
  steps: {
    input: {
      component: () => import('./views/input.vue'),
    },
    offers: {
      component: () => import('./views/offers.vue'),
    },
    comparison: {
      component: () => import('./views/comparison.vue'),
    },
    contract: {
      component: () => import('@/views/pages/contract/type-provident/index.vue'),
    },
    completion: {
      component: () => import('./views/completion.vue'),
    },
  },
};

export default config;
