import reduce from 'lodash/reduce';

import roles from './roles';

import actions from './actions';
import getters from './getters';
import mutations, { createClient } from './mutations';
import sagas from './sagas';

export const NAMESPACE = 'client/';

export default {
  namespaced: true,

  actions,
  getters,
  mutations,
  sagas,

  // Create a slot per required role
  state: reduce(roles, (acc, role) => ({ ...acc, [role]: createClient() }), {}),
};
