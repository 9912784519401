// @ts-ignore
import store from '@/store';
// @ts-ignore
import { NAMESPACE } from '@/store/modules/offers';
import { isOfferSelected, offersMatchInput } from '@/router/utils/stepValidators';
import { StepConfig } from '../types';

const stepConfig: StepConfig = {
  path: 'vergleich/:id?',

  props: {
    icon: 'exchange-alt',

    label() {
      return store.state.offers.selected.length === 1
        ? 'Leistungen'
        : 'Vergleich';
    },

    isValid() {
      return isOfferSelected() && offersMatchInput();
    },
  },

  /**
   * Automatically selected the proper offers as requested.
   *
   * @param to
   * @param from
   * @param next
   */
  beforeEnter: (to, from, next) => {
    if (to.params.id) {
      // Overwrite the selection with the route parameter
      const offer = store.getters[`${NAMESPACE}offerByIdentifier`](to.params.id);
      store.dispatch(`${NAMESPACE}uniqueSelection`, offer);
    }

    next();
  },
};

export default stepConfig;
