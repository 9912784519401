import api from '@/store/api';
import difference from 'lodash/difference';
import keys from 'lodash/keys';
import mapAssoc from '@/utils/mapAssoc';

import {
  call,
  delay,
  put,
  select,
  spawn,
  take,
  takeLatest,
} from 'redux-saga/effects';

import { NAMESPACE as AUTH_NAMESPACE } from '@/store/modules/auth';
import { SET_USER_SUCCESS } from '@/store/modules/auth/mutations';

import { NAMESPACE } from './index';
import { GET_CHOICES, GET_CHOICES_SUCCESS, GET_CHOICES_ERROR } from './mutations';

let queue = [];

/**
 * Fetches the choices for the given choice models.
 *
 * @param {String[]} names
 * @return {IterableIterator<*>}
 */
export function* fetchQueue(names = []) {
  try {
    const product = yield select((state) => state.product);
    const { data } = yield call(api, 'get', `/${product}/choices`, {
      params: {
        name: names,
      },
    });

    // Extract the values array from the response
    const byName = mapAssoc(data.choices, 'name');

    for (let i = 0; i < names.length; i += 1) {
      const key = names[i];
      const entry = byName[key];

      if (entry !== undefined) {
        yield put({
          type: NAMESPACE + GET_CHOICES_SUCCESS,
          payload: {
            key,
            data: entry,
          },
        });
      }
    }

    // Detect which keys have not been returned and mark them as errored
    const notLoadedKeys = difference(names, keys(byName));

    for (let i = 0; i < notLoadedKeys.length; i += 1) {
      yield put({
        type: NAMESPACE + GET_CHOICES_ERROR,
        payload: {
          key: notLoadedKeys[i],
          error: 'Unknown name for choice',
        },
      });
    }
  } catch (error) {
    for (let i = 0; i < names.length; i += 1) {
      yield put({
        type: NAMESPACE + GET_CHOICES_ERROR,
        payload: {
          key: names[i],
          error,
        },
      });
    }
  }
}

export function* preloadChoices() {
  yield take(AUTH_NAMESPACE + SET_USER_SUCCESS); // Wait for a valid product before loading
  yield put({ type: NAMESPACE + GET_CHOICES, payload: 'salutation_id' });
}

/**
 * Builds up a queue that will eventually be resolved
 * as soon as any further load requests have stopped.
 *
 * @param key
 * @return {IterableIterator<*>}
 */
export function* buildQueue({ payload: key }) {
  if (queue.indexOf(key) < 0) {
    queue.push(key);
  }

  // Wait for additional requests within a limited time frame
  yield delay(250);

  // Afterwards, send it off and clear it for the next run.
  // We use spawn instead of fork to not cancel the request in case we get
  // a new load request while we're already loading some data.
  yield spawn(fetchQueue, queue);

  queue = [];
}

export default function* root() {
  yield takeLatest(NAMESPACE + GET_CHOICES, buildQueue);
  yield spawn(preloadChoices);
}
