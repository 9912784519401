import { GET_CHOICES } from './mutations';

export default {
  /**
   * Requests the choices for the given model
   * in case they have not been loaded yet.
   *
   * @param key The identifier for the choices list.
   */
  fetch({ commit, getters }, key) {
    if (key === undefined) {
      throw new Error('Undefined choice key given');
    }

    if (getters.contains(key)) {
      return;
    }

    commit(GET_CHOICES, key);
  },
};
