import get from 'lodash/get';
import keys from 'lodash/keys';
import merge from 'lodash/merge';
import reduce from 'lodash/reduce';
import set from 'lodash/set';

import fields from './fields';
import {
  ROLE_MTVERSICHERTER_PARTNER,
  ROLE_VERSICHERTE_PERSON,
  ROLE_VERSICHERUNGSNEHMER,
  ROLE_ZAHLPERSON,
} from './roles';

import mappingFull from './mappings/full';
import mappingAngebotskunde from './mappings/angebotskunde';
import mappingBankverbindungskunde from './mappings/bankverbindung';
import mappingVersichertePerson from './mappings/versichertePerson';
import mappingVersicherungsnehmer from './mappings/versicherungsnehmer';
import mappingMitversicherterPartner from './mappings/mitversicherterPartner';
import mappingKundenadresse from './mappings/kundenadresse';

export const FORM_MAP_FULL = 'formMapFull';
export const FORM_MAP_ANGEBOT = 'formMapAngebot';
export const FORM_MAP_KUNDENADRESSE = 'formMapKundenAdresse';

const formMap = {
  // Special cases
  [FORM_MAP_FULL]: mappingFull,
  [FORM_MAP_ANGEBOT]: mappingAngebotskunde,
  [FORM_MAP_KUNDENADRESSE]: mappingKundenadresse,

  // Roles
  [ROLE_MTVERSICHERTER_PARTNER]: mappingMitversicherterPartner,
  [ROLE_VERSICHERTE_PERSON]: mappingVersichertePerson,
  [ROLE_VERSICHERUNGSNEHMER]: mappingVersicherungsnehmer,
  [ROLE_ZAHLPERSON]: mappingBankverbindungskunde,
};

/**
 * A list of all available form mappings.
 * @type {Array}
 */
export const forms = keys(formMap);

/**
 * Get's map for the map name. This map can be used convert
 * backend responses to state and vice versa
 *
 * @param {string} name
 */
export const getMapByName = (name) => formMap[name];

/**
 * Converts the given state representation to an object that can be
 * sent to the backend (since our keys and structure differ).
 *
 * @param {Object} state
 * @param {string} name
 * @param {Object} context
 * @returns {Object}
 */
export const toForm = (state, name, context = {}) => reduce(
  getMapByName(name),
  (ctx, local, foreign) => {
    ctx[foreign] = get(state, local);
    return ctx;
  },
  context,
);

/**
 * Converts the given form to a local state representation.
 *
 * @param {Object} form
 * @returns {Object}
 */
export const toState = (form) => {
  const map = getMapByName(FORM_MAP_FULL);
  return reduce(
    form,
    (state, value, foreign) => {
      const path = map[foreign];

      if (value !== undefined && value !== null) {
        set(state, path, value);
      }

      return state;
    },
    merge({}, fields),
  );
};
