import { Merkmal } from '@/types/feature/types';
import filter from 'lodash/filter';

interface StandardRubriken {
  rubrik1: string[];
  rubrik2: string[];
  rubrik3: string[];
  rubrik4: string[];
  rubrik5: string[];
  rubrik6: string[];
  rubrik7: string[];
  rubrik8: string[];
}

export default function filterCategories(
  features: Merkmal[],
  leistungsumfang: any,
  isTarifgruppeFamilie: any,
  hasMehrereFahrzeuge: any,
) {
  const combiLeistungsumfangMietenPrivatSelected = leistungsumfang.privat && leistungsumfang.mieten;

  const standardRubriken: StandardRubriken = {
    rubrik1: [leistungsumfang.privat, leistungsumfang.beruf],
    rubrik2: [leistungsumfang.privat, leistungsumfang.beruf],
    rubrik3: [leistungsumfang.privat, leistungsumfang.beruf],
    rubrik4: [leistungsumfang.privat, leistungsumfang.beruf],
    rubrik5: [leistungsumfang.privat, leistungsumfang.beruf],
    rubrik6: [leistungsumfang.verkehr],
    rubrik7: [combiLeistungsumfangMietenPrivatSelected],
    rubrik8: [leistungsumfang.privat, leistungsumfang.beruf],
  };

  const merkmaleForTarifgruppeSingle = ['rubrik9', 'rubrik10', 'rubrik11', 'rubrik12', 'rubrik13'];
  const merkmaleForTarifgruppeFamilie = ['rubrik14', 'rubrik15', 'rubrik16', 'rubrik17', 'rubrik18'];

  const combiLeistungsumfangVerkehrSelected = !leistungsumfang.privat
    && !leistungsumfang.beruf
    && !leistungsumfang.mieten
    && !leistungsumfang.vermietung
    && leistungsumfang.verkehr;

  const combiLeistungsumfangMietenOrVermietungSelected = !leistungsumfang.privat
    && !leistungsumfang.beruf
    && !leistungsumfang.verkehr
    && (leistungsumfang.mieten || leistungsumfang.vermietung);

  const combiLeistungsumfangVerkehrMietenOrVermietungSelected = !leistungsumfang.privat
    && !leistungsumfang.beruf
    && leistungsumfang.verkehr
    && (leistungsumfang.mieten || leistungsumfang.vermietung);

  if (combiLeistungsumfangVerkehrSelected
    || combiLeistungsumfangVerkehrMietenOrVermietungSelected
  ) {
    return filter(features, (feature) => !!(
      (merkmaleForTarifgruppeSingle.includes(feature.rubrik) && !isTarifgruppeFamilie)
      || (merkmaleForTarifgruppeSingle.includes(feature.rubrik)
        && isTarifgruppeFamilie && !hasMehrereFahrzeuge)
      || (merkmaleForTarifgruppeFamilie.includes(feature.rubrik)
        && isTarifgruppeFamilie && hasMehrereFahrzeuge)
    ));
  }

  if (combiLeistungsumfangMietenOrVermietungSelected) {
    return filter(features, (feature) => feature.rubrik === 'rubrik1');
  }

  return filter(features, (feature) => {
    const currentRubrik = standardRubriken[feature.rubrik as keyof StandardRubriken];

    if (currentRubrik) {
      for (let i = 0; i < currentRubrik.length; i += 1) {
        if (currentRubrik[i]) {
          return true;
        }
      }
    }

    return false;
  });
}
