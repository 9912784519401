import each from 'lodash/each';

import { fallbacks } from './roles';

import {
  ADD_CLIENT_TO_ROLE,
  REMOVE_CLIENT_BY_ROLE,
  SET_DATA_BY_ROLE,
  GET_CLIENT_BY_ID_SUCCESS,
} from './mutations';

export default {
  fillFallbacks({ state, commit }) {
    each(fallbacks, (fallback, role) => {
      if (state[role].data.id !== null) {
        return;
      }

      commit(ADD_CLIENT_TO_ROLE, {
        roles: [role],
        client: state[fallback],
      });
    });
  },

  /**
   * Removes a role from the client and if no more roles are left remove
   * the client
   *
   * @param {Array} roles
   */
  removeRolesDropIfEmpty({ commit, dispatch }, roles) {
    each(roles, (role) => {
      commit(REMOVE_CLIENT_BY_ROLE, role);
    });

    dispatch('fillFallbacks');
  },

  /**
   * Assigns the given client to the given roles.
   *
   * In case we already have an instance of the same client
   * we just re-reference them.
   *
   * @param client
   * @param roles
   * @param copyToInputStore
   */
  addClient({
    getters,
    commit,
    dispatch,
  }, { client, roles, copyToInputStore = false }) {
    // We can either pass a resource container or a normal client data object
    const data = client.id !== undefined ? client : client.data;
    const instance = getters.getClientById(data.id);

    if (instance === undefined) {
      commit(GET_CLIENT_BY_ID_SUCCESS, { roles, data, copyToInputStore });
    } else {
      commit(ADD_CLIENT_TO_ROLE, { roles, client: instance });

      // Let any new instance overwrite existing data
      // That way we can "refresh" it as expected
      commit(SET_DATA_BY_ROLE, { roles, data });
    }

    dispatch('fillFallbacks');
  },
};
