import mapKeys from 'lodash/mapKeys';

/**
 * Changes the keys of given object containing symfony validation errors
 * so instead of "[foo][bar]" they use the dot syntax ("foo.bar").
 *
 * That way we can access them using lodash get/set more easily.
 *
 * @param {Object} errors
 * @return {Object}
 */
export default function translateSymfonyErrors(errors) {
  return mapKeys(
    errors,
    (_, key) => key
      .substr(1, key.length - 2)
      .replace(/]\[/g, '.')
      .replace(/\.(\d+)/g, '[$1]'),
  );
}
