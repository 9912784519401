import findIndex from 'lodash/findIndex';

import { takeEvery, select, put } from 'redux-saga/effects';

import Company from '../../Company';
import DistributionCompany from '@/types/DistributionCompany';

// @ts-ignore
import { NAMESPACE as COMPANY_NAMESPACE } from '../../index';

// @ts-ignore
import { ADD_COMPANY } from '../../mutations';

// @ts-ignore
import { NAMESPACE } from '../index';

// @ts-ignore
import { GET_DISTRIBUTION_WAYS_SUCCESS, ADD_DISTRIBUTION_DEFAULT } from '../mutations';

/**
 * Adds the company to our local store in case we do not know about it yet.
 * This can happen, if the company is not one we actually want to request
 * offers for, but still can create contracts with.
 */
function* fillMissingCompany({ payload }: any): IterableIterator<any> {
  const { product, data } = payload;
  const companies = yield select((state) => state.companies[product].data);

  for (let i = 0; i < data.vertriebswege.length; i += 1) {
    const company: DistributionCompany = data.vertriebswege[i];
    const newInsuranceId = Number.parseInt(company.insuranceId, 10);

    if (findIndex(companies, ({ insuranceId }) => insuranceId === newInsuranceId) < 0) {
      const mappedCompany: Company = {
        insuranceId: newInsuranceId,
        logoUrl: company.links.logo,
        name: company.name,
        isDirektvereinbarung: true,
        supports: {
          api: true,
          tarife: false,
        },
        isBlacklisted: false,
      };

      yield put({
        type: COMPANY_NAMESPACE + ADD_COMPANY,
        payload: {
          company: mappedCompany,
          product,
        },
      });
      // add company to list of distribution ways
      yield put({
        type: NAMESPACE + ADD_DISTRIBUTION_DEFAULT,
        payload: {
          product,
          insuranceId: newInsuranceId,
        },
      });
    }
  }
}

export default function* fork() {
  yield takeEvery(NAMESPACE + GET_DISTRIBUTION_WAYS_SUCCESS, fillMissingCompany);
}
