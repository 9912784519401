import { AxiosError } from 'axios';
import { captureException, withScope } from '@sentry/browser';
import ApiError from './ApiError';

export default function sendToSentry(error: AxiosError) {
  withScope((scope) => {
    if (error.response) {
      // Exclude the config since it will be saved inside the normal error object
      // Also exclude the request as it will be saved individually
      const { config, request, ...response } = error.response;
      scope.setExtra('response', response);
    }

    if (error.request) {
      scope.setExtra('request', error.request);
    }

    scope.setExtra('error', error);

    scope.setFingerprint([
      error.message,
      error.config.url || '',
    ]);

    captureException(new ApiError(error));
  });
}
