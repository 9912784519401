import api from '@/store/api';

import {
  call,
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import waitForProduct from '@/store/effects/waitForProduct';

import { NAMESPACE as AUTH_NAMESPACE } from '@/store/modules/auth';
import { SET_USER_SUCCESS } from '@/store/modules/auth/mutations';

import { NAMESPACE } from './index';
import {
  GET_COMPANIES,
  GET_COMPANIES_ERROR,
  GET_COMPANIES_SUCCESS,
  GET_SPECIAL_COMPANIES,
  GET_SPECIAL_COMPANIES_SUCCESS,
  GET_SPECIAL_COMPANIES_ERROR,
  UPDATE_LAST_REQUEST_HASH,
} from './mutations';

import { NAMESPACE as OFFER_NAMESPACE } from '@/store/modules/offers';
import { SET_REQUEST_HASH } from '@/store/modules/offers/mutations';

import calculateHash from './calculateHash';

/**
 * Loads a quote for the given ID in the given product category.
 *
 * @param payload
 * @return {IterableIterator<*>}
 */
export function* load({ payload }) {
  const { product } = payload;

  try {
    const { data } = yield call(api, 'get', `${product}/gesellschaften`);
    yield put({
      type: NAMESPACE + GET_COMPANIES_SUCCESS,
      payload: { product, data },
    });
  } catch (error) {
    yield put({
      type: NAMESPACE + GET_COMPANIES_ERROR,
      payload: { product, error },
    });
  }
}

function* loadOnProductChange() {
  while (true) {
    yield take(AUTH_NAMESPACE + SET_USER_SUCCESS);

    const product = yield call(waitForProduct);
    const cached = yield select(({ companies }) => companies[product].data);

    if (cached === null) {
      yield put({
        type: NAMESPACE + GET_COMPANIES,
        payload: { product },
      });
    }
  }
}

function* updateLastRequestHash({ payload }) {
  const { product } = payload;

  const companies = yield select((state) => state.companies[product].data);
  const hash = yield calculateHash(companies);

  yield put({
    type: NAMESPACE + UPDATE_LAST_REQUEST_HASH,
    payload: { product, hash },
  });
}

export function* loadSpecialCompanies({ payload }) {
  const { product } = payload;

  try {
    // could be a product config in the future if other products need special companies
    const { data } = yield call(api, 'get', `${product}/kassen/zusatzbeitraege`);
    yield put({
      type: NAMESPACE + GET_SPECIAL_COMPANIES_SUCCESS,
      payload: { product, data },
    });
  } catch (error) {
    yield put({
      type: NAMESPACE + GET_SPECIAL_COMPANIES_ERROR,
      payload: { product, error },
    });
  }
}

export default function* root() {
  yield takeLatest(NAMESPACE + GET_COMPANIES, load);
  yield takeLatest(NAMESPACE + GET_SPECIAL_COMPANIES, loadSpecialCompanies);
  yield takeEvery(OFFER_NAMESPACE + SET_REQUEST_HASH, updateLastRequestHash);
  yield fork(loadOnProductChange);
}
