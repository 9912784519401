import reduce from 'lodash/reduce';
import { resourceContainer } from '@/utils/store/createPerProductState';

export const FILL_QUESTIONS = 'fillQuestions';

export const GET_QUESTIONS = 'getQuestions';
export const GET_QUESTIONS_SUCCESS = 'getQuestionsSuccess';
export const GET_QUESTIONS_ERROR = 'getQuestionsError';

export default {
  [FILL_QUESTIONS](state, { product, offers }) {
    const reduced = reduce(offers, (acc, offer) => {
      acc[offer.identifier] = {
        ...resourceContainer(null),
      };
      return acc;
    }, {});
    state[product] = { ...state[product], ...reduced };
  },

  [GET_QUESTIONS](state, { product, identifier }) {
    if (!state[product][identifier]) {
      state[product][identifier] = {};
    }
    state[product][identifier].isLoading = true;
  },

  [GET_QUESTIONS_SUCCESS](state, { product, identifier, data }) {
    state[product][identifier].isLoading = false;
    state[product][identifier].error = null;
    state[product][identifier].data = data;
  },

  [GET_QUESTIONS_ERROR](state, { product, identifier, error }) {
    state[product][identifier].isLoading = false;
    state[product][identifier].error = error;
  },
};
