import reduce from 'lodash/reduce';
// @ts-ignore
import { resourceContainer } from '@/utils/store/createPerProductState';
import DistributionCompany from '@/types/DistributionCompany';

export const GET_DISTRIBUTION_WAYS = 'getDistributionWays';
export const GET_DISTRIBUTION_WAYS_SUCCESS = 'getDistributionWaysSuccess';
export const GET_DISTRIBUTION_WAYS_ERROR = 'getDistributionWaysError';
export const SET_DISTRIBUTION_DEFAULTS = 'setDistributionDefaults';
export const ADD_DISTRIBUTION_DEFAULT = 'addDistributionDefault';
// channel related
export const SET_CHANNEL_SELECTION = 'setChannelSelection';
export const SAVE_DISTRIBUTION_CHANNEL = 'saveDistributionChannel';
export const SAVE_DISTRIBUTION_CHANNEL_SUCCESS = 'saveDistributionChannelSuccess';
export const SAVE_DISTRIBUTION_CHANNEL_ERROR = 'saveDistributionChannelError';

// pooling related
export const SET_POOLING_SELECTION = 'setPoolingSelection';
export const CALL_POOLING_CALLBACK = 'callPoolingCallback';
export const CALL_POOLING_CALLBACK_SUCCESS = 'callPoolingCallbackSuccess';
export const CALL_POOLING_CALLBACK_ERROR = 'callPoolingCallbackError';

// improvised store state
interface state {
  [k: string]: any;
}

interface payload {
  product: string;
  insuranceId: string;
  data?: any;
  error?: any;
  companies?: any;
  selection?: string;
}

export default {
  [GET_DISTRIBUTION_WAYS](state: state, { product, insuranceId }: payload) {
    state[product][insuranceId].isLoading = true;
    state[product][insuranceId].channel.callback.error = null;
    state[product][insuranceId].pooling.callback.error = null;
  },

  [GET_DISTRIBUTION_WAYS_SUCCESS](state: state, { product, insuranceId, data }: payload) {
    state[product][insuranceId].isLoading = false;
    state[product][insuranceId].data = data;
  },

  [GET_DISTRIBUTION_WAYS_ERROR](state: state, { product, insuranceId, error }: payload) {
    state[product][insuranceId].isLoading = false;
    state[product][insuranceId].error = error;
  },

  [SET_DISTRIBUTION_DEFAULTS](state: state, { product, companies }: payload) {
    state[product] = reduce(companies, (acc, company: DistributionCompany) => {
      // need proper typing...
      // @ts-ignore
      acc[company.insuranceId as keyof acc] = {
        ...resourceContainer(null),
        pooling: {
          callback: resourceContainer(null),
          selection: null,
        },
        channel: {
          callback: resourceContainer(null),
          selection: null,
        },
      };
      return acc;
    }, {});
  },

  [ADD_DISTRIBUTION_DEFAULT](state: state, { product, insuranceId }: payload) {
    state[product][insuranceId] = {
      ...resourceContainer(null),
      pooling: {
        callback: resourceContainer(null),
        selection: null,
      },
      channel: {
        callback: resourceContainer(null),
        selection: null,
      },
    };
  },

  // CHANNEL
  [SET_CHANNEL_SELECTION](state: state, { product, insuranceId, selection }: payload) {
    state[product][insuranceId].channel.selection = selection;
    state[product][insuranceId].pooling.selection = null;
  },

  [SAVE_DISTRIBUTION_CHANNEL](state: state, { product, insuranceId }: payload) {
    state[product][insuranceId].channel.callback.isLoading = true;
  },

  [SAVE_DISTRIBUTION_CHANNEL_SUCCESS](state: state, { product, insuranceId }: payload) {
    state[product][insuranceId].channel.callback.isLoading = false;
  },

  [SAVE_DISTRIBUTION_CHANNEL_ERROR](state: state, { product, insuranceId, error }: payload) {
    state[product][insuranceId].channel.callback.isLoading = false;
    state[product][insuranceId].channel.callback.error = error;
  },

  // POOLING
  [SET_POOLING_SELECTION](state: state, { product, insuranceId, selection }: payload) {
    state[product][insuranceId].pooling.selection = selection;
  },

  [CALL_POOLING_CALLBACK](state: state, { product, insuranceId }: payload) {
    state[product][insuranceId].pooling.callback.isLoading = true;
  },

  [CALL_POOLING_CALLBACK_SUCCESS](state: state, { product, insuranceId, data }: payload) {
    state[product][insuranceId].pooling.callback.isLoading = false;
    state[product][insuranceId].pooling.callback.data = data;
  },

  [CALL_POOLING_CALLBACK_ERROR](state: state, { product, insuranceId, error }: payload) {
    state[product][insuranceId].pooling.callback.isLoading = false;
    state[product][insuranceId].pooling.callback.error = error;
  },
};
