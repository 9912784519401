/* eslint-disable @typescript-eslint/no-var-requires */

import Vue from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

Vue.component('FontAwesomeIcon', FontAwesomeIcon);
library.add(
  require('@fortawesome/free-regular-svg-icons/faCheckCircle').definition,
  require('@fortawesome/free-regular-svg-icons/faFile').definition,
  require('@fortawesome/free-regular-svg-icons/faFilePdf').definition,
  require('@fortawesome/free-regular-svg-icons/faHeart').definition,
  require('@fortawesome/free-regular-svg-icons/faTrashAlt').definition,
  require('@fortawesome/free-regular-svg-icons/faStar').definition,
  require('@fortawesome/free-solid-svg-icons/faAngleDown').definition,
  require('@fortawesome/free-solid-svg-icons/faAngleRight').definition,
  require('@fortawesome/free-solid-svg-icons/faArrowAltCircleLeft').definition,
  require('@fortawesome/free-solid-svg-icons/faBell').definition,
  require('@fortawesome/free-solid-svg-icons/faCalendarAlt').definition,
  require('@fortawesome/free-solid-svg-icons/faCheck').definition,
  require('@fortawesome/free-solid-svg-icons/faCheckCircle').definition,
  require('@fortawesome/free-solid-svg-icons/faCogs').definition,
  require('@fortawesome/free-solid-svg-icons/faEnvelope').definition,
  require('@fortawesome/free-solid-svg-icons/faExchangeAlt').definition,
  require('@fortawesome/free-solid-svg-icons/faExclamationTriangle').definition,
  require('@fortawesome/free-solid-svg-icons/faEye').definition,
  require('@fortawesome/free-solid-svg-icons/faHeart').definition,
  require('@fortawesome/free-solid-svg-icons/faInfo').definition,
  require('@fortawesome/free-solid-svg-icons/faList').definition,
  require('@fortawesome/free-solid-svg-icons/faListUl').definition,
  require('@fortawesome/free-solid-svg-icons/faLock').definition,
  require('@fortawesome/free-solid-svg-icons/faMinus').definition,
  require('@fortawesome/free-solid-svg-icons/faPencilAlt').definition,
  require('@fortawesome/free-solid-svg-icons/faPlus').definition,
  require('@fortawesome/free-solid-svg-icons/faPrint').definition,
  require('@fortawesome/free-solid-svg-icons/faRedo').definition,
  require('@fortawesome/free-solid-svg-icons/faSave').definition,
  require('@fortawesome/free-solid-svg-icons/faSearch').definition,
  require('@fortawesome/free-solid-svg-icons/faSortAmountUp').definition,
  require('@fortawesome/free-solid-svg-icons/faStamp').definition,
  require('@fortawesome/free-solid-svg-icons/faSync').definition,
  require('@fortawesome/free-solid-svg-icons/faTimes').definition,
  require('@fortawesome/free-solid-svg-icons/faTimesCircle').definition,
  require('@fortawesome/free-solid-svg-icons/faUser').definition,
  require('@fortawesome/free-solid-svg-icons/faCalculator').definition,
  require('@fortawesome/free-solid-svg-icons/faWrench').definition,
  require('@fortawesome/free-solid-svg-icons/faStar').definition,
  require('@fortawesome/free-solid-svg-icons/faStarHalfAlt').definition,

  // seem to be unused
  // require('@fortawesome/free-solid-svg-icons/faBold').definition,
  // require('@fortawesome/free-solid-svg-icons/faDownload').definition,
  // require('@fortawesome/free-solid-svg-icons/faHome').definition,
  // require('@fortawesome/free-solid-svg-icons/faItalic').definition,
  // require('@fortawesome/free-solid-svg-icons/faKey').definition,
  // require('@fortawesome/free-solid-svg-icons/faListOl').definition,
  // require('@fortawesome/free-solid-svg-icons/faUnderline').definition,
  // require('@fortawesome/free-solid-svg-icons/faUndo').definition,
  // require('@fortawesome/free-solid-svg-icons/faRulerHorizontal').definition,

);
