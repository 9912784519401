import findIndex from 'lodash/findIndex';
import products from '@/products';

/**
 * Represents a directive that automatically adds the proper class
 * for form fields that can be stored inside a profile.
 *
 * Form fields using this directive will have to provide
 * the exact path as declared in the 'profileFields' list
 * (which is also where they're stored in the state).
 */
export default {
  componentUpdated(el, { value }, { context }) {
    const isArray = Array.isArray(value);

    if (!isArray && typeof value !== 'string') {
      throw new Error('trying to highlight invalid form field');
    }

    const { product } = context.$store.state;

    // Safety checks - unsupported/invalid product
    if (products[product] === undefined) {
      return;
    }

    // Only add the class if it's a field we can save
    const { profileFields = [] } = products[product].props;
    const isValid = (item) => profileFields.indexOf(item) >= 0;

    if ((isArray && findIndex(value, isValid) < 0) || (!isArray && !isValid(value))) {
      return;
    }

    el.classList.add('form-highlight');
  },
};
