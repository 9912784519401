import get from 'lodash/get';
// @ts-ignore
import translateSymfonyErrors from '@/utils/api/translateSymfonyErrors';
import { ApiResource } from '@/types';

export default {
  validationErrors({ response }: { response: ApiResource<any>}) {
    return translateSymfonyErrors(get(response.error, 'data.data', {}));
  },
};
