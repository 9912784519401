<template>
  <div>
    <Component
      :is="modal.component"
      v-for="modal in stack"
      :key="modal.id"
      v-bind="modal.props"
      @shown="modal.shown"
      @hidden="e => close(e, modal)"
    />
  </div>
</template>

<script>
import find from 'lodash/find';
import events, { EVENT_ADD, EVENT_CLEAR, EVENT_CLOSE } from './events';

export default {
  data: () => ({
    stack: [],
    counter: 0,
  }),

  created() {
    events.$on(EVENT_ADD, this.add);
    events.$on(EVENT_CLEAR, this.clear);
    events.$on(EVENT_CLOSE, this.close);
  },

  destroyed() {
    events.$off(EVENT_ADD, this.add);
    events.$off(EVENT_CLEAR, this.clear);
    events.$off(EVENT_CLOSE, this.close);
  },

  methods: {
    add(entry) {
      // Only allow one modal instance at a time (treat them like singletons)
      const instance = find(this.stack, ['component', entry.component]);

      if (instance !== undefined) {
        return;
      }

      this.stack.push({
        ...entry,
        id: this.counter += 1,
      });
    },

    clear() {
      while (this.stack.length > 0) {
        this.close(this.stack[0]);
      }
    },

    close(event, instance = null) {
      if (!instance) {
        this.stack.pop();
        return;
      }

      this.stack.splice(this.stack.indexOf(instance), 1);
      instance.hidden(event);
    },
  },
};
</script>
