import assign from 'lodash/assign';

export default (data) => {
  const wrapper = data.data;

  // Check if we even got a JSend response
  if (wrapper.data !== undefined && wrapper.status !== undefined) {
    if (wrapper.status !== 'success') {
      const error = new Error(`Invalid status code for '${wrapper.status}' response`);
      throw assign(error, wrapper);
    }

    // eslint-disable-next-line no-param-reassign
    data.data = wrapper.data;
  }

  return data;
};
