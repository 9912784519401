import api from '@/store/api';
import {
  call,
  cancel,
  fork,
  put,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects';

import { NAMESPACE as OFFER_NAMESPACE } from '@/store/modules/offers';
import { GET_OFFERS } from '@/store/modules/offers/actions/offersByCompany';

import { NAMESPACE } from './index';
import {
  CLEAR_FEATURES,
  GET_FEATURES,
  GET_FEATURES_ERROR,
  GET_FEATURES_SUCCESS,
} from './mutations';

/**
 * Loads the features for the given offer.
 * This will load the features for its current calculation identifier.
 *
 * @param {{ payload: string }} offer
 * @return {IterableIterator<*>}
 */
export function* getFeatures({ payload: key }) {
  try {
    const product = yield select((store) => store.product);
    const berechnungsId = yield select((store) => store.offers.remoteHash);
    const allTimeTariffId = yield select(
      (store) => store.offers.vorversicherung.data[0]?.identifier,
    );
    const isAllTimeTariff = allTimeTariffId === key;

    const { data } = yield call(api, 'get', `/${product}/tarif/${key}/merkmale`, {
      params: { berechnungsId, isAllTimeTariff },
    });

    yield put({
      type: NAMESPACE + GET_FEATURES_SUCCESS,
      payload: { key, data: data.merkmale },
    });
  } catch (error) {
    yield put({
      type: NAMESPACE + GET_FEATURES_ERROR,
      payload: { key, error },
    });
  }
}

/**
 * Loads features, but cancels the task when we're loading a new offer page.
 *
 * @return {IterableIterator<*>}
 */
export function* loadFeatures(args) {
  const task = yield fork(getFeatures, args);
  yield take(NAMESPACE + CLEAR_FEATURES);
  yield cancel(task);
}

/**
 * Clears our cached results whenever we will fetch a new offer list.
 *
 * @return {IterableIterator<*>}
 */
export function* clearCache() {
  yield put({ type: NAMESPACE + CLEAR_FEATURES });
}

export default function* root() {
  yield takeEvery(OFFER_NAMESPACE + GET_OFFERS, clearCache);
  yield takeEvery(NAMESPACE + GET_FEATURES, loadFeatures);
}
