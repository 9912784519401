import cloneDeep from 'lodash/cloneDeep';
import filter from 'lodash/filter';
import flatMap from 'lodash/flatMap';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import omit from 'lodash/omit';
import set from 'lodash/set';

import products from '@/products';

import { resourceContainer } from '@/utils/store/createPerProductState';
import fields from './fields';
import { isValid } from './roles';

import { MappingMap } from './MappingerMapper/tempMapper';

export const ADD_CLIENT_TO_ROLE = 'addClientToRoles';
export const GET_CLIENT_BY_ID = 'getClientById';
export const GET_CLIENT_BY_ID_ERROR = 'getClientByIdError';
export const GET_CLIENT_BY_ID_SUCCESS = 'getClientByIdSuccess';
export const INIT_CLIENT_BY_ROLE = 'initClientByRole';
export const REMOVE_CLIENT_BY_ROLE = 'removeClientByRole';
export const MARK_AS_DIRTY = 'markAsDirty';
export const RESET_DIRTY_FLAG = 'resetDirtyFlag';
export const SAVE_CLIENT_BY_ROLE = 'saveClientByRole';
export const SAVE_CLIENT_BY_ROLE_ERROR = 'saveClientByRoleError';
export const SAVE_CLIENT_BY_ROLE_SUCCESS = 'saveClientByRoleSuccess';
export const SET_DATA_BY_ROLE = 'setStateByRole';
export const SET_ID_BY_ROLE = 'setIdByRole';
export const SET_INPUT_FIELD = 'setInputField';
export const SET_CLIENT_ERRORS = 'setClientErrors';

const validateRole = (role) => {
  if (!isValid(role)) {
    throw new Error(`Invalid role specified: ${role}`);
  }
};

const syncRoles = (product, roles) => (product !== null ? [
  ...flatMap(roles, (role) => {
    const settings = products[product].props.productSyncRoleSettings;
    return get(settings, role, []);
  }),
  ...roles,
] : roles);

let refCounter = 0;

export const createClient = (data) => {
  refCounter += 1;

  return {
    ...resourceContainer(data === undefined ? cloneDeep(fields) : data),
    dirty: false,
    _refId: refCounter,
  };
};

export default {
  /**
   * Creates a client for the given roles.
   * This will create a shared instance in case of multiple roles.
   *
   * @param state
   * @param roles
   */
  [INIT_CLIENT_BY_ROLE](state, { roles = [] }) {
    const client = createClient();

    forEach(syncRoles(this.state.product, roles), (role) => {
      validateRole(role);
      state[role] = client;
    });
  },

  [GET_CLIENT_BY_ID](state, { roles }) {
    // Keep the current client instance, but indicate that we're loading stuff
    forEach(syncRoles(this.state.product, roles), (role) => {
      validateRole(role);
      state[role].isLoading = true;
    });
  },

  [GET_CLIENT_BY_ID_SUCCESS](state, { roles, data, keepQuoteData }) {
    const { product } = this.state;
    const allRoles = syncRoles(product, roles);
    const fieldList = products[product].props.keepInputFromQuotes || [];
    const fieldsNotEmpty = [];

    const clientData = data;

    // when the client has gender unknown (nothing set in pw)
    // we delete the gender key and use the defaults from the roles
    if (clientData.gender === 'unknown') {
      delete clientData.gender;
    }

    // when the client has no nationality, we delete the key to allow usage of
    // default values from dropdowns.
    // this is needed because the backend fills the empty fields with empty
    // strings, but the dropdown components can't handle that correctly
    if (clientData.nationality === '') {
      delete clientData.nationality;
    }

    // Reset the loading state in case they were different instances
    forEach(allRoles, (role) => {
      validateRole(role);
      state[role].isLoading = false;

      if (keepQuoteData === true) {
        // List all fields with a value that we want to keep
        fieldsNotEmpty.push(...filter(fieldList, (field) => (
          get(state[role].data, field, undefined) !== undefined
        )));
      }
    });

    // Create a shared instance again
    let client;

    if (keepQuoteData === true) {
      // Actually do overwrite fields from the "removal list" which don't hold a value
      client = createClient(omit(clientData, intersection(fieldList, fieldsNotEmpty)));
    } else {
      client = createClient(clientData);
    }

    forEach(allRoles, (role) => {
      state[role] = merge(state[role], client);
    });
  },

  [GET_CLIENT_BY_ID_ERROR](state, { roles, error }) {
    forEach(syncRoles(this.state.product, roles), (role) => {
      validateRole(role);
      state[role].isLoading = false;
      state[role].error = error;
    });
  },

  [SET_DATA_BY_ROLE](state, { roles, data }) {
    if (isEmpty(data)) {
      return;
    }

    forEach(syncRoles(this.state.product, roles), (role) => {
      validateRole(role);

      const client = state[role];

      forEach(data, (value, key) => {
        client.data[key] = value;
        client.dirty = true;
      });
    });
  },

  [SET_ID_BY_ROLE](state, { id, role }) {
    validateRole(role);
    state[role].data.id = id;
  },

  [ADD_CLIENT_TO_ROLE](state, { client, roles }) {
    forEach(syncRoles(this.state.product, roles), (role) => {
      validateRole(role);
      state[role] = client;
    });
  },

  [REMOVE_CLIENT_BY_ROLE](state, role) {
    validateRole(role);

    // Initialize with a new, empty client
    state[role] = createClient();
  },

  [SET_INPUT_FIELD](state, { role, field, value }) {
    validateRole(role);
    set(state[role].data, field, value);
    state[role].dirty = true;
  },

  [MARK_AS_DIRTY](state, role) {
    state[role].dirty = true;
  },

  [RESET_DIRTY_FLAG](state, ctx) {
    ctx.dirty = false;
  },

  [SAVE_CLIENT_BY_ROLE](state, { role }) {
    validateRole(role);
    state[role].isLoading = true;
  },

  [SAVE_CLIENT_BY_ROLE_SUCCESS](state, { role }) {
    validateRole(role);
    state[role].isLoading = false;
    state[role].error = null;
  },

  [SAVE_CLIENT_BY_ROLE_ERROR](state, { role, error }) {
    validateRole(role);
    state[role].isLoading = false;
    state[role].error = error;
  },
  [SET_CLIENT_ERRORS](state, errors) {
    // clean all old errors before setting new ones
    forEach(state, (role, roleKey) => {
      state[roleKey].error = null;
    });

    forEach(errors, (value, key) => {
      // extract ref id from key name
      const indexOfRefId = key.indexOf(']') + 2;
      const refId = Number.parseInt(key.substr(indexOfRefId, 1), 10);

      forEach(state, (role, roleKey) => {
        // eslint-disable-next-line no-underscore-dangle
        if (role._refId === refId) {
          // extract remoteFieldName from key name
          const remoteFieldName = key.substr(indexOfRefId + 9, (key.length - (indexOfRefId + 10)));

          // try finding remoteFieldName in our new mapping
          const localFieldName = MappingMap[remoteFieldName];

          // if no localFieldName was found fall back to remoteFieldName
          const fieldName = localFieldName ?? remoteFieldName;

          state[roleKey].error = {
            ...state[roleKey].error,
            [fieldName]: value,
          };
        }
      });
    });
  },
};
