import { GetterTree } from 'vuex';
import find from 'lodash/find';

export enum DISTRIBUTION_GETTERS {
  SELECTED_POOL = 'selectedPool',
  SELECTED_CHANNEL = 'selectedChannel',
  VERMITTLERNUMMER = 'vermittlernummer',
  POOL_VERMITTLERNUMMER = 'poolVermittlernummer',
}

const getters: GetterTree<any, any> = {
  [DISTRIBUTION_GETTERS.SELECTED_POOL](state, _, rootState) {
    const { product } = rootState;
    return (insuranceId: string) => (
      state[product] && state[product][insuranceId]
        ? state[product][insuranceId].pooling.selection
        : null
    );
  },

  [DISTRIBUTION_GETTERS.SELECTED_CHANNEL](state, _, rootState) {
    const { product } = rootState;
    return (insuranceId: string) => (
      state[product] && state[product][insuranceId]
        ? state[product][insuranceId].channel.selection
        : null
    );
  },

  [DISTRIBUTION_GETTERS.VERMITTLERNUMMER](state, _, rootState) {
    const { product } = rootState;
    return (selectedChannelId: string, offerInsuranceId: string) => {
      const vermittlernummer = find(
        state[product]?.[offerInsuranceId]?.data?.vertriebswege,
        (weg) => weg.insuranceId === selectedChannelId,
      // use ?. operator to account for the possibility of no vertriebswege options at all
      )?.vermittlernummer;

      return vermittlernummer || null;
    };
  },

  [DISTRIBUTION_GETTERS.POOL_VERMITTLERNUMMER](state, _, rootState) {
    const { product } = rootState;
    return (selectedPool: string, offerInsuranceId: string) => {
      const vermittlernummer = find(
        state[product]?.[offerInsuranceId]?.data?.pools,
        (pool) => pool.name === selectedPool,
        // use ?. operator to account for the possibility of no vertriebswege options at all
      )?.vermittlernummer;

      return vermittlernummer || null;
    };
  },

};

export default getters;
