import createPerProductState from '@/utils/store/createPerProductState';
import cloneDeep from 'lodash/cloneDeep';
import map from 'lodash/map';

import sortables, { defaults } from './sortables';

// (only take select properties from the objects)
export const sorting = map(defaults, (key) => ({ key, order: sortables[key].order }));

export default {
  // Answers for offer specific questions
  answers: createPerProductState(() => ({})),

  // Data fetching
  lastRequestHash: 0,
  lastRequestTime: 0,
  remoteHash: null,
  links: {},
  data: {
    fehler: [],
    hinweise: [],
    nichtBerechneteTarife: [],
    tariffehler: [],
  },

  // We store each chunk by their company ID
  chunks: {},

  // Feature settings
  maxSelectionCount: 3,
  recommended: null,
  selected: [],

  // Filters
  searchStrings: [],
  filters: {
    directAgreement: false,
    blacklistedCompanies: [],
  },

  allTimeTariffs: {
    data: null,
    isLoading: false,
    error: null,
  },

  // Sorting options
  sorting: cloneDeep(sorting),

  vorversicherung: {
    isLoading: false,
    error: null,
    data: {},
  },
};
