import {
  ADD_SELECTION,
  SET_RECOMMENDED,
  REMOVE_SELECTION,
  RESET_SELECTION,
} from './mutations';

const isSameOffer = (first, second) => (first.identifier === second.identifier);

export default {
  toggleRecommendation({ commit, getters }, offer) {
    if (getters.isRecommended(offer)) {
      commit(SET_RECOMMENDED, null);
      return;
    }

    // Refresh our position in the selection stack
    // so recommendations always get removed last
    commit(REMOVE_SELECTION, offer);
    commit(ADD_SELECTION, offer);

    commit(SET_RECOMMENDED, offer);
  },

  toggleSelection({ commit, getters }, offer) {
    if (getters.isSelected(offer)) {
      commit(REMOVE_SELECTION, offer);
      return;
    }

    commit(ADD_SELECTION, offer);
  },

  uniqueSelection({ commit, getters }, offer) {
    if (offer === null || offer === undefined) {
      return;
    }

    const { selection } = getters;
    if (selection.length !== 1 || !isSameOffer(selection[0], offer)) {
      commit(RESET_SELECTION);
      commit(ADD_SELECTION, offer);
    }

    commit(SET_RECOMMENDED, offer);
  },
};
