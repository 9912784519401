/* eslint-disable no-underscore-dangle */
import { NumberSchema } from 'yup';

export default class NumberEmpty extends NumberSchema {
  _typeCheck(value: unknown): value is number {
    return value === '' || value === null || (super._typeCheck && super._typeCheck(value));
  }

  // FUCK TS / OOP, it doesn't support overriding
  // methods with different return type so for the
  // moment we ignore the error as JS doesn't care
  // @ts-ignore
  cast(value: unknown, options: {}): number | null {
    if (value === '' || value === null || value === undefined) {
      return null;
    }

    const castedValue = super.cast(value, options);

    return castedValue === undefined ? null : castedValue;
  }
}
