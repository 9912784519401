import createPerProductState from '@/utils/store/createPerProductState';

import modals from './modals';

import mutations from './mutations';
import getters from './getters';
import sagas from './sagas';

export const NAMESPACE = 'input/';

export default {
  namespaced: true,

  mutations,
  getters,
  sagas,

  state: createPerProductState(),

  modules: {
    modals,
  },
};
