import {
  call,
  put,
  select,
  takeEvery,
  fork,
} from 'redux-saga/effects';
import api from '@/store/api';

import { NAMESPACE } from './index';
import {
  CALL_POOLING_CALLBACK, CALL_POOLING_CALLBACK_ERROR, CALL_POOLING_CALLBACK_SUCCESS,
  GET_DISTRIBUTION_WAYS,
  GET_DISTRIBUTION_WAYS_ERROR,
  GET_DISTRIBUTION_WAYS_SUCCESS,
  SAVE_DISTRIBUTION_CHANNEL,
  SAVE_DISTRIBUTION_CHANNEL_ERROR,
  SAVE_DISTRIBUTION_CHANNEL_SUCCESS,
  SET_CHANNEL_SELECTION,
  SET_DISTRIBUTION_DEFAULTS,
} from './mutations';
import { NAMESPACE as COMPANY_NAMESPACE } from '../index';
import { GET_COMPANIES_SUCCESS } from '../mutations';

import fillMissingCompany from './sagas/fillMissingCompany';

/**
 * Loads distribution details for the given insurance ID in the given product category.
 *
 * @param payload
 * @return {IterableIterator<*>}
 */
function* loadData({ payload }) {
  const { product, insuranceId } = payload;
  try {
    const { data } = yield call(
      api,
      'get',
      `${product}/gesellschaften/${insuranceId}/vertriebswege`,
    );

    yield put({
      type: NAMESPACE + GET_DISTRIBUTION_WAYS_SUCCESS,
      payload: { product, insuranceId, data },
    });
  } catch (error) {
    yield put({
      type: NAMESPACE + GET_DISTRIBUTION_WAYS_ERROR,
      payload: { product, insuranceId, error },
    });
  }
}

/**
 * Resets the distribution channel settings / data
 *
 * @param payload
 * @return {IterableIterator<*>}
 */
function* resetChannelingOptions({ payload }) {
  yield put({
    type: NAMESPACE + SET_DISTRIBUTION_DEFAULTS,
    payload: {
      product: payload.product,
      companies: payload.data,
    },
  });
}

/**
 * Set channel selection to store when saving was successful
 *
 * @param payload
 * @return {IterableIterator<*>}
 */
function* setOnStoreChannelSuccess({ payload }) {
  yield put({
    type: NAMESPACE + SET_CHANNEL_SELECTION,
    payload: {
      product: payload.product,
      insuranceId: payload.insuranceId,
      selection: payload.selection,
    },
  });
}

/**
 * Save channel selection
 *
 * @param payload
 * @return {IterableIterator<*>}
 */
function* storeChannel({ payload }) {
  const {
    product, insuranceId, selection, onSuccess, onError,
  } = payload;

  try {
    const { data } = yield call(api, 'post', `${product}/gesellschaften/${insuranceId}/vertriebsweg`, {
      targetInsuranceId: selection,
    });

    yield put({
      type: NAMESPACE + SAVE_DISTRIBUTION_CHANNEL_SUCCESS,
      payload: {
        product, insuranceId, data, selection,
      },
    });

    if (onSuccess !== undefined) {
      yield call(onSuccess, data);
    }
  } catch (error) {
    yield put({
      type: NAMESPACE + SAVE_DISTRIBUTION_CHANNEL_ERROR,
      payload: {
        product, insuranceId, error, selection,
      },
    });

    if (onError !== undefined) {
      yield call(onError, error);
    }
  }
}

/**
 * Set defaul channel option if exists
 *
 * @param payload
 * @return {IterableIterator<*>}
 */
function* selectDefaultChannelOption({ payload }) {
  const { product, insuranceId, data } = payload;

  if (data.vertriebswege.length === undefined || data.vertriebswege.length > 1) {
    return;
  }

  yield put({
    type: NAMESPACE + SET_CHANNEL_SELECTION,
    payload: {
      product,
      insuranceId,
      selection: data.vertriebswege[0] !== undefined ? data.vertriebswege[0].insuranceId : null,
    },
  });
}

/**
 * Callback to send Pooling request to backend
 *
 * @param payload
 * @return {IterableIterator<*>}
 */
function* callPoolingCallback({ payload }) {
  const {
    product, insuranceId, url, onSuccess, onError,
  } = payload;

  const state = yield select(
    ({ companies }) => companies.distribution[product][insuranceId].pooling,
  );

  if (state === undefined) {
    return;
  }

  try {
    let { data } = state.callback;

    // Skip duplicate calls to the callback URL
    if (data === null) {
      ({ data } = (yield call(api, 'post', url)));
    }

    yield put({
      type: NAMESPACE + CALL_POOLING_CALLBACK_SUCCESS,
      payload: { product, insuranceId, data },
    });
    yield call(onSuccess, data);
  } catch (error) {
    yield put({
      type: NAMESPACE + CALL_POOLING_CALLBACK_ERROR,
      payload: { product, insuranceId, error },
    });
    yield call(onError, error);
  }
}

export default function* root() {
  // common
  yield takeEvery(`${NAMESPACE}${GET_DISTRIBUTION_WAYS}`, loadData);
  yield takeEvery(COMPANY_NAMESPACE + GET_COMPANIES_SUCCESS, resetChannelingOptions);
  // select default channel
  yield takeEvery(NAMESPACE + GET_DISTRIBUTION_WAYS_SUCCESS, selectDefaultChannelOption);
  // save channel
  yield takeEvery(NAMESPACE + SAVE_DISTRIBUTION_CHANNEL, storeChannel);
  yield takeEvery(NAMESPACE + SAVE_DISTRIBUTION_CHANNEL_SUCCESS, setOnStoreChannelSuccess);
  // call pooling callback
  yield takeEvery(NAMESPACE + CALL_POOLING_CALLBACK, callPoolingCallback);

  yield fork(fillMissingCompany);
}
