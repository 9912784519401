import * as yup from 'yup';
import NumberEmpty from '@/plugins/yup-extension/numberEmptyString';
import locales from '@/plugins/vormik2/yupLocales';
import { getDay100YearsBefore } from '@/utils/date';

yup.setLocale(locales);

export default yup.object({
  address: yup.object({
    street: yup.string().nullable().defined().default(null),
    number: yup.string().nullable().defined().default(null),
    zipCode: yup.string().nullable().defined().default(null),
    city: yup.string().nullable().defined().default(null),
    country: yup.string().nullable().defined().default(null),
  }).defined(),
  bank: yup.object({
    bankName: yup.string().nullable().defined().default(null),
    ibanCode: yup.string().nullable().defined().default(null),
    swiftCode: yup.string().nullable().defined().default(null),
  }).defined(),
  birthDate: yup.date().nullable().defined().default(null)
    .min(getDay100YearsBefore(), 'Das Alter darf höchstens 100 Jahre betragen.'),
  birthName: yup.string().nullable().defined().default(null),
  cellphone: yup.string().nullable().defined().default(null),
  commercialWorkPercentage: new NumberEmpty().defined().default(70),
  countryOfBirth: yup.string().nullable().defined().default(null),
  email: yup.string().nullable().defined().default(null),
  education: yup.string().nullable().defined().default(null),
  firstName: yup.string().defined().default(''),
  id: new NumberEmpty().nullable().defined().default(null),
  lastName: yup.string().defined().default(''),
  maritalStatus: yup.string().nullable().defined().default(null),
  nationality: yup.string().nullable().defined().default(null),
  numberOfChildren: new NumberEmpty().defined().default(0),
  personnelResponsibilityCount: new NumberEmpty().defined().default(0),
  phone: yup.string().nullable().defined().default(null),
  placeOfBirth: yup.string().nullable().defined().default(null),
  profession: yup.string().defined().default(''),
  professionalStatus: yup.string().nullable().defined().default(null),
  salutation: yup.string().nullable().defined().default(null),
  nonSmoker: yup.string().nullable().defined().default(null),
  title: yup.string().nullable().defined().default(null),
  weight: yup.number().nullable().defined().default(null),
  height: yup.number().nullable().defined().default(null),
  gender: yup.string().nullable().defined().oneOf(['maennlich', 'weiblich'])
    .default('maennlich'),
}).defined();
