




























import {
  computed, defineComponent, ref, watch,
} from '@vue/composition-api';

enum Variant {
  CHEVRON = 'chevron',
  PLUS = 'plus',
}

export default defineComponent({
  name: 'TarifModuleContainer',

  props: {
    active: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: Variant.CHEVRON,
    },
    close: {
      type: Boolean,
      default: false,
    },
    indent: {
      type: Boolean,
      default: true,
    },
  },

  setup(props) {
    const visible = ref(props.active);

    const icon = computed(() => {
      if (props.variant === Variant.CHEVRON) {
        return visible.value ? 'angle-down' : 'angle-right';
      }

      return visible.value ? 'minus' : 'plus';
    });

    watch(() => props.active, (newVal, oldVal) => {
      if (newVal && newVal !== oldVal) {
        visible.value = true;
      }

      if (!newVal) {
        visible.value = false;
      }
    });

    watch(() => props.close, (newVal) => {
      if (!newVal) {
        visible.value = false;
      }
    });

    return {
      icon,
      visible,
    };
  },

});
