import { ProductConfig } from '@/types';
import difference from 'lodash/difference';
import keys from 'lodash/keys';
import map from 'lodash/map';
import steps from './steps';

// Fast access to valid steps
const validStepKeys = keys(steps);

const addWizardSteps = (product: ProductConfig, identifier: string) => {
  const invalid = difference(keys(product.steps), validStepKeys);

  if (invalid.length > 0) {
    throw new Error(
      `Product '${product.props.name}' contains invalid steps: '${invalid.join('\', \'')}'`,
    );
  }

  return map(product.steps, (step, name: keyof typeof steps) => ({
    ...steps[name],
    ...step,
    name: `${identifier}.${name}`,
  }));
};

const addChannelStep = (
  product: ProductConfig,
  identifier: string,
) => (product.props.shouldChannel === true ? [
  {
    name: `${identifier}.select-channel`,
    path: 'gesellschaft/:insuranceId/vertriebsweg',
    component: () => import('@/views/pages/distribution/index.vue'),
  },
] : []);

/**
 * Parses the routes for the given product.
 * @param {{ steps: Object }} product
 * @param {String} identifier The internal product identifier
 * @return {Array}
 */
export default function parse(product: ProductConfig, identifier: string) {
  const prefix = identifier.toLowerCase();

  return [
    ...addWizardSteps(product, prefix),
    ...addChannelStep(product, prefix),
  ];
}
