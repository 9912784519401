/**
 * Small wrapper around the analytics library of our choice.
 * This only actually tracks if everything is set up properly.
 *
 * @param event
 */
export default function analytics(event) {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.debug('Tracking Analytics', event);
  }

  // eslint-disable-next-line no-underscore-dangle
  const matomo = window._paq;

  if (matomo) {
    matomo.push(event);
  }
}
