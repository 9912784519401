import Vue from 'vue';

const events = new Vue({
  name: 'ModalEvents',
});

export const EVENT_ADD = 'add';
export const EVENT_CLOSE = 'close';
export const EVENT_CLEAR = 'clear';

export default events;
