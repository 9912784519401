import map from 'lodash/map';

/**
 * Converts the given object to an object matching the
 * jQuery.serialized function format. This is required
 * only for legacy apis.
 *
 * @param {Object} raw object to be converted
 * @return {Object} serialized object
 */
export default function jQuerySerialize(raw) {
  return map(raw, (value, name) => (
    { name, value: (value !== null && value !== undefined ? `${value}` : '') }
  ));
}
