import { GET_COMPANIES } from './mutations';

export default {
  /**
   * Requests the company list for the given model
   * in case it has not been loaded yet.
   *
   * @param key The identifier for the choice list we get the companies for
   */
  fetchCompanies({ commit, getters }, key) {
    if (getters.containsCompany(key)) {
      return;
    }

    commit(GET_COMPANIES, key);
  },
};
