import Vue from 'vue';

import {
  BFormGroup,
  BFormInput,
  BInputGroup,
  BFormRadioGroup,
  BFormRadio,
  BFormCheckbox,
  BSpinner,
  BCollapse,
  VBPopoverPlugin,
  VBTogglePlugin,
} from 'bootstrap-vue';

Vue.use(VBPopoverPlugin);
Vue.use(VBTogglePlugin);

Vue.component('BFormGroup', BFormGroup);
Vue.component('BFormInput', BFormInput);
Vue.component('BInputGroup', BInputGroup);
Vue.component('BFormRadioGroup', BFormRadioGroup);
Vue.component('BFormRadio', BFormRadio);
Vue.component('BFormCheckbox', BFormCheckbox);
Vue.component('BSpinner', BSpinner);
Vue.component('BCollapse', BCollapse);
