import api from '@/store/api';

import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { NAMESPACE } from './index';
import { GET_COMPANIES, GET_COMPANIES_SUCCESS, GET_COMPANIES_ERROR } from './mutations';

/**
 * Fetches the companies for the given choice model.
 *
 * @param key
 * @return {IterableIterator<*>}
 */
export function* fetchCompanies({ payload: key }) {
  try {
    const product = yield select((state) => state.product);
    const { data } = yield call(api, 'get', `/${product}/choices/${key}/companies`);

    yield put({
      type: NAMESPACE + GET_COMPANIES_SUCCESS,
      payload: {
        key,
        data: data.companies,
      },
    });
  } catch (error) {
    yield put({
      type: NAMESPACE + GET_COMPANIES_ERROR,
      payload: {
        key,
        error,
      },
    });
  }
}

export default function* root() {
  yield takeLatest(NAMESPACE + GET_COMPANIES, fetchCompanies);
}
