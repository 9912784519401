/* eslint-disable no-underscore-dangle */

import Vue from 'vue';

import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

import { v4 as uuidv4 } from 'uuid';

interface VueElement extends Element {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  __vue__?: any;
}

// eslint-disable-next-line import/prefer-default-export
export const sessionId = uuidv4();

(() => {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }

  const findInstance = (el: VueElement): Vue|null => {
    for (let parent = el; parent; parent = parent.parentNode as VueElement) {
      if (parent.__vue__) {
        return parent.__vue__;
      }
    }

    return null;
  };

  const findHierarchy = (ctx: Vue): string[] => {
    const path = [];

    for (let vm = ctx; vm !== vm.$root; vm = vm.$parent) {
      const name = (vm.$vnode.componentOptions && vm.$vnode.componentOptions.tag) || vm.$vnode.tag || '';
      path.push(name.replace('vue-component-', ''));
    }

    return path.reverse();
  };

  Sentry.init({
    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT || process.env.NODE_ENV,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new Integrations.Vue({
        Vue,
        attachProps: true,
      }),
      new Integrations.ExtraErrorData({
        depth: 8,
      }),
    ],

    /**
     * Adds additional debug data to clicks.
     * e.g.
     * vue-component-13 -> vue-component-14-ModernLayout -> quote-state-container ->
     * vue-component-18-BuInput -> input-page -> VnVpBox -> BFormGroup -> ClientBox"
     *
     * @param ctx
     * @param hint
     */
    beforeBreadcrumb(ctx, hint) {
      if (hint === undefined || ctx.category !== 'ui.click') {
        return ctx;
      }

      const { target = {} } = hint.event;
      const instance = findInstance(target);

      if (instance === null) {
        return ctx;
      }

      const hierarchy = findHierarchy(instance);
      ctx.message = `${hierarchy.join(' > ')}: ${ctx.message}`;

      return ctx;
    },
  });

  // Add custom session ID for easier searching even if the user has not been authenticated yet
  Sentry.setTag('session', sessionId);
})();
