// @ts-ignore
import comparison from './comparison';

// @ts-ignore
import completion from './completion';

// @ts-ignore
import contract from './contract';

// @ts-ignore
import input from './input';

// @ts-ignore
import offers from './offers';

export enum Step {
  Comparison = 'comparison',
  Completion = 'completion',
  Contract = 'contract',
  Input = 'input',
  Offers = 'offers',
}

export enum ExtraStep {
  SelectChannel = 'select-channel',
  SelectPool = 'select-pool',
}

/**
 * Represents a list of valid wizard steps that a product can go through.
 * Each step contains their respective default config for the router.
 */
export default {
  // WARNING: Do not change the order of this in any way,
  // they're ordered by their appearance in the wizard
  [Step.Input]: input,
  [Step.Offers]: offers,
  [Step.Comparison]: comparison,
  [Step.Contract]: contract,
  [Step.Completion]: completion,
};
