import isFunction from 'lodash/isFunction';
import reduce from 'lodash/reduce';

/**
 * Maps the given array to an object using the given field for the keys.
 *
 * @param {Array} array
 * @param {string|function} key
 * @return {Object}
 */
export default (array, key) => {
  const func = isFunction(key);

  return reduce(array, (ctx, element) => {
    ctx[func ? key(element) : element[key]] = element;
    return ctx;
  }, {});
};
