import mutations from './mutations';
import sagas from './sagas';
import { ApiResource } from '@/types';
import { UserSettings } from '@/store/modules/userSettings/UserSettings';
import getters from '@/store/modules/userSettings/getters';

export const NAMESPACE = 'userSettings/';

export interface UserSettingsResource extends ApiResource<UserSettings> {
  isChanged?: Boolean;
}

export default {
  namespaced: true,

  mutations,
  sagas,

  getters,

  state: {
    isLoading: false,
    error: null,
    data: {},
    isChanged: false,
  },
};
