class PreventExitEventHandler {
  constructor() {
    this.handler = (e) => {
      e.preventDefault();
      e.returnValue = ''; // Chrome requires returnValue to be set.
    };
    this.attached = false;
  }

  /**
   * Enable the event listener
   */
  enable() {
    if (this.attached === false) {
      window.addEventListener('beforeunload', this.handler);
      this.attached = true;
    }
  }

  /**
   * Disable the event listener
   */
  disable() {
    if (this.attached === true) {
      window.removeEventListener('beforeunload', this.handler);
      this.attached = false;
    }
  }
}

export default (new PreventExitEventHandler());
