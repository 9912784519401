import { Role } from '@/store/modules/client/roles';
import { ProductConfig } from '@/types';
// @ts-ignore
import image from './assets/bg.jpg';
import { filterItemsByMetaData } from '@/products/phv/utils/featureFilter';

const config: ProductConfig = {
  path: '/phv',
  component: () => import('@/views/layout/BaseLayout.vue'),
  // Config
  props: {
    name: 'Privathaftpflicht',
    heading: 'Privathaftpflicht',
    product: 'phv',
    icon: 'home',
    image,

    // distribution settings
    shouldChannel: true,
    shouldPool: true,

    // how many request to send to backend for offers
    numChunks: 3,

    // which client data need to be send to the backend
    clientData: {
      [Role.VersichertePerson]: {
        'clientDaten.versichertePerson.geburtsdatum': 'birthDate',
        'clientDaten.versichertePerson.plz': 'address.zipCode',
      },
      [Role.Versicherungsnehmer]: {
        'clientDaten.versicherungsnehmer.geburtsdatum': 'birthDate',
        'clientDaten.versicherungsnehmer.plz': 'address.zipCode',
      },
    },

    // role settings
    productSyncRoleSettings: {
      [Role.Versicherungsnehmer]: [Role.VersichertePerson, Role.Zahlperson],
      [Role.VersichertePerson]: [Role.Versicherungsnehmer, Role.Zahlperson],
    },

    profileFields: [
      'eingabedaten.deckungssumme',
      'eingabedaten.selbstbeteiligung',
      'eingabedaten.tarifart',
    ],

    keepInputFromQuotes: [
      'birthDate',
      'address.zipCode',
    ],

    defaults: {
      contract: () => ({
        antragAbgelehnt: false,
        zahlart: null,
        memofeld: '',
        vorversicherungsDaten: {
          gesellschaft: null,
          versicherungsnummer: '',
          kuendigender: null,
          kuendigen: false,
          beginn: '',
          ablauf: '',
        },
        vorschaden: {
          bestehenAnsprueche: false,
          memofeldAnsprueche: '',
          entschaedigungszahlung: null,
        },
      }),
    },

    offerDetail: () => import('./views/offerdetail.vue'),

    featureFilter: (store: any, features: any): any => {
      const { product } = store.state;
      const { eingabedaten } = store.state.input[product].data;

      return filterItemsByMetaData(features, eingabedaten);
    },
  },

  // Wizard steps
  steps: {
    input: {
      component: () => import('./views/input.vue'),
    },
    offers: {
      component: () => import('./views/offers.vue'),
    },
    comparison: {
      component: () => import('./views/comparison.vue'),
      meta: {
        showContractButton: false,
      },
    },
    contract: {
      component: () => import('./views/contract.vue'),
    },
    completion: {
      component: () => import('@/views/pages/completion/type/Property.vue'),
    },
  },
};

export default config;
