import reduce from 'lodash/reduce';
import mapKeys from 'lodash/mapKeys';
import isObject from 'lodash/isObject';

/**
 * Flattens the given object using a dot-notation (readable by lodash).
 *
 * @param {Object} obj
 * @return {Object}
 */
const flattenObject = (obj, ignoreArrays = false) => reduce(obj, (acc, val, key) => {
  if (!isObject(val) || (ignoreArrays && val instanceof Array)) {
    acc[key] = val;
    return acc;
  }

  return { ...acc, ...mapKeys(flattenObject(val, ignoreArrays), (_, child) => `${key}.${child}`) };
}, {});

export default flattenObject;
