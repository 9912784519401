import { Product, ProductConfig } from '@/types';
import bu from './bu';
import hau from './hau';
import hun from './hun';
import phv from './phv';
import rlv from './rlv';
import woh from './woh';
import pkv from './pkv';
import res from './res';
// import pfe from './pfe';

// Register our custom products here
const productModules: { [key in Product]: ProductConfig} = {
  bu,
  hau,
  // pfe,
  hun,
  phv,
  rlv,
  woh,
  pkv,
  res,
};

export default productModules;
