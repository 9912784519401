import createPerProductState from '@/utils/store/createPerProductState';

import mutations from './mutations';
import getters from './getters';
import sagas from './sagas';

export const NAMESPACE = 'offers/questions/';

export default {
  namespaced: true,

  mutations,
  getters,
  sagas,

  state: createPerProductState(() => ({})),
};
