import orderBy from 'lodash/orderBy';
import sumBy from 'lodash/sumBy';

export default {
  orderedDocuments(offers) {
    return (identifier) => orderBy(offers[identifier].dokumente, ['name'], ['asc']);
  },

  selectedDocumentsNumber(offers) {
    return (identifier) => [
      sumBy(offers[identifier].dokumente, ({ checked }) => (checked ? 1 : 0)),
      offers[identifier].dokumente.length,
    ];
  },
};
