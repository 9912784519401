import Vue from 'vue';
import findIndex from 'lodash/findIndex';

import { ApiResource } from '@/types';
import Company, { SpecialCompany } from './Company';
import { UPDATE_COMPANY, UpdateCompany } from './actions/updateCompany';

export const GET_COMPANIES = 'getCompanies';
export const GET_COMPANIES_SUCCESS = 'getCompaniesSuccess';
export const GET_COMPANIES_ERROR = 'getCompaniesError';

export const ADD_COMPANY = 'addCompany';

export const UPDATE_LAST_REQUEST_HASH = 'updateLastRequestHash';

export const GET_SPECIAL_COMPANIES = 'getSpecialCompanies';
export const GET_SPECIAL_COMPANIES_SUCCESS = 'getSpecialCompaniesSuccess';
export const GET_SPECIAL_COMPANIES_ERROR = 'getSpecialCompaniesError';
export const SET_BLACKLIST = 'setBlacklist';

type State = {
  [p: string]: ApiResource<Company[]>&{
    specialCompanies: ApiResource<SpecialCompany>;
    lastRequestHash: number;
  };
}

export default {
  [GET_COMPANIES](state: State, { product }: any) {
    state[product].isLoading = true;
  },

  [GET_COMPANIES_SUCCESS](state: State, { product, data }: any) {
    state[product].isLoading = false;
    state[product].data = data.map((company: Company) => ({
      ...company,
      isBlacklisted: false, // for white label we only have session based blacklists
    }));
  },

  [GET_COMPANIES_ERROR](state: State, { product, error }: any) {
    state[product].isLoading = false;
    state[product].error = error;
  },

  [ADD_COMPANY](state: State, { product, company }: { product: string; company: Company }) {
    state[product].data.push(company);
  },

  [UPDATE_COMPANY](state: State, payload: UpdateCompany): void {
    const companies = state[payload.product].data;
    const idx = findIndex(companies, (candidate) => (
      candidate.insuranceId === payload.insuranceId
    ));

    if (idx >= 0) {
      Vue.set(companies, idx, { ...companies[idx], ...payload.data });
    }
  },

  [UPDATE_LAST_REQUEST_HASH](state: State, payload: { product: string; hash: number }): void {
    state[payload.product].lastRequestHash = payload.hash;
  },

  [GET_SPECIAL_COMPANIES](state: State, { product }: any) {
    state[product].specialCompanies.isLoading = true;
  },

  [GET_SPECIAL_COMPANIES_SUCCESS](state: State, { product, data }: any) {
    state[product].specialCompanies.isLoading = false;
    state[product].specialCompanies.data = data;
  },

  [GET_SPECIAL_COMPANIES_ERROR](state: State, { product, error }: any) {
    state[product].specialCompanies.isLoading = false;
    state[product].specialCompanies.error = error;
  },

  [SET_BLACKLIST](state: State, { product, blacklistedCompanies }:
    { product: string; blacklistedCompanies: number[] }) {
    state[product].data?.forEach((company) => {
      if (blacklistedCompanies.includes(company.insuranceId)) {
        // eslint-disable-next-line no-param-reassign
        company.isBlacklisted = true;
      }
    });
  },
};
