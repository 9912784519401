import mutations from './mutations';
import getters from './getters';
import sagas from './sagas';

export default {
  mutations,
  getters,
  sagas,

  state: {
    product: null,
    productStatus: false,
  },
};
