export enum DescriptionModuleTypes {
  STATIONAER = 'Stationaer',
  AMBULANT = 'Ambulant',
  KOMBI = 'Ambulant,Stationaer',
  ZAHN = 'Zahn',
}

export enum InsuranceType {
  VOLL = 'Vollversicherung',
  ZUSATZ = 'Zusatzversicherung'
}

export enum contributionReliefType {
  ABSOLUTE = 'ABSOLUTE',
  PERCENT = 'PERCENT',
}
