import Vue from 'vue';
import { resourceContainer } from '@/utils/store/createPerProductState';

export const GET_CHOICES = 'get';
export const GET_CHOICES_SUCCESS = 'getSuccess';
export const GET_CHOICES_ERROR = 'getError';

export default {
  [GET_CHOICES](state, key) {
    if (state[key] !== undefined) {
      state[key].isLoading = true;
      return;
    }

    // Fix reactivity issues by directly setting the value
    Vue.set(state, key, {
      ...resourceContainer(),
      isLoading: true,
    });
  },

  [GET_CHOICES_SUCCESS](state, { key, data }) {
    state[key].isLoading = false;
    state[key].data = data;
    state[key].error = null;
  },

  [GET_CHOICES_ERROR](state, { key, error }) {
    state[key].isLoading = false;
    state[key].error = error;
  },
};
