import cloneDeep from 'lodash/cloneDeep';
import map from 'lodash/map';
import Vue from 'vue';

import { resourceContainer } from '@/utils/store/createPerProductState';

import initialState from './state';
import { ORDER_ASC, ORDER_DESC } from './constants';

import {
  GET_OFFERS,
  GET_OFFERS_BY_COMPANY,
  GET_OFFERS_BY_COMPANY_ERROR,
  GET_OFFERS_BY_COMPANY_SUCCESS,
} from './actions/offersByCompany';

export const GET_FULFILLMENT = 'getFulfillmentDegree';

export const SET_RECOMMENDED = 'setRecommended';

export const ADD_SELECTION = 'addSelection';
export const REMOVE_SELECTION = 'removeSelection';
export const RESET_SELECTION = 'resetSelection';
export const TOGGLE_SELECTION = 'toggleSelection';

export const SET_REQUEST_FILTER = 'setRequestFilter';

export const SET_SORTING = 'setSorting';

export const TOGGLE_SORTING_ORDER = 'toggleSortingOrder';
export const UPDATE_SORTING_KEY = 'updateSortingKey';

export const SET_SEARCH_STRINGS = 'setFilter';

export const SET_REQUEST_HASH = 'setRequestHash';

export const SET_ANSWER = 'setAnswer';
export const SET_ANSWER_KEYS = 'setAnswerKeys';

export const SET_OFFERS_WEBREPORT = 'setOffersWebreport';

export const GET_ALL_TIME_TARIFF = 'getAllTimeTariff';
export const GET_ALL_TIME_TARIFF_SUCCESS = 'getAllTimeTariffSuccess';
export const GET_ALL_TIME_TARIFF_ERROR = 'getAllTimeTariffError';

export const SET_VORVERSICHERUNG = 'setVorversicherung';

export default {
  [SET_REQUEST_HASH](state, { hash }) {
    state.lastRequestHash = hash;
  },

  [GET_FULFILLMENT](state, ctx) {
    ctx.erfuellungsgrad.isLoading = true;
  },

  [GET_OFFERS](state, { append } = {}) {
    if (append) {
      return;
    }

    state.data = cloneDeep(initialState.data);
    state.chunks = {};
  },

  [GET_OFFERS_BY_COMPANY](state, { id }) {
    Vue.set(state.chunks, id, resourceContainer());
    state.chunks[id].isLoading = true;
  },

  [GET_OFFERS_BY_COMPANY_SUCCESS](state, { id, data }) {
    state.remoteHash = data.id;
    state.links = data.links;

    // Since the backend cannot differentiate between the different companies
    // regarding any potential errors yet, we simply keep them global and
    // use the ones that came last. That way we continuously update them
    // as well as the normal offer list - which we do keep separated.
    if (data.requestTime > state.lastRequestTime) {
      state.lastRequestTime = data.requestTime;

      state.data.fehler = data.fehler;

      const mergedUniqHinweise = data.hinweise.reduce((acc, hinweisObj) => {
        if (!acc[hinweisObj.anbieter]) {
          acc[hinweisObj.anbieter] = {};
          acc[hinweisObj.anbieter].anbieter = hinweisObj.anbieter;
          acc[hinweisObj.anbieter].fachlicheFehler = new Set(hinweisObj.fachlicheFehler);
          acc[hinweisObj.anbieter].technischeFehler = new Set(hinweisObj.technischeFehler);
        } else {
          acc[hinweisObj.anbieter].fachlicheFehler = new Set([
            ...acc[hinweisObj.anbieter].fachlicheFehler,
            ...hinweisObj.fachlicheFehler,
          ]);
          acc[hinweisObj.anbieter].technischeFehler = new Set([
            ...acc[hinweisObj.anbieter].technischeFehler,
            ...hinweisObj.technischeFehler,
          ]);
        }
        return acc;
      }, {});

      state.data.hinweise = Object.entries(mergedUniqHinweise).map((val) => ({
        anbieter: val[1].anbieter,
        fachlicheFehler: Array.from(val[1].fachlicheFehler),
        technischeFehler: Array.from(val[1].technischeFehler),
      }));
      state.data.nichtBerechneteTarife = data.nichtBerechneteTarife;
    }

    state.chunks[id].isLoading = false;
    state.chunks[id].data = {
      tariffehler: data.tariffehler,
      tarife: map(data.tarife, (tarif) => ({
        ...tarif,
        erfuellungsgrad: {
          ...tarif.erfuellungsgrad,
          isLoading: tarif.erfuellungsgrad.wert === null,
          error: null,
        },
      })),
    };
  },

  [GET_OFFERS_BY_COMPANY_ERROR](state, { id, error }) {
    state.chunks[id].isLoading = false;
    state.chunks[id].error = Object.freeze(error);
  },

  [SET_RECOMMENDED](state, offer) {
    state.recommended = offer ? offer.identifier : null;
  },

  [TOGGLE_SELECTION](state, { identifier }) {
    const index = state.selected.indexOf(identifier);

    if (index >= 0) {
      state.selected.splice(index, 1);

      if (state.recommended === identifier) {
        state.recommended = null;
      }

      return;
    }

    state.select(identifier);
  },

  [ADD_SELECTION](state, { identifier }) {
    state.selected.push(identifier);

    // Remove all "overflow" elements from the stack
    while (state.selected.length > state.maxSelectionCount) {
      if (state.recommended === state.selected.shift()) {
        state.recommended = null;
      }
    }
  },

  [REMOVE_SELECTION](state, { identifier }) {
    const index = state.selected.indexOf(identifier);
    if (index < 0) {
      return;
    }

    state.selected.splice(index, 1);

    if (state.recommended === identifier) {
      state.recommended = null;
    }
  },

  [RESET_SELECTION](state) {
    state.selected = [];
    state.recommended = null;
  },

  [SET_REQUEST_FILTER](state, { key, value }) {
    state.filters[key] = value;
  },

  [SET_SEARCH_STRINGS](state, filter) {
    state.searchStrings = filter;
  },

  [SET_SORTING](state, sorting) {
    state.sorting = cloneDeep(sorting);
  },

  [TOGGLE_SORTING_ORDER](state, { index }) {
    const item = state.sorting[index];
    item.order = item.order === ORDER_ASC ? ORDER_DESC : ORDER_ASC;
    state.sorting.splice(index, 1, item);
  },

  [UPDATE_SORTING_KEY](state, { index, key, order }) {
    const item = state.sorting[index];
    item.key = key;
    item.order = order === undefined ? item.order : order;
    state.sorting.splice(index, 1, item);
  },

  [SET_ANSWER](state, { product, key, value }) {
    state.answers[product][key] = value;
  },

  [SET_ANSWER_KEYS](state, { product, answerKeys }) {
    state.answers[product] = { ...answerKeys, ...state.answers[product] };
  },

  [SET_OFFERS_WEBREPORT](state, tarife) {
    const data = {};
    for (let i = 0; i < tarife.length; i += 1) {
      const { id } = tarife[i].gesellschaft;
      if (data[id]) {
        data[id].data.tarife.push(tarife[i]);
      } else {
        data[id] = {
          data: {
            tarife: [tarife[i]],
          },
        };
      }
    }
    state.chunks = data;
  },

  [GET_ALL_TIME_TARIFF](state) {
    state.allTimeTariffs.isLoading = true;
  },

  [GET_ALL_TIME_TARIFF_SUCCESS](state, data) {
    state.allTimeTariffs.data = data;
    state.allTimeTariffs.isLoading = false;
  },

  [GET_ALL_TIME_TARIFF_ERROR](state, error) {
    state.allTimeTariffs.error = error;
    state.allTimeTariffs.isLoading = false;
  },

  [SET_VORVERSICHERUNG](state, data) {
    state.vorversicherung.data = map(data, (element) => ({
      ...element, disableContract: true,
    }));
  },
};
