import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import sagas from './sagas';

export default {
  actions,
  getters,
  mutations,
  sagas,

  state: {
    // Contains a per offer object
  },
};
