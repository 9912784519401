import cloneDeep from 'lodash/cloneDeep';
import deepFreeze from '@/utils/deepFreeze';
import Client from './Client.interface';
import schema from '@/store/modules/client/schema';

const state: Client = schema.getDefault();

export const defaults = deepFreeze(cloneDeep(state));

export default state;
