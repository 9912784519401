export const GET_FILES = 'getFiles';
export const GET_FILES_SUCCESS = 'getFilesSuccess';
export const GET_FILES_ERROR = 'getFilesError';

export default {
  [GET_FILES](state) {
    state.isLoading = true;
  },

  [GET_FILES_SUCCESS](state, { data }) {
    state.isLoading = false;
    state.error = null;
    state.data = data;
  },

  [GET_FILES_ERROR](state, { error }) {
    state.isLoading = false;
    state.error = error;
  },
};
