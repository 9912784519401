<template functional>
  <div
    :class="[data.class, data.staticClass]"
    class="my-5"
  >
    <div class="mb-3">
      <slot name="heading">
        <h5 v-text="props.label" />
      </slot>
    </div>
    <div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
  },
};
</script>
