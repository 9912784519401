import find from 'lodash/find';
import { resourceContainer } from '@/utils/store/createPerProductState';

export const CLEAR_FEATURES = 'clearFeatures';

export const GET_FEATURES = 'getFeatures';
export const GET_FEATURES_SUCCESS = 'getFeaturesSuccess';
export const GET_FEATURES_ERROR = 'getFeaturesError';
export const SET_FEATURES_WEBREPORT = 'setFeaturesWebreport';

const findEntry = (state, key) => find(state.entries, ['key', key]);

export default {
  [CLEAR_FEATURES](state) {
    state.entries = [];
  },

  [GET_FEATURES](state, key) {
    // Find existing entry for the offer and reload their data
    let entry = findEntry(state, key);

    if (entry === undefined) {
      entry = {
        ...resourceContainer(),
        key,
      };

      state.entries.push(entry);
    }

    // Indicate loading state
    entry.isLoading = true;

    // Clear the queue
    if (state.entries.length > state.limit) {
      state.entries.shift();
    }
  },

  [GET_FEATURES_SUCCESS](state, { key, data }) {
    const entry = findEntry(state, key);

    if (entry === undefined) {
      return;
    }

    entry.isLoading = false;
    entry.data = data;
  },

  [GET_FEATURES_ERROR](state, { key, error }) {
    const entry = findEntry(state, key);

    if (entry === undefined) {
      return;
    }

    entry.isLoading = false;
    entry.error = error;
  },

  [SET_FEATURES_WEBREPORT](state, { key, data }) {
    state.entries.push({
      data,
      key,
    });
  },
};
