import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import keys from 'lodash/keys';
import map from 'lodash/map';
import difference from '@/utils/difference';
import flattenObject from '@/utils/flattenObject';

import api from '@/store/api';
import { resourceContainer } from '@/utils/store/createPerProductState';

import {
  call, put, select, takeEvery, takeLatest, delay,
} from 'redux-saga/effects';

import products from '@/products';
import waitForProduct from '@/store/effects/waitForProduct';

import { NAMESPACE as AUTH_NAMESPACE } from '@/store/modules/auth';
import { SET_USER_SUCCESS } from '@/store/modules/auth/mutations';

import { NAMESPACE as INPUT_NAMESPACE } from '@/store/modules/input';
import { SET_INPUT_FIELD } from '@/store/modules/input/mutations';

import { NAMESPACE } from './index';
import {
  CREATE_PROFILE,
  CREATE_PROFILE_ERROR,
  CREATE_PROFILE_SUCCESS,
  DELETE_PROFILE,
  DELETE_PROFILE_ERROR,
  DELETE_PROFILE_SUCCESS,
  GET_PROFILES,
  GET_PROFILES_ERROR,
  GET_PROFILES_SUCCESS,
  SET_ACTIVE,
  SET_BY_ID,
  UPDATE_PROFILE,
  UPDATE_PROFILE_ERROR,
  UPDATE_PROFILE_SUCCESS,
} from './mutations';

import { NAMESPACE as FEATURE_NAMESPACE } from '@/store/modules/features';
import { SET_FEATURE_VALUE } from '@/store/modules/features/mutations';
import { byKey } from '@/store/modules/features/getters';

/**
 * Picks all persistable fields from the given profile.
 *
 * @param {Boolean} active
 * @param {Object} content
 * @param {String} title
 * @return {{title: String, active: Boolean, content: Object}}
 */
const pickPersistable = ({ active, content, title }) => ({ active, content, title });

/**
 * Loads all products for the given product category.
 *
 * @param {{ product: string }} payload
 * @return {IterableIterator<*>}
 */
export function* loadAll({ payload: product }) {
  try {
    const { data } = yield call(api, 'get', `${product}/profiles`);

    // Wrap each profile in another resource for easier model updates
    const profiles = map(data.profiles, (profile) => ({
      ...resourceContainer(),
      data: profile,
    }));

    yield put({
      type: NAMESPACE + GET_PROFILES_SUCCESS,
      payload: { product, data: profiles },
    });
  } catch (error) {
    yield put({
      type: NAMESPACE + GET_PROFILES_ERROR,
      payload: { product, error },
    });
  }
}

/**
 * When we change the product, this loads the respective profile list,
 * if we haven't done so during the current session.
 *
 * @return {IterableIterator<*>}
 */
export function* loadAllIfEmpty() {
  const product = yield call(waitForProduct);
  const { data = [] } = yield select((store) => store.profiles[product].list);

  if (data.length < 1) {
    yield put({ type: NAMESPACE + GET_PROFILES, payload: product });
  }
}

/**
 * Creates the given profile for later use.
 *
 * @param {{ product: string, data: Object }} payload
 * @return {IterableIterator<*>}
 */
export function* storeProfile({ payload }) {
  const { product, data: profile } = payload;

  try {
    const { data } = yield call(api, 'post', `${product}/profiles`, pickPersistable(profile.data));

    yield put({
      type: NAMESPACE + CREATE_PROFILE_SUCCESS,
      payload: {
        product,
        profile,
        data: {
          id: data.profile.id,
          userId: data.profile.userId,
        },
      },
    });
  } catch (error) {
    yield put({
      type: NAMESPACE + CREATE_PROFILE_ERROR,
      payload: { product, profile, error },
    });
  }
}

/**
 * Remotely saves any (relevant) local changes in the given profile.
 *
 * @param {{ product: string, profile: Object, data: Object }} payload
 * @return {IterableIterator<*>}
 */
export function* updateProfile({ payload }) {
  const { product, profile, data } = payload;

  // Early exit
  if (isEmpty(data)) {
    return;
  }

  // Grab all differences that we have to update
  const changes = difference(
    pickPersistable(profile.data),
    pickPersistable(data),
  );

  try {
    if (!isEmpty(changes)) {
      yield call(api, 'put', `${product}/profiles/${profile.data.id}`, changes);
    }

    // Always persist any requested changes
    yield put({
      type: NAMESPACE + UPDATE_PROFILE_SUCCESS,
      payload: { product, profile, data },
    });
  } catch (error) {
    yield put({
      type: NAMESPACE + UPDATE_PROFILE_ERROR,
      payload: { product, profile, error },
    });
  }
}

/**
 * Loads any additional / missing data for the given profile.
 *
 * @param {{ product: string, data: Object }} payload
 * @return {IterableIterator<*>}
 */
export function* loadProfile({ payload }) {
  const { product, data: profile } = payload;

  if (profile.data.id === null || !isEmpty(profile.data.content)) {
    return;
  }

  // Mark the profile as "updating"
  yield put({
    type: NAMESPACE + UPDATE_PROFILE,
    payload: { product, profile },
  });

  try {
    const { data } = yield call(api, 'get', `${product}/profiles/${profile.data.id}`);

    yield put({
      type: NAMESPACE + UPDATE_PROFILE_SUCCESS,
      payload: { product, profile, data: data.profile },
    });
  } catch (error) {
    yield put({
      type: NAMESPACE + UPDATE_PROFILE_ERROR,
      payload: { product, profile, error },
    });
  }
}

/**
 * Deletes the given profile if it exists.
 *
 * @param {{ product: string, data: Object }} payload
 * @return {IterableIterator<*>}
 */
export function* deleteProfile({ payload }) {
  const { product, profile } = payload;

  if (profile.data.id === null) {
    return;
  }

  try {
    yield call(api, 'delete', `${product}/profiles/${profile.data.id}`);

    yield put({
      type: NAMESPACE + DELETE_PROFILE_SUCCESS,
      payload: { product, profile },
    });
  } catch (error) {
    yield put({
      type: NAMESPACE + DELETE_PROFILE_ERROR,
      payload: { product, profile, error },
    });
  }
}

/**
 * Fills in all input fields based on the profile content.
 *
 * @param payload
 * @return {IterableIterator<*>}
 */
export function* fillProfileFields({ payload }) {
  const { product, data: profile } = payload;

  if (!profile) {
    return;
  }

  // Since we're listening to two different mutations
  // we have to match them both in their payload
  const { content } = profile.data || profile;
  if (isEmpty(content)) {
    return;
  }

  // Do an update per field, as that's easier
  const { profileFields = [] } = products[product].props;
  const { merkmale = {}, ...input } = content;
  const flattened = flattenObject(input, true);
  const fields = keys(flattened);

  for (let i = 0; i < fields.length; i += 1) {
    const field = fields[i];

    if (profileFields.includes(field)) {
      yield put({
        type: INPUT_NAMESPACE + SET_INPUT_FIELD,
        payload: {
          field: fields[i],
          value: get(flattened, field),
          product,
        },
      });
    }
  }

  const featuresByKey = byKey(yield select((state) => state.features));
  const identifiers = keys(merkmale);

  for (let i = 0; i < identifiers.length; i += 1) {
    yield put({
      type: FEATURE_NAMESPACE + SET_FEATURE_VALUE,
      payload: {
        feature: featuresByKey[product][identifiers[i]],
        value: merkmale[identifiers[i]],
      },
    });
  }
}

function* setProfileFromId({ payload }) {
  const { product, id } = payload;
  let setProfil = false;
  let tryCount = 0;

  // we stop trying after 5 seconds as it seems user got no profiles
  while (!setProfil && tryCount < 20) {
    const { list } = yield select((store) => store.profiles[product]);

    if (list.data.length === 0 || list.isLoading) {
      yield delay(250 * 1);
      tryCount += 1;
    } else {
      const foundProfile = list.data.find((profile) => profile.data.id === id);

      if (foundProfile) {
        yield put({
          type: NAMESPACE + SET_ACTIVE,
          payload: {
            product,
            data: foundProfile,
          },
        });
      }

      setProfil = true;
    }
  }
}

export default function* root() {
  yield takeLatest(NAMESPACE + GET_PROFILES, loadAll);
  yield takeLatest(NAMESPACE + CREATE_PROFILE, storeProfile);
  yield takeLatest(NAMESPACE + SET_ACTIVE, loadProfile);
  yield takeLatest(NAMESPACE + UPDATE_PROFILE, updateProfile);
  yield takeLatest(NAMESPACE + DELETE_PROFILE, deleteProfile);
  yield takeLatest(NAMESPACE + SET_BY_ID, setProfileFromId);

  yield takeLatest(AUTH_NAMESPACE + SET_USER_SUCCESS, loadAllIfEmpty);

  yield takeEvery([
    NAMESPACE + SET_ACTIVE,
    NAMESPACE + UPDATE_PROFILE_SUCCESS,
  ], fillProfileFields);
}
