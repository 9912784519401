import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import sagas from './sagas';

// cannot get namespace from parent due to cyclical dependency
export const NAMESPACE = 'choices/complex/';

export default {
  namespaced: true,

  actions,
  getters,
  mutations,
  sagas,

  state: {
    companies: {
      // Will be filled with the choices keys mapped to a resource container
    },
  },
};
