import { resourceContainer } from '@/utils/store/createPerProductState';
import { TYPE_DEFAULT } from './constants';

export const UPSERT_QUOTE = 'upsertQuote';
export const UPSERT_QUOTE_SUCCESS = 'upsertQuoteSuccess';
export const UPSERT_QUOTE_ERROR = 'upsertQuoteError';

export const GET_QUOTE_BY_ID = 'getQuoteById';
export const GET_QUOTE_BY_ID_SUCCESS = 'getQuoteByIdSuccess';
export const GET_QUOTE_BY_ID_ERROR = 'getQuoteByIdError';

export const SAVE_BESTANDSVERTRAG = 'saveBestandsvertrag';
export const SAVE_BESTANDSVERTRAG_SUCCESS = 'saveBestandsvertragSuccess';
export const SAVE_BESTANDSVERTRAG_ERROR = 'saveBestandsvertragError';

export const SET_QUOTE = 'setQuote';
export const RESET_QUOTE = 'resetQuote';

export default {
  [UPSERT_QUOTE](state, { product }) {
    state[product].isLoading = true;
    state[product].showContent = true;
  },

  [UPSERT_QUOTE_SUCCESS](state, { product, data }) {
    state[product].isLoading = false;
    state[product].data = {
      id: data.id,
      title: data.title,
    };
  },

  [UPSERT_QUOTE_ERROR](state, { product, error }) {
    state[product].isLoading = false;
    state[product].error = error;
  },

  [GET_QUOTE_BY_ID](state, { product, type = TYPE_DEFAULT }) {
    state[product].isLoading = true;
    state[product].showContent = false;
    state[product].type = type;
  },

  [GET_QUOTE_BY_ID_SUCCESS](state, { product, data }) {
    state[product].isLoading = false;
    state[product].data = {
      clientId: data.clientId,
      id: data.id,
      title: data.title,
      offers: ((data.content.vergleich || {}).vergleichGroup || {}),
    };
  },

  [GET_QUOTE_BY_ID_ERROR](state, { product, error }) {
    state[product].isLoading = false;
    state[product].error = error;
  },

  [SET_QUOTE](state, { product, data }) {
    state[product].data = data;
  },

  [RESET_QUOTE](state, { product }) {
    state[product] = {
      ...resourceContainer(),
    };
  },

  [SAVE_BESTANDSVERTRAG](state) {
    state.bestandsvertrag.isLoading = true;
    state.bestandsvertrag.error = null;
  },

  [SAVE_BESTANDSVERTRAG_SUCCESS](state, { data }) {
    state.bestandsvertrag.isLoading = false;
    state.bestandsvertrag.data = data;
  },

  [SAVE_BESTANDSVERTRAG_ERROR](state, { error }) {
    state.bestandsvertrag.isLoading = false;
    state.bestandsvertrag.error = error;
  },
};
