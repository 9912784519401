import { NavigationGuard, Route } from 'vue-router';
import { map } from 'lodash';
import routeName from '@/router/utils/routeName';
// @ts-ignore
import store from '@/store';
// @ts-ignore
import sortables from '@/store/modules/offers/sortables';

import { SET_PRODUCT } from '@/store/modules/global/mutations';
// @ts-ignore
import { NAMESPACE as AUTH_NAMESPACE } from '@/store/modules/auth';
// @ts-ignore
import { GET_USER } from '@/store/modules/auth/mutations';
// @ts-ignore
import { NAMESPACE as OFFER_NAMESPACE } from '@/store/modules/offers';
// @ts-ignore
import { SET_SORTING } from '@/store/modules/offers/mutations';
// @ts-ignore
import { NAMESPACE as QUOTE_NAMESPACE } from '@/store/modules/quote';
// @ts-ignore
import { TYPE_BESTANDSVERTRAG } from '@/store/modules/quote/constants';
// @ts-ignore
import { GET_QUOTE_BY_ID } from '@/store/modules/quote/mutations';
// @ts-ignore
import { NAMESPACE as PROFILE_NAMESPACE } from '@/store/modules/profiles';
// @ts-ignore
import { SET_BY_ID } from '@/store/modules/profiles/mutations';
// @ts-ignore
import { NAMESPACE as CLIENT_NAMESPACE } from '@/store/modules/client';
// @ts-ignore
import { GET_CLIENT_BY_ID } from '@/store/modules/client/mutations';
// @ts-ignore
import { ROLE_VERSICHERUNGSNEHMER } from '@/store/modules/client/roles';
// @ts-ignore
import analytics from '@/utils/analytics';
import refreshSession from '@/utils/refreshSession';
import loadClientsFromQuote from '@/router/utils/loadClientsFromQuote';
import { ProductConfig } from '@/types';
import { Step } from './steps';

const loggedInToken = '_session';

interface Guards {
  beforeModule: (module: ProductConfig) => NavigationGuard;
  afterEach: (to: Route, from?: Route) => any;
}

const guards: Guards = {
  /**
   * Sets the current product when we change module pages.
   *
   * @param module
   * @return {Function}
   */
  beforeModule: (module) => (to, from, next) => {
    if (to.query[loggedInToken] === undefined) {
      refreshSession(module.props.product, `${loggedInToken}=1`);
      next({ name: 'login' });
      return;
    }

    store.commit(SET_PRODUCT, module.props.product);
    store.commit(AUTH_NAMESPACE + GET_USER, module.props.product);
    if (module.props.defaultSorting) {
      const sort = map(module.props.defaultSorting, (key) => (
        { key, order: sortables[key as keyof typeof sortables].order }
      ));
      store.commit(OFFER_NAMESPACE + SET_SORTING, sort);
    }

    // Always make the input page the first thing we see
    const inputPage = routeName(Step.Input);

    if (from.path !== '/' || to.name === inputPage) {
      next();
      return;
    }

    next({
      name: inputPage,
      query: to.query,
      params: to.params,
    });
  },

  /**
   * Global guard to load stuff from our query parameters.
   *
   * @param to
   */
  afterEach: (to) => {
    analytics(['setCustomUrl', document.location.origin + to.fullPath]);
    analytics(['setDocumentTitle', to.path]);
    analytics(['trackPageView']);

    const {
      angebotId,
      bestandsvertragId,
      clientId,
      profileId,
    } = to.query;

    if (bestandsvertragId) {
      store.commit(QUOTE_NAMESPACE + GET_QUOTE_BY_ID, {
        product: store.state.product,
        type: TYPE_BESTANDSVERTRAG,
        id: bestandsvertragId,
        onSuccess: loadClientsFromQuote,
      });
    } else if (angebotId) {
      store.commit(QUOTE_NAMESPACE + GET_QUOTE_BY_ID, {
        product: store.state.product,
        id: angebotId,
        onSuccess: loadClientsFromQuote,
      });
    } else if (clientId && typeof clientId === 'string') {
      store.commit(CLIENT_NAMESPACE + GET_CLIENT_BY_ID, {
        id: parseInt(clientId, 10),
        roles: [ROLE_VERSICHERUNGSNEHMER],
        copyToInputStore: true,
        onSuccess: () => store.dispatch(`${CLIENT_NAMESPACE}fillFallbacks`),
      });
    }

    if (profileId && typeof profileId === 'string') {
      store.commit(PROFILE_NAMESPACE + SET_BY_ID, {
        product: store.state.product,
        id: parseInt(profileId, 10),
      });
    }
  },
};

export default guards;
