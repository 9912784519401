import { Role } from '@/store/modules/client/roles';
import { ProductConfig } from '@/types';
// @ts-ignore
import image from './assets/bg.jpg';

const config: ProductConfig = {
  path: '/woh',
  component: () => import('@/views/layout/BaseLayout.vue'),
  props: {
    name: 'Wohngebäude',
    heading: 'Wohngebäudeversicherung',
    product: 'woh',
    icon: 'home',
    image,

    // distribution settings
    shouldChannel: true,
    shouldPool: true,

    // how many request to send to backend for offers
    numChunks: 3,

    // which client data need to be send to the backend
    clientData: {
      [Role.VersichertePerson]: {
        'clientDaten.versichertePerson.geburtsdatum': 'birthDate',
      },
      [Role.Versicherungsnehmer]: {
        'clientDaten.versicherungsnehmer.geburtsdatum': 'birthDate',
      },
    },

    // role settings
    productSyncRoleSettings: {
      [Role.Versicherungsnehmer]: [Role.VersichertePerson, Role.Zahlperson],
      [Role.VersichertePerson]: [Role.Versicherungsnehmer, Role.Zahlperson],
    },

    profileFields: [
      'eingabedaten.absicherungen.elementar',
      'eingabedaten.absicherungen.feuerBlitz',
      'eingabedaten.absicherungen.glasversicherung',
      'eingabedaten.absicherungen.leitungswasser',
      'eingabedaten.absicherungen.sturmHagel',
      'eingabedaten.demvRabattiert',
      'eingabedaten.selbstbeteiligung',
      'eingabedaten.ableitungsrohre.innerhalb',
      'eingabedaten.ableitungsrohre.ausserhalb',
      'eingabedaten.photovoltaikVorhanden',
      'eingabedaten.photovoltaik.feuerwehrschalter',
      'eingabedaten.photovoltaik.mitversicherung',
    ],

    keepInputFromQuotes: [
      'birthDate',
      'address.zipCode',
      'address.street',
      'address.number',
      'address.city',
    ],

    copyToInputStore: {
      'address.zipCode': 'eingabedaten.plz',
      'address.street': 'eingabedaten.strasse',
      'address.number': 'eingabedaten.hausnummer',
      'address.city': 'eingabedaten.ort',
    },

    defaults: {
      contract: () => ({
        antragAbgelehnt: false,
        gebaeudeImBau: false,
        zahlart: null,
        emailBestaetigungKunde: false,
        memofeld: '',
        vorversicherungsDaten: {
          gesellschaft: null,
          versicherungsnummer: '',
          kuendigender: null,
          kuendigen: false,
          gekuendigt: false,
          beginn: '',
          ablauf: '',
        },
        vorschaden: {
          bestehenAnsprueche: false,
          memofeldAnsprueche: '',
          entschaedigungszahlung: null,
          vorschadenGlas: false,
          glasAnzahl: null,
          glasEntschaedigung: null,
          vorschadenElementar: false,
          elementarAnzahl: null,
          elementarEntschaedigung: null,
        },
        bewohnt: false,
        gefahrerheblicheUmstaende: {
          vorhanden: false,
          memofeld: '',
        },
        trennungBrandmauer: false,
        gewerblicheNutzung: false,
        gewerbeGebaeude: {
          anzahlPraxen: 0,
          flaechePraxen: null,
          anzahlBueros: 0,
          flaecheBueros: null,
          anzahlSonstigeGewerbe: 0,
          flaecheSonstigeGewerbe: null,
        },
        rohbau: {
          baubeginn: null,
          bezug: null,
          feuerRohbauRisiko: true,
        },
        baumangel: false,
        denkmalschutz: false,
        elementargefahren: {
          gefaehrdet: false,
          betroffen: false,
          erdbeben: false,
          erdfall: false,
          erdrutsch: false,
          schneedruck: false,
          lawinen: false,
          ueberschwemmung: false,
          rueckstau: false,
          schadenjahr: '',
          schadenhoehe: null,
          bereinigt: false,
          schadenursache: '',
          gegenmassnahmen: '',
          bemerkungen: '',
        },
      }),
    },

    offerDetail: () => import('./views/offerdetail.vue'),
  },

  // Wizard steps
  steps: {
    input: {
      component: () => import('./views/input.vue'),
    },
    offers: {
      component: () => import('./views/offers.vue'),
    },
    comparison: {
      component: () => import('./views/comparison.vue'),
      meta: {
        showContractButton: false,
      },
    },
    contract: {
      component: () => import('./views/contract.vue'),
    },
    completion: {
      component: () => import('@/views/pages/completion/type/Property.vue'),
    },
  },
};

export default config;
