import filter from 'lodash/filter';
import reduce from 'lodash/reduce';
import cloneDeep from 'lodash/cloneDeep';
import { InsuranceType } from '@/products/pkv/types';
import { Merkmal, MerkmalItem } from '@/types/feature/types';

interface Products {
  ambulant: boolean;
  stationaer: boolean;
  zahn: boolean;
  krankentagegeld: boolean;
  krankenhaustagegeld: boolean;
  pflegezusatz: boolean;
  pflegepflichtversicherung: boolean;
  auslandsreisekrankenversicherung: boolean;
}

export function filterCategoriesByProducts(features: Merkmal[], eingabedaten: any) {
  const products: Products = {
    ambulant: eingabedaten.ambulant,
    stationaer: eingabedaten.stationaer,
    zahn: eingabedaten.zahn,
    krankentagegeld: eingabedaten.krankentagegeld,
    krankenhaustagegeld: eingabedaten.krankenhaustagegeld,
    pflegezusatz: eingabedaten.pflegezusatz,
    pflegepflichtversicherung: eingabedaten.pflegepflichtversicherung,
    auslandsreisekrankenversicherung: eingabedaten.auslandsreisekrankenversicherung,
  };

  const { versicherungsart } = eingabedaten;

  const filteredFeatures: any = filter(
    features, (feature) => {
      if (!feature.meta) return false;

      const isVoll = feature.meta.visibleVoll && versicherungsart === InsuranceType.VOLL;
      const isZusatz = feature.meta.visibleZusatz && versicherungsart === InsuranceType.ZUSATZ;

      return isVoll || isZusatz;
    },
  );

  return filter(filteredFeatures, (feature) => {
    const visibileIn = feature.meta.visible;

    for (let i = 0; i < visibileIn.length; i += 1) {
      if (typeof visibileIn[i] === 'boolean') {
        return visibileIn[i];
      }

      if (products[visibileIn[i] as keyof Products]) {
        return true;
      }
    }
    return false;
  });
}

export function filterItemsByMetaData(features: Merkmal[], eingabedaten: any) {
  const { versicherungsart } = eingabedaten;
  const copyFeatures = cloneDeep(features);

  for (let i = 0; i < copyFeatures.length; i += 1) {
    const { items } = copyFeatures[i];

    copyFeatures[i].items = reduce(items, (acc, value: MerkmalItem) => {
      const { meta } = value;
      if (!meta) {
        // @ts-ignore
        return acc;
      }

      if (meta) {
        const visibleVollProperty = meta.visibleVoll;
        const visibleZusatzProperty = meta.visibleZusatz;

        if (visibleVollProperty && versicherungsart === InsuranceType.VOLL) {
          // @ts-ignore
          acc.push(value);
        }

        if (visibleZusatzProperty && versicherungsart === InsuranceType.ZUSATZ) {
          // @ts-ignore
          acc.push(value);
        }
      }

      return acc;
    }, []);
  }

  return copyFeatures;
}
