import mutations from './mutations';
import sagas from './sagas';
import state from './state';
import getters from './getters';

export const NAMESPACE = 'contract/';

export default {
  namespaced: true,

  mutations,
  sagas,
  state,
  getters,
};
