import api from '@/store/api';

import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import waitForProduct from '@/store/effects/waitForProduct';

import { NAMESPACE as AUTH_NAMESPACE } from '@/store/modules/auth';
import { SET_USER_SUCCESS } from '@/store/modules/auth/mutations';

import { NAMESPACE } from './index';
import {
  GET_FEATURES,
  GET_FEATURES_ERROR,
  GET_FEATURES_SUCCESS,
} from './mutations';

export function* loadFeatures({ payload: product }) {
  try {
    const { data } = yield call(api, 'get', `/${product}/merkmale`);
    yield put({
      type: NAMESPACE + GET_FEATURES_SUCCESS,
      payload: { product, data: data.merkmale },
    });
  } catch (error) {
    yield put({
      type: NAMESPACE + GET_FEATURES_ERROR,
      payload: { product, error },
    });
  }
}

export function* loadFeaturesIfEmpty() {
  const product = yield call(waitForProduct);
  const { data = [] } = yield select((store) => store.features[product]);

  if (data.length < 1) {
    yield put({ type: NAMESPACE + GET_FEATURES, payload: product });
  }
}

export default function* root() {
  yield takeLatest(AUTH_NAMESPACE + SET_USER_SUCCESS, loadFeaturesIfEmpty);
  yield takeLatest(NAMESPACE + GET_FEATURES, loadFeatures);
}
