import reduce from 'lodash/reduce';

/**
 * Returns the difference between two objects
 * by comparing their values.
 *
 * @param {Object} a
 * @param {Object} b
 * @return {Object}
 */
export default function difference(a, b) {
  return reduce(b, (acc, val, key) => {
    if (val !== undefined && val !== a[key]) {
      acc[key] = val;
    }
    return acc;
  }, {});
}
