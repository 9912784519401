import Vue from 'vue';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import toPath from 'lodash/toPath';

export const GET_LEGACY_INPUT = 'getLegacyInput';
export const GET_LEGACY_INPUT_SUCCESS = 'getLegacyInputSuccess';
export const GET_LEGACY_INPUT_ERROR = 'getLegacyInputError';
export const SET_INPUT = 'setInput';
export const SET_INPUT_FIELD = 'setInputField';
export const SET_INPUT_FIELDS = 'setInputFields';

/**
 * Recursively sets the value for the given path.
 *
 * @param {Object} state The root object
 * @param {string} field A path string (e.g. "foo.bar[0].baz")
 * @param {any} value The value to store
 */
function setValue(state, field, value) {
  const path = toPath(field);
  let key = path.shift();
  let root = state;

  for (; path.length > 0; key = path.shift()) {
    let nested = get(root, key);

    if (nested === undefined) {
      nested = isNumber(key) ? [] : {};
      Vue.set(root, key, nested);
    }

    root = nested;
  }

  Vue.set(root, key, value);
}

export default {
  [GET_LEGACY_INPUT](state, product) {
    state[product].isLoading = true;
  },

  [GET_LEGACY_INPUT_SUCCESS](state, { product, data }) {
    state[product].isLoading = false;
    state[product].error = null;
    state[product].data = data;
  },

  [GET_LEGACY_INPUT_ERROR](state, { product, error }) {
    state[product].isLoading = false;
    state[product].error = error;
  },

  [SET_INPUT](state, { product, data }) {
    state[product].data = data;
  },

  [SET_INPUT_FIELD](state, { product, field, value }) {
    setValue(state[product].data, field, value);
  },

  [SET_INPUT_FIELDS](state, { product, fields }) {
    forEach(fields, (value, field) => {
      setValue(state[product].data, field, value);
    });
  },
};
