export enum Role {
  Versicherungsnehmer = 'versicherungsnehmer',
  VersichertePerson = 'versichertePerson',
  Zahlperson = 'zahlPerson',
  MitversicherterPartner = 'mitversicherterPartner',
}

export const ROLE_VERSICHERUNGSNEHMER = Role.Versicherungsnehmer;
export const ROLE_VERSICHERTE_PERSON = Role.VersichertePerson;
export const ROLE_ZAHLPERSON = Role.Zahlperson;
export const ROLE_MTVERSICHERTER_PARTNER = Role.MitversicherterPartner;

const roles: Role[] = [
  Role.Versicherungsnehmer,
  Role.VersichertePerson,
  Role.Zahlperson,
  Role.MitversicherterPartner,
];

/**
 * Fallback role mapping for when the user with a given role gets cleared.
 * In those cases, A (key) falls back to B (value) (references get merged).
 */
export const fallbacks: { [key in Role]?: Role } = {
  [Role.Versicherungsnehmer]: Role.VersichertePerson,
  [Role.VersichertePerson]: Role.Versicherungsnehmer,
  [Role.Zahlperson]: Role.VersichertePerson,
};

export const isValid = (role: Role) => roles.indexOf(role) > -1;

export default roles;
