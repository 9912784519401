// @ts-nocheck

import Vue from 'vue';

import './sentry';

import './css/app.scss';

import './bootstrap';
import './components';
import './icons';

import modals from './plugins/modals';
import ProfileField from '@/directives/ProfileField';
import preventExit from '@/events/preventExit';

import App from './App.vue';
import router from './router';
import store from './store';

import useCompositionApi from './utils/compositionApi';

Vue.config.productionTip = false;

useCompositionApi();

Vue.use(modals);
Vue.directive('profile-field', ProfileField);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

preventExit.enable();
