import { Sortables } from '@/types/offers/sortables';
// @ts-ignore
import { ORDER_ASC, ORDER_DESC } from '@/store/modules/offers/constants';

export const BEITRAG = 'beitrag';
export const DECKUNGSSUMME = 'deckungssumme';
export const ERFUELLUNGSGRAD = 'erfuellungsgrad';
export const GESELLSCHAFT = 'gesellschaft';
export const LAUFZEIT = 'laufzeit';
export const MAX_MOEGLICHER_BEITRAG = 'maxMoeglicherBeitrag';
export const MONATLICHE_RENTE = 'monatlicheRente';
export const SELBSTBETEILIGUNG = 'selbstbeteiligung';
export const TARIFNAME = 'tarifname';
export const VERSICHERUNGSSUMME = 'versicherungssumme';
export const RATING = 'rating';

export const globals = [
  BEITRAG,
  ERFUELLUNGSGRAD,
  GESELLSCHAFT,
  TARIFNAME,
];

export const defaults = [
  ERFUELLUNGSGRAD,
  BEITRAG,
];

const sortables: Sortables = {
  [BEITRAG]: {
    label: 'Beitrag',
    key: 'betrag',
    order: ORDER_ASC,
  },

  [ERFUELLUNGSGRAD]: {
    label: 'Erfüllungsgrad',
    key: 'erfuellungsgrad.wert',
    order: ORDER_DESC,
  },

  [GESELLSCHAFT]: {
    label: 'Gesellschaft',
    key: 'gesellschaft.name',
    order: ORDER_ASC,
    handler: (tarif: any) => tarif.gesellschaft.name.toLowerCase(),
  },

  [TARIFNAME]: {
    label: 'Tarifname',
    key: 'name',
    order: ORDER_ASC,
    handler: (tarif: any) => tarif.name.toLowerCase(),
  },

  [DECKUNGSSUMME]: {
    label: 'Deckungssumme',
    key: 'deckungssumme',
    order: ORDER_DESC,
  },

  [SELBSTBETEILIGUNG]: {
    label: 'Selbstbeteiligung',
    key: 'selbstbeteiligung',
    order: ORDER_ASC,
  },

  [LAUFZEIT]: {
    label: 'Laufzeit',
    key: 'laufzeit',
    order: ORDER_ASC,
  },

  [MONATLICHE_RENTE]: {
    label: 'monatliche Rente',
    key: 'monatlicheRente',
    order: ORDER_DESC,
  },

  [MAX_MOEGLICHER_BEITRAG]: {
    label: 'max. möglicher Beitrag',
    key: 'maxMoeglicherBeitrag',
    order: ORDER_ASC,
  },

  [VERSICHERUNGSSUMME]: {
    label: 'Versicherungssumme',
    key: 'versicherungssumme',
    order: ORDER_DESC,
  },

  [RATING]: {
    label: 'Bewertung',
    key: 'bewertung',
    order: ORDER_DESC,
  },
};

export default sortables;
