import { resourceContainer } from '@/utils/store/createPerProductState';

import mutations from './mutations';
import getters from './getters';
import sagas from './sagas';

export const NAMESPACE = 'offers/files/';

export default {
  namespaced: true,

  mutations,
  getters,
  sagas,

  state: resourceContainer(),
};
