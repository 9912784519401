import { select, take } from 'redux-saga/effects';

import { SET_PRODUCT } from '@/store/modules/global/mutations';

/**
 * Acts as an effect that waits until
 * we have set our current product.
 *
 * @return {IterableIterator<string>}
 */
export default function* waitForProduct() {
  let product = yield select((state) => state.product);

  while (!product) {
    ({ payload: product } = yield take(SET_PRODUCT));
  }

  return product;
}
