import { hasDisplayableOfferRequest, offersMatchInput } from '@/router/utils/stepValidators';
import { StepConfig } from '../types';

const stepConfig: StepConfig = {
  path: 'tarife',

  props: {
    icon: 'list',
    label: 'Beitragsübersicht',

    isValid() {
      return hasDisplayableOfferRequest() || !offersMatchInput();
    },
  },
};

export default stepConfig;
