import { BModal as Bootstrap } from 'bootstrap-vue';
import ModalEvents from './ModalEvents';

/**
 * Custom bootstrap modal extension that overwrites a few defaults
 * as well as integrates it into our custom modal stack system.
 */
export default {
  extends: Bootstrap,

  mixins: [ModalEvents],

  props: {
    cancelTitle: {
      type: String,
      default: 'Abbrechen',
    },

    visible: {
      type: Boolean,
      default: true,
    },
  },
};
