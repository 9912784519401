/* eslint-disable no-bitwise */

/**
 * Converts the given data into a string, from which we can calculate a HashCode
 * source: https://stackoverflow.com/a/8831937/1397894
 *
 * @param data
 * @returns {number}
 */
export default function hashCode(data) {
  const json = JSON.stringify(data);
  let hash = 0;

  for (let i = 0; i < json.length; i += 1) {
    hash = ((hash << 5) - hash) + json.charCodeAt(i);
    hash &= hash; // Convert to 32bit integer
  }

  return hash;
}
