import mapAssoc from '@/utils/mapAssoc';
import mapValues from 'lodash/mapValues';

export default {
  /**
   * Builds a dictionary of all loaded offers by their key (identifier).
   * Each entry is another identifier -> feature mapping.
   *
   * @param state
   * @return {object}
   */
  byKey(state) {
    const offers = mapAssoc(state.entries, 'key');
    return mapValues(offers, (resource) => mapAssoc(resource.data, 'key'));
  },

  /**
   * Checks if we have already loaded the features for the given offer.
   *
   * @param state
   * @param getters
   * @return {function({string}): boolean}
   */
  contains(state, getters) {
    return (key) => (getters.byKey[key] !== undefined);
  },
};
