import cloneDeep from 'lodash/cloneDeep';
import each from 'lodash/each';
import get from 'lodash/get';
import set from 'lodash/set';

import products from '@/products';
import originalState from './state';

export const SET_DOCUMENT = 'setDocument';
export const SET_PRINT_DETAIL = 'setPrintDetail';

export const SET_ACTION = 'setAction';

export const GENERATE_CONTRACT = 'generateContract';
export const GENERATE_CONTRACT_ERROR = 'generateContractError';
export const GENERATE_CONTRACT_SUCCESS = 'generateContractSuccess';

export const CLEAR_ERROR = 'clearError';

export const SET_DATA = 'setData';
export const SET_VALUE = 'setValue';
export const RESET_CONTRACT_STATE = 'resetContractState';

export default {
  [GENERATE_CONTRACT](state, { type }) {
    state.response.isLoading = true;
    state.response.error = null;
    state.type = type;
  },

  [GENERATE_CONTRACT_SUCCESS]({ response }, { data }) {
    response.isLoading = false;
    response.data = data;
    response.error = null;
  },

  [GENERATE_CONTRACT_ERROR]({ response }, error) {
    response.isLoading = false;
    response.error = error;
  },

  [CLEAR_ERROR]({ response }) {
    response.error = null;
  },

  [RESET_CONTRACT_STATE](state, { product }) {
    each(cloneDeep(originalState), (val, key) => {
      state[key] = val;
    });

    const callback = get(products[product].props, ['defaults', 'contract']);
    if (callback !== undefined) {
      state.data = callback();
    }
  },

  [SET_DATA](state, data) {
    state.data = data;
  },

  [SET_VALUE](state, { key, value }) {
    set(state.data, key, value);
  },

  [SET_DOCUMENT](state, { document, value }) {
    state.documents[document] = value;
  },

  [SET_PRINT_DETAIL](state, { printDetail, value }) {
    state.printDetails[printDetail] = value;
  },

  [SET_ACTION](state, { action, value }) {
    state.actions[action] = value;
  },
};
