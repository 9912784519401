import complex from './complex';
import simple from './simple';

export const NAMESPACE = 'choices/';

export default {
  namespaced: true,

  modules: {
    complex,
    simple,
  },
};
