// Source: https://stackoverflow.com/a/44122804/1397894

import _chunk from 'lodash/chunk';

/**
 * Split an array into n subarrays (or columns).
 *
 * @param  {Array} array Doesn't necessarily have to be flat, but this func only works 1 level deep
 * @param  {Number} numCols The desired number of columns
 * @return {Array}
 */
export default function splitArray(array, numCols) {
  const maxColLength = Math.ceil(array.length / numCols);
  const nestedArray = _chunk(array, maxColLength);
  const newArray = [];

  for (let i = 0; i < numCols; i += 1) {
    newArray[i] = nestedArray[i] || [];
  }

  return newArray;
}
