<template>
  <div id="app">
    <LoginError v-if="hasLoginError" />
    <LoginInProgress v-else-if="isAuthenticating" />
    <RouterView v-else />

    <footer class="text-center text-muted small my-4 pb-5">
      <div class="container">
        &copy; DEMV
      </div>
    </footer>

    <ModalStack />
  </div>
</template>

<script>
import LoginError from './views/pages/login/error.vue';
import LoginInProgress from './views/pages/login/index.vue';

export default {
  components: {
    LoginError,
    LoginInProgress,
  },

  computed: {
    isAuthenticating() {
      if (this.$route.name === 'login') {
        return true;
      }

      if (!this.$store.state.product) {
        return false;
      }

      if (this.$route.meta.isWebreport) {
        return false;
      }

      const { user } = this.$store.state.auth;
      return user.data.id === undefined;
    },

    hasLoginError() {
      const { user } = this.$store.state.auth;
      return user.error !== null && user.error.status !== 401;
    },
  },
};
</script>
