import each from 'lodash/each';
import { set } from '@vue/composition-api';
// @ts-ignore
import router from '@/router';
import { Step } from '@/router/steps';
import routeName from '@/router/utils/routeName';

import { PUSH_ROUTE, PushRoute } from './actions/pushRoute';

export const SET_PRODUCT = 'setProduct';
export const REPLACE_ROUTE = 'replaceRoute';
export const BLOCK_PROCEED = 'blockProceed';
export const SET_WEBREPORT = 'setWebreport';
export const GET_PRODUCT_STATUS_SUCCESS = 'getProductStatusSuccess';
export const GET_PRODUCT_STATUS_ERROR = 'getProductStatusError';
export const RESTORE_STATE = 'restoreState';

export default {
  [SET_PRODUCT](state: any, product: string) {
    state.product = product;
  },

  [SET_WEBREPORT](state: any) {
    state.webreport = true;
  },

  [GET_PRODUCT_STATUS_SUCCESS](state: any, { product, data }: any) {
    state.productStatus = data[product];
  },

  [PUSH_ROUTE](_: any, payload: PushRoute) {
    router.push({
      name: routeName(payload.step),
    }).then(payload.onSuccess, payload.onError);
  },

  [REPLACE_ROUTE](_: any, name: Step) {
    router.replace({
      name: routeName(name),
    });
  },

  [BLOCK_PROCEED](state: any, block: boolean) {
    state.blockProceed = block;
  },

  [RESTORE_STATE](state: any, loadedState: any) {
    each(loadedState, (value, key) => {
      set(state, key, value);
    });
  },
};
