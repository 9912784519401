import forOwn from 'lodash/forOwn';
import isObject from 'lodash/isObject';

const deepFreeze = <T extends object>(obj: T): Readonly<T> => {
  forOwn(obj, (value) => {
    if (isObject(value)) {
      deepFreeze(value);
    }
  });

  return Object.freeze(obj);
};

export default deepFreeze;
