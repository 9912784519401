import map from 'lodash/map';
import { Role } from '@/store/modules/client/roles';
import { RATING, BEITRAG } from '@/store/modules/offers/sortables';
import { ProductConfig } from '@/types';
// @ts-ignore
import image from './assets/bg.jpg';
import { Bewertungsart, UserSettings } from '@/store/modules/userSettings/UserSettings';
import { filterCategoriesByProducts, filterItemsByMetaData } from '@/products/pkv/utils/featureFilter';
// @ts-ignore
import { VorversicherungsArt } from '@/products/pkv/views/inputPartials/vorversicherung/index.vue';

const config: ProductConfig<UserSettings> = {
  path: '/pkv',
  component: () => import('@/views/layout/BaseLayout.vue'),

  // Config
  props: {
    name: 'Krankenversicherung',
    heading: 'Krankenversicherung',

    product: 'pkv',
    icon: 'home',
    image,

    numChunks: 3,
    shouldChannel: false,
    shouldPool: false,

    clientData: {
      [Role.VersichertePerson]: {
        'clientDaten.versichertePerson.geburtsdatum': 'birthDate',
        'clientDaten.versichertePerson.geschlecht': 'gender',
      },
      [Role.Versicherungsnehmer]: {
        'clientDaten.versicherungsnehmer.geburtsdatum': 'birthDate',
        'clientDaten.versicherungsnehmer.geschlecht': 'gender',
      },
    },

    productSyncRoleSettings: {
      [Role.Versicherungsnehmer]: [Role.Zahlperson],
    },

    profileFields: [
      'eingabedaten.versicherungsart',
      'eingabedaten.leistungstraeger',
      'eingabedaten.beihilfesaetze',
      'eingabedaten.stationaer',
      'eingabedaten.stationaerOptionen.leistung',
      'eingabedaten.stationaerOptionen.leistungVoll',
      'eingabedaten.stationaerOptionen.leistungZusatz',
      'eingabedaten.stationaerOptionen.gebuehrenordnung',
      'eingabedaten.ambulant',
      'eingabedaten.ambulantOptionen.gebuehrenordnung',
      'eingabedaten.ambulantOptionen.heilpraktiker',
      'eingabedaten.ambulantOptionen.kurort',
      'eingabedaten.ambulantOptionen.logopaedie',
      'eingabedaten.ambulantOptionen.ergotherapie',
      'eingabedaten.ambulantOptionen.hilfsmittel',
      'eingabedaten.ambulantOptionen.ergaenzungGKV',
      'eingabedaten.zahn',
      'eingabedaten.zahnOptionen.leistung',
      'eingabedaten.zahnOptionen.gebuehrenordnung',
      'eingabedaten.zahnOptionen.zahnSummenbegrenzungUnfall',
      'eingabedaten.zahnOptionen.bruecken',
      'eingabedaten.zahnOptionen.funktionsanalyse',
      'eingabedaten.zahnOptionen.funktionstherapie',
      'eingabedaten.zahnOptionen.implantate',
      'eingabedaten.zahnOptionen.inlays',
      'eingabedaten.zahnOptionen.kieferorthopaedie',
      'eingabedaten.zahnOptionen.knochenaufbau',
      'eingabedaten.zahnOptionen.kronen',
      'eingabedaten.zahnOptionen.kunststofffuellungen',
      'eingabedaten.zahnOptionen.onlays',
      'eingabedaten.zahnOptionen.parodontosebehandlung',
      'eingabedaten.zahnOptionen.zahnreinigung',
      'eingabedaten.zahnOptionen.veneers',
      'eingabedaten.zahnOptionen.verblendungen',
      'eingabedaten.zahnOptionen.wurzelbehandlung',
      'eingabedaten.zahnOptionen.zahnbehandlung',
      'eingabedaten.zahnOptionen.zahnersatz',
      'eingabedaten.zahnOptionen.zahnerstattung',
      'eingabedaten.zahnOptionen.zahnbehandlungMin',
      'eingabedaten.zahnOptionen.zahnersatzMin',
      'eingabedaten.zahnOptionen.kieferorthopaedieMin',
      'eingabedaten.zahnOptionen.materialLaborKosten',
      'eingabedaten.krankentagegeld',
      'eingabedaten.krankentagegeldOptionen.karenzzeit',
      'eingabedaten.krankentagegeldOptionen.leistungBerufsunfaehigkeit',
      'eingabedaten.krankentagegeldOptionen.leistungTeilBerufsunfaehigkeit',
      'eingabedaten.krankentagegeldOptionen.alkoholKlausel',
      'eingabedaten.krankentagegeldOptionen.tag0',
      'eingabedaten.krankentagegeldOptionen.tag1',
      'eingabedaten.krankentagegeldOptionen.tag2',
      'eingabedaten.krankentagegeldOptionen.betrag0',
      'eingabedaten.krankentagegeldOptionen.betrag1',
      'eingabedaten.krankentagegeldOptionen.betrag2',
      'eingabedaten.krankentagegeldOptionen.krankentageGeldFelderAnzahl',
      'eingabedaten.krankenhaustagegeld',
      'eingabedaten.krankenhaustagegeldOptionen.betrag',
      'eingabedaten.auslandsreisekrankenversicherung',
      'eingabedaten.auslandsreisekrankenversicherungOptionen.ruecktransportAusland',
      'eingabedaten.pflegeBahrOptionen.auswahl',
      'eingabedaten.pflegepflichtversicherung',
      'eingabedaten.pflegepflichtversicherungOptionen.leistung',
      'eingabedaten.pflegezusatz',
      'eingabedaten.pflegezusatzOptionen.grad1',
      'eingabedaten.pflegezusatzOptionen.grad2',
      'eingabedaten.pflegezusatzOptionen.grad3',
      'eingabedaten.pflegezusatzOptionen.grad4',
      'eingabedaten.pflegezusatzOptionen.grad5',
      'eingabedaten.pflegezusatzOptionen.pflegeBahrAufbauTarife',
      'eingabedaten.pflegezusatzOptionen.pflegeBahrTarife',
      'eingabedaten.pflegezusatzOptionen.pflegekostenTarife',
      'eingabedaten.allgemein.selbstbeteiligungMin',
      'eingabedaten.allgemein.selbstbeteiligungMax',
      'eingabedaten.allgemein.optionsrecht',
      'eingabedaten.allgemein.beitragsrueckerstattung',
      'eingabedaten.allgemein.pauschalleistung',
      'eingabedaten.allgemein.primaerarzttarif',
      'eingabedaten.allgemein.osttarife',
      'eingabedaten.allgemein.beitragsentlastungOptionen.betrag',
      'eingabedaten.allgemein.beitragsentlastungOptionen.typ',
      'eingabedaten.allgemein.altersrueckstellung',
      // 'eingabedaten.zahlweise',
    ],

    keepInputFromQuotes: [],

    comparisonExtraColumns: 1,

    offerDetail: () => import('./views/offerdetail.vue'),

    userSettings: () => import('./components/PkvUserSettings.vue'),

    defaultUserSettings: {
      bewertungsart: Bewertungsart.ERFUELLUNGSGRAD,
      gesamtaufwand: true,
    },

    defaultSorting: [RATING, BEITRAG],

    useFirstOfNextMonth: true,

    disabledComparisonFilters: {
      hideDuplicates: true,
    },

    featureFilter: (store: any, features: any): any => {
      const { product } = store.state;
      const { eingabedaten } = store.state.input[product].data;
      const filteredMerkmale = filterCategoriesByProducts(features, eingabedaten);

      return filterItemsByMetaData(filteredMerkmale, eingabedaten);
    },

    oldTariffFormatter: (data: any): Record<string, any> => {
      const vorversicherungsArt = data.vorversicherung.art;

      const oldTarifSearchData: Record<string, any> = {};
      oldTarifSearchData.beitrag = data.vorversicherung?.beitrag;
      oldTarifSearchData.einzelbeitraege = data.vorversicherung?.einzelbeitraege;
      oldTarifSearchData.products = map(data.vorversicherung?.tarifBausteine, (baustein) => ({
        id: baustein.id,
        name: baustein.name,
        price: baustein.price || null,
      }));
      oldTarifSearchData.arbeitgeberanteil = {
        kv: data.vorversicherung?.arbeitgeberanteil?.kv,
        ppv: data.vorversicherung?.arbeitgeberanteil?.ppv,
      };
      oldTarifSearchData.art = vorversicherungsArt;

      if (vorversicherungsArt !== VorversicherungsArt.GKV) {
        oldTarifSearchData.gesellschaft = data.vorversicherung.gesellschaft;
        oldTarifSearchData.berufsgruppe = data.vorversicherung.berufsgruppe;
        oldTarifSearchData.berufsstatus = data.vorversicherung.berufsstatus;
      }

      return oldTarifSearchData;
    },
  },

  // Wizard steps
  steps: {
    input: {
      component: () => import('./views/input.vue'),
    },
    offers: {
      component: () => import('./views/offers.vue'),
    },
    comparison: {
      component: () => import('./views/comparison.vue'),
    },
    contract: {
      component: () => import('@/views/pages/contract/type-health/index.vue'),
    },
    completion: {
      component: () => import('@/views/pages/completion/type/Property.vue'),
    },
  },
};

export default config;
