import { Route } from 'vue-router';
import { Step } from '@/router/steps';
import { BaseAction } from '@/store/common';

export interface PushRoute {
  step: Step;
  onSuccess?: (route: Route) => void;
  onError?: () => void;
}

export type PushRouteAction = BaseAction<PushRoute>

export const PUSH_ROUTE = 'pushRoute';

export const pushRoute = (payload: PushRoute): PushRouteAction => ({
  type: PUSH_ROUTE,
  payload,
});
