import map from 'lodash/map';
import { NavigationGuard, RouteConfig } from 'vue-router';
import { ProductConfig } from '@/types';
// @ts-ignore
import { useStore } from '@/store';
import { SET_PRODUCT, SET_WEBREPORT } from '@/store/modules/global/mutations';
// @ts-ignore
import { NAMESPACE as OFFER_NAMESPACE } from '@/store/modules/offers';
// @ts-ignore
import { SET_SORTING } from '@/store/modules/offers/mutations';
import sortables from '@/store/modules/offers/sortables';

const store = useStore();

const beforeModule = (module: ProductConfig): NavigationGuard => (to, from, next) => {
  store.commit(SET_PRODUCT, module.props.product);
  store.commit(SET_WEBREPORT, true);

  if (module.props.defaultSorting) {
    const sort = map(module.props.defaultSorting, (key) => (
      { key, order: sortables[key as keyof typeof sortables].order }
    ));
    store.commit(OFFER_NAMESPACE + SET_SORTING, sort);
  }

  next();
};

export const addWebreports = (
  module: ProductConfig,
  identifier: string,
): RouteConfig[] => [
  {
    name: `${identifier}.webreport-vergleichuebersicht`,
    path: `/webreports/${identifier}/vergleich/:id`,
    component: () => import('@/views/pages/webreports/Vergleichuebersicht.vue'),
    meta: {
      product: identifier,
      type: 'vergleich',
      isWebreport: true,
    },
    props: module.props,
    beforeEnter: beforeModule(module),
  },
  {
    name: `${identifier}.webreport-beitragsuebersicht`,
    path: `/webreports/${identifier}/beitragsuebersicht/:id`,
    component: () => import('@/views/pages/webreports/Beitragsuebersicht.vue'),
    meta: {
      product: identifier,
      type: 'uebersicht',
      isWebreport: true,
    },
    props: module.props,
    beforeEnter: beforeModule(module),
  },
];

export default addWebreports;
