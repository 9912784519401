import { UserSettings } from '@/store/modules/userSettings/UserSettings';
import { UserSettingsResource } from '@/store/modules/userSettings/index';

export enum UserSettingsMutations {
  SET_USER_SETTINGS_ERROR = 'setUserSettingsError',
  SET_USER_SETTINGS = 'setUserSettings',
  SET_USER_SETTINGS_SUCCESS = 'setUserSettingsSuccess',
  STORE_USER_SETTINGS = 'storeUserSettings',
  STORE_USER_SETTINGS_SUCCESS = 'storeUserSettingsSuccess',
  UPDATE_USER_SETTINGS = 'updateUserSettings',
}

export default {
  [UserSettingsMutations.SET_USER_SETTINGS](state: UserSettingsResource) {
    state.isLoading = true;
  },
  [UserSettingsMutations.SET_USER_SETTINGS_SUCCESS](
    state: UserSettingsResource, data: UserSettings,
  ) {
    state.isLoading = false;
    state.data = data;
  },
  [UserSettingsMutations.UPDATE_USER_SETTINGS](
    state: UserSettingsResource, data: Partial<UserSettings>,
  ) {
    state.isChanged = true;
    state.data = { ...state.data, ...data };
  },
  [UserSettingsMutations.STORE_USER_SETTINGS](state: UserSettingsResource) {
    state.isLoading = true;
  },
  [UserSettingsMutations.STORE_USER_SETTINGS_SUCCESS](state: UserSettingsResource) {
    state.isLoading = false;
    state.isChanged = false;
  },
  [UserSettingsMutations.SET_USER_SETTINGS_ERROR](
    state: UserSettingsResource,
    { error, defaultUserSettings }: any,
  ) {
    state.error = error;
    state.data = defaultUserSettings;
    state.isLoading = false;
  },
};
