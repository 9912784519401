import routeName from '@/router/utils/routeName';
import { Step } from '.';
import { StepConfig } from '../types';

const stepConfig: StepConfig = {
  path: 'abschluss',
  component: () => import('@/views/pages/completion/index.vue'),
  meta: {
    showWizard: false,
  },

  beforeEnter: (to, { name = null }, next) => {
    // redirect if previous route is unknown
    if (name === null) {
      next({ name: routeName(Step.Input) });
      return;
    }

    next();
  },
};

export default stepConfig;
