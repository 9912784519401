export default {
  created() {
    this.propagateEvent('hidden');
    this.propagateEvent('shown');
  },

  methods: {
    /**
     * Propagates the given event to our parent
     * as we usually wrap the modal in another component.
     *
     * @param {string} event
     */
    propagateEvent(event) {
      this.$on(event, (e) => {
        if (this.$parent) {
          this.$parent.$emit(event, e);
        }
      });
    },
  },
};
