import createPerProductState, { resourceContainer } from '@/utils/store/createPerProductState';

import distribution from './distribution';

import mutations from './mutations';
import getters from './getters';
import sagas from './sagas';

export const NAMESPACE = 'companies/';

export default {
  namespaced: true,

  mutations,
  getters,
  sagas,

  state: createPerProductState(() => ({
    ...resourceContainer(null),
    lastRequestHash: 0,
    specialCompanies: resourceContainer(null),
  })),

  modules: {
    distribution,
  },
};
