





























































import { computed, defineComponent, watch } from '@vue/composition-api';

import { InsuranceType } from '@/products/pkv/types';
import Section from '@/components/Section.vue';
import ToggleContainer from '@/components/ToggleContainer.vue';

interface Props {
  form: Record<string, any>
  formValues: Record<string, any>
  meta: Record<string, any>
}

export enum VorversicherungsArt {
  GKV = 'gesetzlich',
  PKV = 'privat'
}

export default defineComponent<Props>({
  name: 'VorversicherungsPartial',

  components: {
    PkvVorversicherung: () => import('./inputsPkvVorversicherung.vue'),
    GkvVorversicherung: () => import('./inputsGkvVorversicherung.vue'),
    GkvCostSavings: () => import('./inputsGkvCostSavings.vue'),
    Section,
    TarifModuleContainer: ToggleContainer,
  },

  props: {
    form: {
      type: Object,
      required: true,
    },
    formValues: {
      type: Object,
      required: true,
    },
    meta: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const disableVorversicherungsInput = computed(() => !props.formValues.vorversicherung);
    const isVollVersicherung = computed(
      () => props.formValues.versicherungsart === InsuranceType.VOLL,
    );

    const withAGAnteil = computed(() => {
      if (props.formValues.versicherungsart === InsuranceType.ZUSATZ) {
        return false;
      }

      if (props.meta.berufsgruppe) {
        let isWithAGAnteil = props.formValues.vorversicherungBerufsgruppe === ''
          ? false
          : !props.meta.berufsgruppe.hideAGAnteil
            .includes(props.formValues.vorversicherungBerufsgruppe);

        if (
          props.meta.berufsgruppe.hideAGAnteilWithoutArbeitnehmer
            .includes(props.formValues.vorversicherungBerufsgruppe)
          && !props.meta.berufsstatus.isArbeitnehmer
            .includes(props.formValues.vorversicherungBerufsstatus)
        ) {
          isWithAGAnteil = false;
        }

        return isWithAGAnteil;
      }

      return false;
    });

    const labelMonatlicherGesamtbeitrag = computed(() => (
      withAGAnteil.value || props.formValues.vorversicherungsArt === VorversicherungsArt.GKV
        ? 'Monatlicher Gesamtbeitrag (inkl. AG-Anteil)'
        : 'Monatlicher Gesamtbeitrag'));

    watch(() => props.formValues.vorversicherungsArt, (val, oldVal) => {
      if (val !== oldVal) {
        props.form.fields.vorversicherungTarifAuswahl.set([]);
      }
    });

    return {
      vorversicherungsArtOptions: [
        { text: 'GKV', value: VorversicherungsArt.GKV },
        { text: 'PKV', value: VorversicherungsArt.PKV },
      ],

      disableVorversicherungsInput,
      labelMonatlicherGesamtbeitrag,
      withAGAnteil,

      VorversicherungsArt,
      isVollVersicherung,
    };
  },
});
