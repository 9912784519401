import mapValues from 'lodash/mapValues';
import reduce from 'lodash/reduce';
import { ApiResource } from '@/types';

type Question = {
  name: string;
  type: 'group' | 'dropdown';
  items?: Question[];
}

type State = {
  [product: string]: {
    [insuranceId: number]: ApiResource<Question[]>;
  };
}

type FlatMap = {
  [name: string]: Question;
}

const mergeFields = (root: FlatMap, obj: Question[]): FlatMap => (
  reduce(obj, (acc, question) => {
    if (question.type === 'group' && question.items !== undefined) {
      return mergeFields(acc, question.items);
    }

    acc[question.name] = question;

    return acc;
  }, root)
);

export default {
  byName(state: State) {
    return mapValues(
      state,
      (company) => mapValues(
        company,
        ({ data }) => mergeFields({}, data),
      ),
    );
  },
};
