import {
  all, call, fork, put, take, takeLatest,
} from 'redux-saga/effects';
import find from 'lodash/find';
import reduce from 'lodash/reduce';

import api from '@/store/api';
// @ts-ignore
import { NAMESPACE as OFFER_NAMESPACE } from '@/store/modules/offers';
// @ts-ignore
import { NAMESPACE as INPUT_NAMESPACE } from '@/store/modules/input';
// @ts-ignore
import { SET_INPUT } from '@/store/modules/input/mutations';
import {
  ADD_SELECTION, SET_OFFERS_WEBREPORT, SET_RECOMMENDED, SET_VORVERSICHERUNG,
  // @ts-ignore
} from '@/store/modules/offers/mutations';
// @ts-ignore
import { NAMESPACE as FEATURE_NAMESPACE } from '@/store/modules/features';
// @ts-ignore
import { GET_FEATURES_SUCCESS, SET_FEATURE_VALUE } from '@/store/modules/features/mutations';
// @ts-ignore
import { NAMESPACE as OFFER_FEATURE_NAMESPACE } from '@/store/modules/features/offers';
// @ts-ignore
import { SET_FEATURES_WEBREPORT as SET_OFFER_FEATURES } from '@/store/modules/features/offers/mutations';

import { NAMESPACE as USER_SETTINGS_NAMESPACE } from '@/store/modules/userSettings/';
import { UserSettingsMutations } from '@/store/modules/userSettings/mutations';

import { NAMESPACE } from '.';
import { WebreportMutations } from './mutations';

import { AxiosResponse } from 'axios';
import { Offer } from '@/types/webreports/Offer';

interface WebreportsApiResponse {
  id: string;
  clientId: string;
  offerId: number;
  data: {
    [k: string]: any;
  }
}

type setDataPayload = {
  payload: {
    data: {
      clientId: number;
      tarife: Offer[],
      merkmale: any[],
      recommendation: string;
      hasAllTimeTariff: boolean,
      vorgaben: any,
      id: string,
      offerId: number;
      userKonfiguration: {
        userSettings: Record<string, any>
      }
    },
    product: string
  }
}

// Helper function for mapping features
const addItem = (ctx: any, item: any) => {
  if (item.identifier !== undefined) {
    ctx[item.identifier] = item;
  }

  if (item.items !== undefined) {
    item.items.forEach((entry: any) => addItem(ctx, entry));
  }

  return ctx;
};

function* loadDataVergleich({ payload }: { payload: { id: number, product: string}}) {
  try {
    const { data }: AxiosResponse<WebreportsApiResponse> = yield call(
      api,
      'get',
      `${payload.product}/angebot/${payload.id}/webreport/frontend`,
    );

    const { merkmale } = data.data;

    yield put({
      type: NAMESPACE + WebreportMutations.GET_VERGLEICHS_REPORT_SUCCESS,
      payload: {
        data: {
          clientId: data.clientId,
          id: data.id,
          offerId: data.offerId,
          tarife: data.data.tarife,
          hasAllTimeTariff: data.data.hasAllTimeTariff,
          merkmale,
          recommendation: data.data.recommendation,
          vorgaben: data.data.vorgaben,
          userKonfiguration: data.data.userKonfiguration,
        },
        product: payload.product,
      },
    });
  } catch (error) {
    yield put({
      type: NAMESPACE + WebreportMutations.GET_VERGLEICHS_REPORT_ERROR,
      payload: { error },
    });
  }
}

function* loadDataUebersicht({ payload }: { payload: { id: number, product: string}}) {
  try {
    const { data }: AxiosResponse<WebreportsApiResponse> = yield call(
      api,
      'get',
      `${payload.product}/angebot/${payload.id}/webreport/frontend`,
    );

    yield put({
      type: NAMESPACE + WebreportMutations.GET_UEBERSICHTS_REPORT_SUCCESS,
      payload: {
        data: {
          clientId: data.clientId,
          id: data.id,
          offerId: data.offerId,
          tarife: data.data.tarife,
          allTimeTariffs: data.data.allTimeTariffs,
          vorgaben: data.data.vorgaben,
          userKonfiguration: data.data.userKonfiguration,
        },
        product: payload.product,
      },
    });
  } catch (error) {
    yield put({
      type: NAMESPACE + WebreportMutations.GET_UEBERSICHTS_REPORT_ERROR,
      payload: { error },
    });
  }
}

export function* setFeatures({ payload }: setDataPayload) {
  const { product } = payload;
  const { merkmale } = payload.data;

  yield put({
    type: FEATURE_NAMESPACE + GET_FEATURES_SUCCESS,
    payload: { product, data: merkmale },
  });
}

function* setInputData({
  payload,
}: setDataPayload) {
  // code needs to be defensive since some old webreports might miss vorgaben key
  let eingabedaten = {};
  if (payload.data.vorgaben) {
    ({ eingabedaten } = payload.data.vorgaben);
  }
  const { product } = payload;

  yield put({
    type: INPUT_NAMESPACE + SET_INPUT,
    payload: {
      product,
      data: {
        eingabedaten,
      },
    },
  });
}

function* setOffers({ payload }: setDataPayload) {
  const { tarife, recommendation, hasAllTimeTariff } = payload.data;

  yield put({
    type: OFFER_NAMESPACE + SET_OFFERS_WEBREPORT,
    payload: tarife,
  });

  for (let i = 0; i < tarife.length; i += 1) {
    yield put({
      type: OFFER_NAMESPACE + ADD_SELECTION,
      payload: tarife[i],
    });
  }

  if (recommendation !== null) {
    yield put({
      type: OFFER_NAMESPACE + SET_RECOMMENDED,
      payload: { identifier: recommendation, fromQuote: true },
    });
  }

  if (hasAllTimeTariff) {
    const allTimeTariff = tarife[0];
    yield put({
      type: OFFER_NAMESPACE + SET_VORVERSICHERUNG,
      payload: { allTimeTariff, fromQuote: true },
    });
  }
}

function* setFeatureValues({ payload }: setDataPayload) {
  const { merkmale = [] } = payload.data.vorgaben;
  const features = reduce(payload.data.merkmale, addItem, {});

  for (let i = 0; i < merkmale.length; i += 1) {
    // debugger;
    const { identifier, value } = merkmale[i];
    const feature = features[identifier];

    if (feature !== undefined) {
      yield put({
        type: FEATURE_NAMESPACE + SET_FEATURE_VALUE,
        payload: { feature, value },
      });
    }
  }
}

function* setOfferFeatures({ payload }: setDataPayload) {
  const { tarife } = payload.data;

  for (let i = 0; i < tarife.length; i += 1) {
    const { identifier, merkmale } = <Offer>tarife[i];
    yield put({
      type: OFFER_FEATURE_NAMESPACE + SET_OFFER_FEATURES,
      payload: { key: identifier, data: merkmale },
    });
  }
}

function* watchFeaturesFromQuote(): IterableIterator<any> {
  while (true) {
    const payload = find(
      yield all([
        take(NAMESPACE + WebreportMutations.GET_VERGLEICHS_REPORT_SUCCESS),
        take(FEATURE_NAMESPACE + GET_FEATURES_SUCCESS),
      ]),
      ['type', NAMESPACE + WebreportMutations.GET_VERGLEICHS_REPORT_SUCCESS],
    );

    // @ts-ignore
    yield call(setFeatureValues, payload);
  }
}

function* setLoadingDone() {
  yield put({
    type: NAMESPACE + WebreportMutations.LOADING_VERGLEICHS_DATA_DONE,
  });
}

function* setUserSettings({ payload }: setDataPayload) {
  yield put({
    type: USER_SETTINGS_NAMESPACE + UserSettingsMutations.UPDATE_USER_SETTINGS,
    payload: { ...payload.data?.userKonfiguration?.userSettings },
  });
}

export default function* root() {
  yield takeLatest(`${NAMESPACE}${WebreportMutations.GET_VERGLEICHS_REPORT}` as any, loadDataVergleich);
  yield takeLatest(`${NAMESPACE}${WebreportMutations.GET_VERGLEICHS_REPORT_SUCCESS}` as any, setInputData);
  yield takeLatest(`${NAMESPACE}${WebreportMutations.GET_VERGLEICHS_REPORT_SUCCESS}` as any, setOffers);
  yield takeLatest(`${NAMESPACE}${WebreportMutations.GET_VERGLEICHS_REPORT_SUCCESS}` as any, setFeatures);
  yield takeLatest(`${NAMESPACE}${WebreportMutations.GET_VERGLEICHS_REPORT_SUCCESS}` as any, setOfferFeatures);
  yield takeLatest(`${NAMESPACE}${WebreportMutations.GET_VERGLEICHS_REPORT_SUCCESS}` as any, setUserSettings);

  yield takeLatest([
    NAMESPACE + WebreportMutations.GET_VERGLEICHS_REPORT_SUCCESS,
    FEATURE_NAMESPACE + GET_FEATURES_SUCCESS,
    OFFER_FEATURE_NAMESPACE + SET_OFFER_FEATURES,
    FEATURE_NAMESPACE + SET_FEATURE_VALUE,
    OFFER_NAMESPACE + SET_OFFERS_WEBREPORT,
    OFFER_NAMESPACE + ADD_SELECTION,
    OFFER_NAMESPACE + SET_RECOMMENDED,
    OFFER_NAMESPACE + SET_VORVERSICHERUNG,
    USER_SETTINGS_NAMESPACE + UserSettingsMutations.UPDATE_USER_SETTINGS,
  ], setLoadingDone);

  yield takeLatest(`${NAMESPACE}${WebreportMutations.GET_UEBERSICHTS_REPORT}` as any, loadDataUebersicht);
  yield takeLatest(`${NAMESPACE}${WebreportMutations.GET_UEBERSICHTS_REPORT_SUCCESS}` as any, setInputData);
  yield takeLatest(`${NAMESPACE}${WebreportMutations.GET_UEBERSICHTS_REPORT_SUCCESS}` as any, setUserSettings);

  yield fork(watchFeaturesFromQuote);
}
