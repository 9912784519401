import Vue from 'vue';

export const GET_COMPANIES = 'getCompanies';
export const GET_COMPANIES_SUCCESS = 'getCompaniesSuccess';
export const GET_COMPANIES_ERROR = 'getCompaniesError';

export default {
  [GET_COMPANIES](state, key) {
    if (state.companies[key] !== undefined) {
      state.companies[key].isLoading = true;
      return;
    }

    // Fix reactivity issues by directly setting the value
    Vue.set(state.companies, key, {
      isLoading: true,
      error: {},
      data: [],
    });
  },

  [GET_COMPANIES_SUCCESS](state, { key, data }) {
    state.companies[key].isLoading = false;
    state.companies[key].data = data;
    state.companies[key].error = {};
  },

  [GET_COMPANIES_ERROR](state, { key, error }) {
    state.companies[key].isLoading = false;
    state.companies[key].error = error;
  },
};
