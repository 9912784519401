import api from '@/store/api';
import { setUser } from '@sentry/browser';
import analytics from '@/utils/analytics';

import {
  call,
  delay,
  fork,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import waitForProduct from '@/store/effects/waitForProduct';
import { NAMESPACE } from './index';
import {
  GET_USER,
  SET_USER_ERROR,
  SET_USER_SUCCESS,
  GET_PERMISSIONS,
  SET_PERMISSIONS_ERROR,
  SET_PERMISSIONS_SUCCESS,
} from './mutations';

/**
 * Loads the user object for the current product page.
 * Might be replaced with a JWT token later on.
 * @param product
 * @return {IterableIterator<*>}
 */
export function* loadUser({ payload: product }) {
  yield call(waitForProduct); // Wait for a valid product

  try {
    const { data } = yield call(api, 'get', `/${product}/makler/me`);
    yield put({ type: NAMESPACE + SET_USER_SUCCESS, payload: data });
  } catch (e) {
    yield put({ type: NAMESPACE + SET_USER_ERROR, payload: e });
  }
}

/**
 * Loads the user permissions for the current product page.
 * Might be replaced with a JWT token later on.
 *
 * @return {IterableIterator<*>}
 */
export function* loadPermissions() {
  const product = yield call(waitForProduct);
  yield put({ type: NAMESPACE + GET_PERMISSIONS, payload: product });

  try {
    const { data } = yield call(api, 'get', `/${product}/makler/me/rechte`);
    yield put({ type: NAMESPACE + SET_PERMISSIONS_SUCCESS, payload: data });
  } catch (e) {
    yield put({ type: NAMESPACE + SET_PERMISSIONS_ERROR, payload: e });
  }
}

/**
 * Pings the server periodically to keep the session alive.
 * @return {IterableIterator<*>}
 */
export function* heartbeat() {
  yield call(waitForProduct); // Wait for a valid product

  const webreport = yield select((store) => store.webreport);

  while (true && !webreport) {
    yield delay(1000 * 60 * 5); // every 5 minutes

    try {
      const product = yield select((store) => store.product);
      const berechnungsId = yield select((store) => store.offers.remoteHash);

      yield call(api, 'get', `/${product}/heartbeat`, {
        params: { berechnungsId },
      });
    } catch (ignore) {
      // Ignore any errors
    }
  }
}

function setUserIdForAnalytics({ payload: data }) {
  analytics(['setUserId', `${data.id}`]);
  setUser(data);
}

export default function* root() {
  yield takeLatest(NAMESPACE + GET_USER, loadUser);
  yield takeLatest(NAMESPACE + SET_USER_SUCCESS, loadPermissions);
  yield takeLatest(NAMESPACE + SET_USER_SUCCESS, setUserIdForAnalytics);
  yield fork(heartbeat);
}
