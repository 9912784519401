import forEach from 'lodash/forEach';

import { SET_DOCUMENTS_CHECKED_STATE } from './mutations';

export default {
  resetDocumentSelection({ commit, getters }) {
    const { selection } = getters;
    forEach(selection, (selected) => {
      commit(SET_DOCUMENTS_CHECKED_STATE, {
        identifier: selected.identifier,
        checked: false,
      });
    });
  },
};
