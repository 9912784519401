import isEmpty from 'lodash/isEmpty';
import each from 'lodash/each';
import get from 'lodash/get';
import reduce from 'lodash/reduce';

import {
  call,
  fork,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import api from '@/store/api';

import products from '@/products';
import waitForProduct from '@/store/effects/waitForProduct';

import { NAMESPACE } from '@/store/modules/input';
import { NAMESPACE as CLIENT_NAMESPACE } from '@/store/modules/client';
import {
  GET_LEGACY_INPUT,
  GET_LEGACY_INPUT_ERROR,
  GET_LEGACY_INPUT_SUCCESS,
  SET_INPUT_FIELDS,
} from '@/store/modules/input/mutations';
import {
  ADD_CLIENT_TO_ROLE,
  REMOVE_CLIENT_BY_ROLE,
  GET_CLIENT_BY_ID_SUCCESS,
} from '@/store/modules/client/mutations';
import { ROLE_VERSICHERTE_PERSON } from '@/store/modules/client/roles';
import { isCurrentRouteName } from '@/router/utils/routeName';

/**
 * Loads the "legacy" input data in case we do not have it yet
 * in our store. This is being saved on a per-product basis.
 *
 * @param product
 * @return {IterableIterator<*>}
 */
export function* loadLegacy({ payload: product }) {
  const exists = yield select((store) => !isEmpty(store.input[product].data));
  if (exists) {
    return;
  }

  try {
    const { data } = yield call(api, 'get', `${product}/tarifvorgaben/input`);
    yield put({ type: NAMESPACE + GET_LEGACY_INPUT_SUCCESS, payload: { product, data } });
  } catch (error) {
    yield put({ type: NAMESPACE + GET_LEGACY_INPUT_ERROR, payload: { product, error } });
  }
}

export function* resetClientRelatedInputFields({ payload: { roles } }) {
  // Only update specific roles
  if (roles.indexOf(ROLE_VERSICHERTE_PERSON) < 0) {
    return;
  }

  // We only overwrite input data on input pages
  if (!isCurrentRouteName('input')) {
    return;
  }

  // Do not overwrite data when loading quotes
  const product = yield select((store) => store.product);
  const quote = yield select((store) => store.quote[product]);

  if (quote.data !== undefined && quote.data.id !== undefined) {
    return;
  }

  yield put({
    type: NAMESPACE + SET_INPUT_FIELDS,
    payload: {
      product,
      fields: products[product].props.resetClientFieldsOnLoad || {},
    },
  });
}

export function* resetInputFieldsOnRoleRemoval({ payload: role }) {
  yield fork(resetClientRelatedInputFields, { payload: { roles: [role] } });
}

export function* copyToInputFields({
  payload: {
    data,
    keepQuoteData = false,
    copyToInputStore = false,
  },
}) {
  const product = yield call(waitForProduct);
  const copyFields = products[product].props.copyToInputStore;

  if (copyFields === undefined || Object.keys(copyFields) === 0) {
    return;
  }

  const productInputState = get(yield select(), `input.${product}.data`);

  const fieldsUndefindInStore = reduce(copyFields, (acc, value, key) => {
    const fieldValue = get(productInputState, value);
    if (fieldValue === undefined || fieldValue === null) {
      acc[key] = value;
    }
    return acc;
  }, {});
  const fields = {};

  if (keepQuoteData || !copyToInputStore) {
    if (isEmpty(fieldsUndefindInStore)) {
      return;
    }
    each(fieldsUndefindInStore, (field, clientField) => {
      const filedValue = get(data, clientField);
      if (filedValue !== null && filedValue !== undefined) {
        fields[field] = filedValue;
      }
    });
  } else {
    each(copyFields, (field, clientField) => {
      const filedValue = get(data, clientField);
      if (filedValue !== null && filedValue !== undefined) {
        fields[field] = filedValue;
      }
    });
  }

  yield put({ type: NAMESPACE + SET_INPUT_FIELDS, payload: { product, fields } });
}

export default function* root() {
  yield takeLatest(CLIENT_NAMESPACE + ADD_CLIENT_TO_ROLE, resetClientRelatedInputFields);
  yield takeLatest(CLIENT_NAMESPACE + REMOVE_CLIENT_BY_ROLE, resetInputFieldsOnRoleRemoval);
  yield takeLatest(CLIENT_NAMESPACE + GET_CLIENT_BY_ID_SUCCESS, copyToInputFields);

  yield takeLatest(NAMESPACE + GET_LEGACY_INPUT, loadLegacy);
}
