import { resourceContainer } from '@/utils/store/createPerProductState';
import { CREATE_PROFILE } from '@/store/modules/profiles/mutations';

export default {
  /**
   * Adds a new profile to the given product
   * and returns the created resource object.
   *
   * @param commit
   * @param {String} product
   * @param {Object} profile
   * @return {{ isLoading: boolean, data: Object, error: Object }}
   */
  addProfile({ commit }, { product, profile }) {
    const data = {
      ...resourceContainer(),
      isLoading: true,
      data: {
        id: null,
        active: true,
        global: false,
        content: {},
        ...profile,
      },
    };

    commit(CREATE_PROFILE, { product, data });

    return data;
  },
};
