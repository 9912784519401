/*
 * Modified copy (fork) of the vuex-redux-saga plugin
 * over at https://github.com/xanf/vuex-redux-saga
 *
 * Changes we made to better integrate it into Vue:
 * - made sure it runs with ^0.16
 * - The commit payload is properly being extracted now
 * - Automatically runs all sagas in registered modules
 */

import each from 'lodash/each';
import { runSaga, stdChannel } from 'redux-saga';

export default (store) => {
  const channel = stdChannel();
  store.subscribe(channel.put);

  /* eslint-disable no-underscore-dangle */
  const registerSaga = ({ _children, _rawModule: { sagas } }) => {
    each(_children, registerSaga);

    if (typeof sagas !== 'function') {
      return;
    }

    runSaga({
      channel,
      dispatch: ({ type, ...payload }) => store.commit(
        type,

        // https://en.wikipedia.org/wiki/Buffalo_buffalo_Buffalo_buffalo_buffalo_buffalo_Buffalo_buffalo
        // In other words, extracts the payload if key has been set
        payload.payload ? payload.payload : payload,
      ),
      getState: () => store.state,
    }, sagas);
  };

  each(store._modules, registerSaga);
  /* eslint-enable no-underscore-dangle */
};
