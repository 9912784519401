import filter from 'lodash/filter';
import reduce from 'lodash/reduce';

import hashCode from '@/utils/hashCode';
import api from '@/store/api';
import waitForProduct from '@/store/effects/waitForProduct';

import {
  call,
  cancel,
  cancelled,
  fork,
  put,
  race,
  select,
  spawn,
  take,
  takeLatest,
} from 'redux-saga/effects';

import { GET_LEGACY_INPUT_SUCCESS } from '@/store/modules/input/mutations';
import { NAMESPACE as INPUT_NAMESPACE } from '@/store/modules/input';
import { searchData } from '@/store/modules/global/getters';

import { NAMESPACE } from './index';

import {
  GET_OFFERS,
  GET_OFFERS_BY_COMPANY,
  GET_OFFERS_BY_COMPANY_ERROR,
  GET_OFFERS_BY_COMPANY_SUCCESS,
  getOffersByCompanyError,
} from './actions/offersByCompany';

import {
  SET_REQUEST_FILTER,
  SET_REQUEST_HASH,
  GET_ALL_TIME_TARIFF,
  GET_ALL_TIME_TARIFF_SUCCESS,
  GET_ALL_TIME_TARIFF_ERROR, SET_VORVERSICHERUNG,
} from './mutations';

import { NAMESPACE as CLIENT_NAMESPACE } from '../client';
import { SET_CLIENT_ERRORS } from '../client/mutations';

import splitArray from '@/utils/splitArray';
import products from '@/products';

import redirectOnError from './sagas/redirectOnError';

function* saveErrorPerCompany(companyIds, error) {
  for (let i = 0; i < companyIds.length; i += 1) {
    yield put(getOffersByCompanyError({
      id: companyIds[i],
      error,
    }));
  }
}

function* loadByCompany(payload, requestCallback) {
  const { id } = payload;

  // Only load companies additively
  // If we already got a request running, ignore this call
  const existing = yield select(({ offers }) => offers.chunks[payload.id]);
  if (existing !== undefined) {
    return;
  }

  yield put({
    type: NAMESPACE + GET_OFFERS_BY_COMPANY,
    payload,
  });

  requestCallback(id);
}

function* loadChunk(product, request, companyIds) {
  let savedError = false;

  try {
    const { data } = yield call(api, 'post', `/${product}/tarif`, {
      ...request,
      konfigurationsParameter: {
        direktvereinbarung: false,
        gesellschaftsfilter: companyIds,
      },
    });

    for (let i = 0; i < companyIds.length; i += 1) {
      const id = companyIds[i];
      const chunkData = {
        ...data,
        tarife: filter(data.tarife, (tarif) => tarif.gesellschaft.id === `${id}`),
      };

      yield put({
        type: NAMESPACE + GET_OFFERS_BY_COMPANY_SUCCESS,
        payload: { id, data: chunkData, product },
      });
    }

    yield put({
      type: NAMESPACE + SET_VORVERSICHERUNG,
      payload: data.allTimeTariffs,
    });
  } catch (error) {
    savedError = true;

    // extract client errors from validation error list
    const clientErrors = reduce(error.data.data.validation, (acc, value, key) => {
      if (key.includes('[entities]')) {
        acc[key] = value;
      }
      return acc;
    }, {});
    // send client errors to client mutation to build usefule error state from it
    yield put({ type: CLIENT_NAMESPACE + SET_CLIENT_ERRORS, payload: clientErrors });
    yield call(saveErrorPerCompany, companyIds, error);
  } finally {
    // savedError checks if this chunk was the one that caused the others to cancel
    // in which case we don't want to clear ourselves with an empty error again
    if (!savedError && (yield cancelled())) {
      yield call(saveErrorPerCompany, companyIds, {});
    }
  }
}

/**
 * Grabs the offers from the given URL
 * and stores them in our store.
 *
 * @returns {IterableIterator<*>}
 */
export function* load() {
  // Merge normal input with client data
  const request = searchData(yield select());

  const companies = yield select((store) => store.companies[store.product].data || []);
  // eslint-disable-next-line
  const { directAgreement } = yield select((store) => store.offers.filters);

  const companyIds = [];
  const requestCallback = (id) => companyIds.push(id);

  for (let i = 0; i < companies.length; i += 1) {
    const company = companies[i];

    if (
      company.supports.tarife
      && !company.isBlacklisted
      && (!directAgreement || company.isDirektvereinbarung)
    ) {
      yield call(loadByCompany, {
        id: company.insuranceId,
        request,
      }, requestCallback);
    }
  }

  if (companyIds.length === 0) {
    // No need to request anything
    return [];
  }

  const product = yield call(waitForProduct);
  const chunks = splitArray(companyIds, products[product].props.numChunks || 1);
  const requests = [];

  yield put({
    type: NAMESPACE + SET_REQUEST_HASH,
    payload: {
      product,
      hash: hashCode(request),
    },
  });

  for (let c = 0; c < chunks.length; c += 1) {
    requests.push(yield fork(loadChunk, product, request, chunks[c]));
  }

  return requests;
}

/**
 * Refreshes the list whenever a filter changes.
 *
 * @return {IterableIterator<*>}
 */
export function* filterWatcher() {
  while (true) {
    const { payload } = yield take(NAMESPACE + SET_REQUEST_FILTER);

    if (payload.refresh !== false) {
      yield put({
        type: NAMESPACE + GET_OFFERS,
        payload: {
          append: true,
        },
      });
    }
  }
}

function* loadOrAppend() {
  let tasks = [];

  while (true) {
    // Load offers for as long as we request them, and don't encounter an error
    const { fetch, error } = yield race({
      fetch: take(NAMESPACE + GET_OFFERS),
      error: take(NAMESPACE + GET_OFFERS_BY_COMPANY_ERROR),
    });

    // Cancel tasks if there's an error, or we want to request all data
    const isClientError = error !== undefined && error.payload && error.payload.error.status < 500;
    const fetchPayload = (fetch || {}).payload || {};

    if (tasks.length > 0 && (isClientError || fetchPayload.append)) {
      yield cancel(tasks);
      tasks = [];
    }

    if (fetch !== undefined) {
      tasks.push(yield spawn(load, { payload: fetchPayload }));
    }
  }
}

function* loadAllTimeTariffData({ payload }) {
  const { product, companyId, queryParams } = payload;

  try {
    const { data } = yield call(api, 'get', `/${product}/gesellschaften/${companyId}/tarife/all-time`, {
      params: queryParams,
    });

    yield put({ type: NAMESPACE + GET_ALL_TIME_TARIFF_SUCCESS, payload: data });
  } catch (e) {
    yield put({ type: NAMESPACE + GET_ALL_TIME_TARIFF_ERROR, payload: e });
  }
}

export default function* root() {
  yield takeLatest(NAMESPACE + GET_ALL_TIME_TARIFF, loadAllTimeTariffData);

  yield takeLatest(INPUT_NAMESPACE + GET_LEGACY_INPUT_SUCCESS, load);

  yield fork(loadOrAppend);
  yield fork(filterWatcher);

  yield fork(redirectOnError);
}
