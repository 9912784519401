import mapValues from 'lodash/mapValues';
import reduce from 'lodash/reduce';
import find from 'lodash/find';

import { transformAll } from '@demvsystems/yup-ast';

import { FEATURE_CHECKED, FEATURE_UNCHECKED } from './constants';

/**
 * Recursively adds feature items to a map by their key.
 *
 * @param {[key: string]: object} ctx
 * @param {{ type: string, identifier: string, items?: Array }} item
 * @return {[key: string]: object}
 */
const addItem = (ctx, item) => {
  if (item.identifier !== undefined) {
    ctx[item.identifier] = item;
  }

  if (item.items !== undefined) {
    item.items.forEach((entry) => addItem(ctx, entry));
  }

  return ctx;
};

/**
 * Builds a flattened dictionary of all features by their key (identifier).
 *
 * @param state
 * @return {[key: string]: object}
 */
export function byKey(state) {
  return mapValues(state, ({ data }) => reduce(data, addItem, {}));
}

/**
 * Maps the feature IDs to the evaluated value.
 * This either returns the user-selected value or evaluates the requirement checks
 * (in the form of a yup schema).
 *
 * @param state
 * @param getters
 * @param product
 * @param searchData
 * @return {object}
 */
export function evaluatedFeatures(state, getters, { product }, { searchData }) {
  return mapValues(getters.byKey[product], (feature) => {
    // Handle general requirements (e.g. for checkboxes)
    if (feature.requirements !== undefined && feature.requirements.length > 0) {
      return transformAll(feature.requirements).isValidSync(searchData)
        ? FEATURE_CHECKED
        : FEATURE_UNCHECKED;
    }

    // Handle group requirements (e.g. radio selects)
    let didEvaluate = false;

    const validChoice = find(feature.choices, (choice) => {
      if (choice.requirements === undefined || choice.requirements.length === 0) {
        // The group is not dynamic, so do an early exit
        return true;
      }

      didEvaluate = true;

      return transformAll(choice.requirements).isValidSync(searchData);
    });

    if (!didEvaluate) {
      // Early exit for groups without requirements
      return feature.value;
    }

    return validChoice !== undefined ? validChoice.value : FEATURE_UNCHECKED;
  });
}

export default {
  byKey,
  evaluatedFeatures,

  valuesByKey(state, getters, rootState) {
    return mapValues(getters.byKey[rootState.product], (v) => {
      if (v.type === 'select') {
        return reduce(v.choices, (acc, choice) => {
          acc[choice.value] = choice.text;
          return acc;
        }, {
          [FEATURE_UNCHECKED]: 'Keine Vorgabe',
        });
      }

      return {
        [FEATURE_CHECKED]: 'Gewünscht',
        [FEATURE_UNCHECKED]: 'Keine Vorgabe',
      };
    });
  },

  collapsedCount(state) {
    const { offers, ...products } = state;
    return mapValues(products, ({ collapsed }) => collapsed.length);
  },

  isHidden(state, getters, rootState) {
    const { product } = rootState;
    return (group) => (state[product].collapsed.indexOf(group.label)) >= 0;
  },
};
