export const GET_USER = 'getUser';
export const SET_USER_SUCCESS = 'setUserSuccess';
export const SET_USER_ERROR = 'setUserError';

export const GET_PERMISSIONS = 'getPermissions';
export const SET_PERMISSIONS_SUCCESS = 'setPermissionsSuccess';
export const SET_PERMISSIONS_ERROR = 'setPermissionsError';

export default {
  [GET_USER](state) {
    state.user.isLoading = true;
  },

  [SET_USER_SUCCESS](state, user) {
    state.user.isLoading = false;
    state.user.data = user;
  },

  [SET_USER_ERROR](state, error) {
    state.user.isLoading = false;
    state.user.error = error;
  },

  [GET_PERMISSIONS](state) {
    state.permissions.isLoading = true;
  },

  [SET_PERMISSIONS_SUCCESS](state, permissions) {
    state.permissions.isLoading = false;
    state.permissions.data = permissions;
  },

  [SET_PERMISSIONS_ERROR](state, error) {
    state.permissions.isLoading = false;
    state.permissions.error = error;
  },
};
