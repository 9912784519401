import { BaseAction } from '@/store/common';
import Company from '../Company';

export interface UpdateCompany {
  product: string;
  insuranceId: number;
  data: Partial<Company>;
}

export type UpdateCompanyAction = BaseAction<UpdateCompany>

// TODO use NAMESPACE import
export const UPDATE_COMPANY = 'updateCompany';

export const updateCompany = (payload: UpdateCompany): UpdateCompanyAction => ({
  type: `companies/${UPDATE_COMPANY}`,
  payload,
});
