// @ts-ignore
import store from '@/store';

// @ts-ignore
import { NAMESPACE as CLIENT_NAMESPACE } from '@/store/modules/client';

// @ts-ignore
import { GET_CLIENT_BY_ID } from '@/store/modules/client/mutations';

import roles from '@/store/modules/client/roles';

export default function loadClientsFromQuote(quote: any) {
  const clientRoles = [];

  for (let i = 0; i < roles.length; i += 1) {
    const client = quote.content.kunden[roles[i]];

    if (client !== undefined && quote.clientId === Number.parseInt(client.id, 10)) {
      clientRoles.push(roles[i]);
    }
  }

  store.commit(CLIENT_NAMESPACE + GET_CLIENT_BY_ID, {
    id: parseInt(quote.clientId, 10),
    roles: clientRoles,
    keepQuoteData: true,
  });
}
