import { StepConfig } from '../types';

const stepConfig: StepConfig = {
  path: 'vorgaben',

  props: {
    icon: 'pencil-alt',
    label: 'Vorgaben',
  },
};

export default stepConfig;
